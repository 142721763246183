import React, { useState } from 'react';
import CommonLayout from '../../../layout/commonLayout/CommonLayout';
import { updatePassword } from '../../../components/Auth/AuthApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { CiSaveDown2 } from "react-icons/ci";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function ChangePassword() {
  const [oldPassword, setoldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    if (!oldPassword) newErrors.oldPassword = 'Current password is required';
    if (!newPassword) newErrors.newPassword = 'New password is required';
    if (newPassword !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await updatePassword(oldPassword, newPassword);
        if (response.success) {
          toast.success('Password updated successfully');
          navigate('/Dashboard');
        } else {
          toast.error(response.error || response.message || 'An error occurred');
        }
      } catch (error) {
        if (error.response) {
          console.error('Response error:', error.response);
          toast.error(error.response.data.message || 'Invalid token or request failed');
        } else if (error.request) {
          toast.error('No response received, please try again');
        } else {
          console.error('An error occurred', error);
        }
      }
    }
  };

  const togglePasswordOldVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  const togglePasswordNewVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const togglePasswordConfirmVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <CommonLayout>
      <div className="auth px-0">
        <div className="col-lg-12">
          <div className="auth-form-light text-left rounded py-5 px-sm-5">
            <h4 className="text-center mb-4 fw-bold">🔒 Change Password</h4>
            <form className="pt-3">
              <div className="form-group position-relative">
                <input
                  type={showOldPassword ? "text" : "password"} // Dynamically set type
                  className={`form-control formclass ${errors.oldPassword ? 'is-invalid' : ''}`}
                  placeholder="Current Password"
                  value={oldPassword}
                  onChange={(e) => setoldPassword(e.target.value)}
                />
                {errors.oldPassword && <div className="invalid-feedback">{errors.oldPassword}</div>}
                <span
                  onClick={togglePasswordOldVisibility}
                  className="position-absolute top-50 end-0 translate-middle-y pe-3 cursor-pointer"
                  style={{ cursor: 'pointer' }}
                >
                  {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <div className="form-group position-relative">
                <input
                  type={showNewPassword ? "text" : "password"} // Dynamically set type
                  className={`form-control formclass ${errors.newPassword ? 'is-invalid' : ''}`}
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
                {errors.newPassword && <div className="invalid-feedback">{errors.newPassword}</div>}
                <span
                  onClick={togglePasswordNewVisibility}
                  className="position-absolute top-50 end-0 translate-middle-y pe-3 cursor-pointer"
                  style={{ cursor: 'pointer' }}
                >
                  {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <div className="form-group position-relative">
                <input
                  type={showConfirmPassword ? "text" : "password"} // Dynamically set type
                  className={`form-control formclass ${errors.confirmPassword ? 'is-invalid' : ''}`}
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                <span
                  onClick={togglePasswordConfirmVisibility}
                  className="position-absolute top-50 end-0 translate-middle-y pe-3 cursor-pointer"
                  style={{ cursor: 'pointer' }}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <div className="mt-4 ms-4">
                <div className='text-end mt-4'>
                  <Button
                    icon={<CiSaveDown2 />}
                    className='mybtn'
                    onClick={handleSubmit}
                  >
                    Change password
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
}

export default ChangePassword;
