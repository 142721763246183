import React, { useEffect, useState } from "react";
import CommonLayout from "../../layout/commonLayout/CommonLayout";
import {
  createBanner,
  fetchCategories,
  fetchCategoryBySubCategory,
  uploadBannerImage,
} from "../../services/Apis/ApiServices";
import { toast } from "react-toastify";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { CiSaveDown2 } from "react-icons/ci";
import { languageOptions } from "../../utils/utils";
import { Input } from "antd";
function CreateBanner() {
  const navigate = useNavigate();
  const { TextArea } = Input;
  const [category, setCategory] = useState([]);
  // const [isFetched, setIsFetched] = useState(false);
  const [subCategory, setSubCategory] = useState([]);
  const [image,setImage] = useState([]);
  const [isFetched,setIsFetched] = useState([]);
  const [formData, setFormData] = useState({
    titleEn: "",
    titleSecondary: "",
    descriptionEn: "",
    descriptionSecondary: "",
    language: "",
    category: "",
    subCategory: "",
    image: null,
  });

  const [errors, setErrors] = useState({
    titleEn: "",
    titleSecondary: "",
    descriptionEn: "",
    descriptionSecondary: "",
    language: "",
    category: "",
    subCategory: "",
    image: "",
  });

  const [previewImage, setPreviewImage] = useState(null);

  const getCategories = async () => {
    try {
      const fetchedCategories = await fetchCategories();
      if (fetchedCategories?.length === 0) {
      } else {
        setCategory(fetchedCategories.categories);
        setIsFetched(true);
      }
    } catch (err) {
      console.error("Error in getCategories:", err);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleCategoryChange = async (categoryId) => {
    setFormData({ ...formData, category: categoryId });
    if (categoryId) {
      try {
        const fetchedSubCategory = await fetchCategoryBySubCategory(categoryId);
        if (fetchedSubCategory.length > 0) {
          setSubCategory(fetchedSubCategory);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    } else {
      setSubCategory([]);
    }
  };

  // const getSubCategory = async () =>{
  //   try {
  //     const response = await
  //   }
  //   catch (err) {
  //     console.error("Error in getCategories:", err);
  //   }
  // }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    try {
      setImage(file);
      const previewURL = URL.createObjectURL(file);
      setPreviewImage(previewURL);
      const imagePath = await uploadBannerImage(file);
      setFormData((prevData) => ({
        ...prevData,
        image: imagePath,
      }));
    } catch (error) {
      toast.error("Failed to upload image.");
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({
      language: "",
      titleEn: "",
      titleSecondary: "",
      descriptionEn: "",
      descriptionSecondary: "",
      category: "",
      subCategory: "",
      image: "",
    });

    let isValid = true;
    if (!formData.language) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        language: "Language is required",
      }));
      isValid = false;
    }

    if (!formData.titleEn) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        titleEn: "name (English) is required",
      }));
      isValid = false;
    }

    if (!formData.titleSecondary) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        titleSecondary: `name (${
          languageOptions[formData.language]
        }) is required`,
      }));
      isValid = false;
    }

    if (!formData.descriptionEn) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        descriptionEn: "Description (English) is required",
      }));
      isValid = false;
    }

    if (!formData.descriptionSecondary) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        descriptionSecondary: `Description (${
          languageOptions[formData.language]
        }) is required`,
      }));
      isValid = false;
    }

    if (!formData.category) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        category: `Category is required`,
      }));
      isValid = false;
    }

    if (!formData.subCategory) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        subCategory: `Sub Category is required`,
      }));
      isValid = false;
    }

    if (!formData.image) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: `image is required`,
      }));
      isValid = false;
    }

    // if (!formData.category) {
    //   setErrors((prevErrors) => ({ ...prevErrors, category: 'category is required' }));
    //   isValid = false;
    // }
    if (!isValid) return;

    const apiData = {
      title: {
        en: formData.titleEn,
        secondary: formData.titleSecondary,
      },
      description: {
        en: formData.descriptionEn,
        secondary: formData.descriptionSecondary,
      },
      language: {
        secondary: formData.language,
      },
      bannerImage: formData.image,
      linkedCategory: formData.category,
      linkedSubCategory: formData.subCategory,
    };

    const response = await createBanner(apiData);
    if (response) {
      toast.success(response.message);
      navigate("/AllBanner");
      setFormData({
        titleEn: "",
        titleSecondary: "",
        descriptionEn: "",
        descriptionSecondary: "",
        language: "",
        image: null,
      });
      setPreviewImage(null);
    }
  };

  return (
    <CommonLayout>
      <div className="container my-3">
        <div className="card shadow-lg p-4">
          <h3 className="text-center mb-4 text-primary fw-bold">
            Add New Banner
          </h3>
          <form>
            <div className="row mb-3">
              <div className="col-md-12">
                <label className="form-label fw-bold">Language</label>
                <select
                  className="form-select "
                  name="language"
                  value={formData.language}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select Language ---
                  </option>
                  {Object.entries(languageOptions).map(([code, name]) => (
                    <option key={code} value={code}>
                      {name}
                    </option>
                  ))}
                </select>
                {errors.language && (
                  <div className="text-danger">{errors.language}</div>
                )}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6 ">
                <label className="form-label fw-bold"> Category</label>
                <select
                  className="form-select"
                  value={formData.category}
                  onChange={(e) => {
                    handleCategoryChange(e.target.value);
                  }}
                >
                  <option value="">select category</option>
                  {category?.length > 0 ? (
                    category?.map((category) => (
                      <option key={category._id} value={category._id}>
                        {typeof category.name === "object"
                          ? category.name.en
                          : category.name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No category available</option>
                  )}
                </select>
                {errors.category && (
                  <div className="text-danger">{errors.category}</div>
                )}
              </div>
              <div className="col-md-6 ">
                <label className="form-label fw-bold">Sub Category</label>
                <select
                  className="form-select"
                  value={formData.subCategory}
                  onChange={(e) =>
                    setFormData({ ...formData, subCategory: e.target.value })
                  }
                >
                  <option value="">select category</option>
                  {subCategory?.length > 0 ? (
                    subCategory?.map((sCategory) => (
                      <option key={sCategory._id} value={sCategory._id}>
                        {typeof sCategory.name === "object"
                          ? sCategory.name.en
                          : sCategory.name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No category available</option>
                  )}
                </select>
                {errors.subCategory && (
                  <div className="text-danger">{errors.subCategory}</div>
                )}
              </div>
            </div>

            <div className="mb-3 row">
              <div className="col-md-6">
                <label className="form-label fw-bold">Title (English)</label>
                <input
                  type="text"
                  className="form-control formclass"
                  name="titleEn"
                  value={formData.titleEn}
                  onChange={handleChange}
                  placeholder="Enter title in English"
                  required
                />
                {errors.titleEn && (
                  <div className="text-danger">{errors.titleEn}</div>
                )}
              </div>

              {/* <div className="mb-3"> */}
              <div className="col-md-6">
                <label className="form-label fw-bold">
                  Title ({languageOptions[formData.language]})
                </label>
                <input
                  type="text"
                  className="form-control formclass"
                  name="titleSecondary"
                  value={formData.titleSecondary}
                  onChange={handleChange}
                  placeholder={`Enter title in ${
                    languageOptions[formData.language]
                  }`}
                  required
                />
                {errors.titleSecondary && (
                  <div className="text-danger">{errors.titleSecondary}</div>
                )}
              </div>
            </div>

            <div className="mb-3 col-md-12">
              <label className="form-label fw-bold">
                Description (English)
              </label>
              <TextArea
                className="form-control formclass"
                name="descriptionEn"
                value={formData.descriptionEn}
                onChange={handleChange}
                placeholder="Enter description in English"
                required
              />
              {errors.descriptionEn && (
                <div className="text-danger">{errors.descriptionEn}</div>
              )}
            </div>

            <div className="mb-4 col-md-12">
              <label className="form-label fw-bold">
                Description ({languageOptions[formData.language]})
              </label>
              <TextArea
                className="form-control formclass"
                name="descriptionSecondary"
                value={formData.descriptionSecondary}
                onChange={handleChange}
                placeholder={`Enter description in ${
                  languageOptions[formData.language]
                }`}
                required
              />
              {errors.descriptionSecondary && (
                <div className="text-danger">{errors.descriptionSecondary}</div>
              )}
            </div>

            <div className="">
              <label className="form-label fw-bold">Upload Image</label>
              <input
                type="file"
                className="form-control formclass"
                onChange={handleImageChange}
                accept="image/*"
                required
              />
              {previewImage && (
                <div className="mt-3">
                  <img
                    src={previewImage}
                    alt="Preview"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}

              {errors.image && (
                <div className="text-danger">{errors.image}</div>
              )}
            </div>

            <div className="text-end mt-4">
              <Button
                icon={<CiSaveDown2 />}
                className="mybtn"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </CommonLayout>
  );
}

export default CreateBanner;
