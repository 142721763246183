import React from 'react'

function Footer() {
  return (
    <div >
        <footer class="footer footerclass">
  <div class="d-sm-flex justify-content-center justify-content-sm-between">
    <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2023. Premium <a href="https://www.bootstrapdash.com/" target="_blank"></a> All rights reserved.</span>
    {/* <div className="text-center mt-4"> */}
        <p className="mb-0">Crafted with care by <span className="text-primary">Your Team</span>. Made to make your experience better.</p>
      {/* </div> */}
  </div>
</footer>
    </div>
  )
}

export default Footer