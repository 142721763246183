import React, { useEffect, useState, useCallback } from "react";
import CommonLayout from "../../layout/commonLayout/CommonLayout";
import { Link, useNavigate } from "react-router-dom";
import { MdHome } from "react-icons/md";
import {
    deleteArticle,
    fetchAllArticles,
} from "../../services/Apis/ApiServices";
import { Input, Image, Select } from "antd";
import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";
import DeleteArticleModal from "../../components/models/artcleModal/DeleteArticleModal";
import { languageOptions, languageOptionsWithFlag } from "../../utils/utils";
import "../ArticalForm/AllArticle.css";
import { FaLessThan } from "react-icons/fa";
import { FaGreaterThan } from "react-icons/fa";
import { FaTrashAlt, FaEye } from "react-icons/fa";
import { GrFormPreviousLink ,GrFormNextLink  } from "react-icons/gr";
import { chunkArray } from "../../utils/utils";

const { Option } = Select;
const Dashboard = () => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(null);

    const [languageCodes, setLanguageCodes] = useState([]);
   
    const navigate = useNavigate();

    // Fetch articles from API
    const fetchArticles = useCallback(async (language = "en" , page=1) => {
        try {
            setLoading(true);
            const response = await fetchAllArticles(language , page);
            setArticles(response?.articles || []);
            const otherLanguageContentArray = response?.articles?.map((article) =>
                Object.entries(article.otherLanguageContent || {})
            );
            setLanguageCodes(otherLanguageContentArray);
            setTotalPages(response?.totalPage);
        } catch (error) {
            console.error("Error fetching articles:", error);
            setArticles([]);
        } finally {
            setLoading(false);
        }
    }, []);

    // Fetch articles on mount and language change
    useEffect(() => {
        fetchArticles(selectedLanguage ,currentPage);
    }, [fetchArticles, selectedLanguage , currentPage]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const handleLanguageChange = (value) => {
        setSelectedLanguage(value);
    };

    // Filter articles based on search term
    const filteredArticles = articles.filter((article) => {
        const titleEn = article?.title?.toLowerCase() || "";
        const titleSecondary = article?.title?.toLowerCase() || "";
        const category = article?.category?.name?.en?.toLowerCase() || "";
        return titleEn.includes(searchTerm) || titleSecondary.includes(searchTerm) || category.includes(searchTerm);
    });
   
    const handlePrevious = () => {
        if (currentPage > 1) {
          setCurrentPage((prevPage) => prevPage - 1);
        }
      };
    
      // Handler for going to the next page
      const handleNext = () => {
        if (currentPage < totalPages) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      };

    const handleDeleteClick = (article) => {
        setSelectedArticle({
            ...article,
            langCode: selectedLanguage, 
          })
        setShowDeleteModal(true);
    };

    // Confirm delete
    const handleConfirmDelete = async () => {
        try {
            const response = await deleteArticle(selectedArticle._id , selectedArticle.languages);
            if (response.success) {
                toast.success(response.message);
                fetchArticles(selectedLanguage); // Refresh articles
            } else {
                toast.warning(response.message);
            }
        } catch (error) {
            toast.error("Error deleting article");
        } finally {
            setShowDeleteModal(false); // Close modal
        }
    };

    return (
        <CommonLayout>
            <div className="row d-flex align-items-center">

                <div className="col-md-9 col-sm-12">
                    <div
                        style={{
                            padding: "20px",
                            backgroundColor: "#f8f9fa",
                            borderRadius: "8px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <h2 style={{ color: "#4B49AC", fontWeight: "bold" }}>
                            Welcome to the Dashboard!
                        </h2>
                        <p
                            style={{
                                fontSize: "13px",
                                color: "#6c757d",
                                marginTop: "10px",
                            }}
                        >
                            Here, you can view and manage all articles efficiently. Use the
                            tools below to keep track of your content and stay organized.
                        </p>
                    </div>
                </div>

                <div className="col-md-3 col-sm-12">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-end">
                            <li class="breadcrumb-item">
                                <Link>
                                    <MdHome size={20} color="#4B49AC" />
                                </Link>
                            </li>
                            <li class="breadcrumb-item">
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                        fontWeight: "bold",
                                        fontSize: "15px",
                                    }}
                                >
                                    Dashboard
                                </Link>
                            </li>
                        </ol>
                    </nav>
                </div>

            </div>



            <div className="parentlanglag">
                {Object.entries(languageOptionsWithFlag).map(
                    ([code, { name, flag }]) => (
                        <button
                            key={code}
                            onClick={() => handleLanguageChange(code)}
                            style={{
                                backgroundColor:
                                    selectedLanguage === code ? "#4B49AC" : "transparent",
                            }}
                            className={`langflag ${selectedLanguage === code ? "selected" : ""
                                }`}
                        >
                            <div>
                                <img
                                    src={flag}
                                    // alt={`${name} flag`}
                                    style={{ width: "24px", height: "16px", borderRadius: "2px" }}
                                />
                            </div>
                            <div>
                                <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                                    {name}
                                </span>
                            </div>
                        </button>
                    )
                )}
            </div>

            <div className="container card rounded shadow-lg p-4">
                <div className=" dashboardtableclass mb-4 ">
                    <h4 className="fw-bold middleclass tabledashwidht">   {languageOptionsWithFlag[selectedLanguage]?.name || "Articles"} Articles</h4>

                    {/* Search Bar and Pagination Controls */}
                    {/* <div className='secondtableclass' */}
                        {/* Search Bar */}
                        <Input
                            prefix={<FaSearch />}
                            placeholder="Search by title"
                            className="search-input"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            style={{
                                maxWidth: "300px",
                                borderRadius: "50px",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            }}
                        />
                    {/* </div> */}
                </div>
                {selectedLanguage === "en" && (
                    <div className="mb-3">
                        <small>
                            <em>
                                <strong>Note:</strong>
                                <span className="text-success"> Green badge </span> indicates that
                                the article is created in that language, while{" "}
                                <span className="text-danger">Red badge </span>
                                indicates that the article is <strong>not created</strong> in that
                                language.
                            </em>
                        </small>
                    </div>
                )}
                {loading ? (
                    <div className="loading-container">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className="table-responsive">
                        <table className="table table-bordered table-striped table-hover parentclass">
                            <thead className="bgtable">
                                <tr>
                                    <th className="paddingclass">#</th>
                                    <th className="paddingclass">Banner</th>
                                    <th className="paddingclass">Title</th>
                                    {selectedLanguage !== "en" && (
                                    <th className="paddingclass">Related English Article</th>
                                    )}
                                    <th className="paddingclass">Category</th>
                                    <th className="paddingclass">Language</th>
                                    <th className="paddingclass">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredArticles.length > 0 ? (
                                    filteredArticles.map((article, index) => (
                                        <tr key={article._id}>
                                            {/* Serial Number */}
                                            <td className="pclass">
                                            {index + 1}
                                            </td>

                                            {/* Banner Image */}
                                            <td className="pclass">
                                                {article.bannerData?.[0]?.bannerImage ? (
                                                    <Image
                                                        src={article.bannerData[0].bannerImage}
                                                        className="img-fluid avatar rounded-circle"
                                                        alt={article.title.en}
                                                        style={{
                                                            width: "40px",
                                                            height: "40px",
                                                            objectFit: "cover",
                                                        }}
                                                    />
                                                ) : (
                                                    <Image
                                                        src="../../assets/images/defaultArticle.jpg"
                                                        className="img-fluid avatar rounded-circle"
                                                        alt="Default Image"
                                                        style={{
                                                            width: "40px",
                                                            height: "40px",
                                                            objectFit: "cover",
                                                        }}
                                                    />
                                                )}
                                            </td>


                                            {/* Article Title */}
                                            <td className="pclass titlepclass">{article?.title}</td>
                                            {selectedLanguage !== "en" && (
                                                
                                            <td className="pclass">{article?.englishContent?.title}</td>
                                            )}

                                            {/* Article Category */}
                                            <td className="pclass">{article?.category?.name?.en}</td>

                                            {/* Language Code Section */}
                                            <td className="pclass">
                                                {selectedLanguage === "en" ? (
                                                    languageOptions ? (
                                                        <div>
                                                            {chunkArray(Object.keys(languageOptions), 6).map(
                                                                (group, groupIndex) => (
                                                                    <div
                                                                        key={groupIndex}
                                                                        className="d-flex justify-content-start mb-2"
                                                                    >
                                                                        {group.map((key) => {
                                                                            const isAvailable =
                                                                                article.otherLanguageContent &&
                                                                                key in article.otherLanguageContent &&
                                                                                article.otherLanguageContent[key] !== null;

                                                                            return (
                                                                                <span
                                                                                    key={key}
                                                                                    className={`badge ${isAvailable
                                                                                            ? "bg-success"
                                                                                            : "bg-danger"
                                                                                        } text-light hover-badge`}
                                                                                    style={{
                                                                                        fontSize: "10px",
                                                                                        padding: "5px 7px",
                                                                                        borderRadius: "10px",
                                                                                        marginRight: "10px",
                                                                                        cursor: "pointer",
                                                                                        fontWeight: "bold",
                                                                                    }}
                                                                                    title={languageOptions[key]}
                                                                                >
                                                                                    {key.toUpperCase()}{" "}
                                                                                </span>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <span className="text-muted">
                                                            No Languages Available
                                                        </span>
                                                    )
                                                ) : (
                                                    <p className="pclass">{article.languages}</p>
                                                )}
                                            </td>

                                            {/* Action Buttons */}

                                            <td>
                                                <div
                                                    className="d-flex justify-content-start"
                                                    style={{ gap: "10px" }}
                                                >
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary p-2"
                                                        style={{
                                                            border: "none",
                                                            backgroundColor: "transparent",
                                                        }}
                                                        onClick={() =>
                                                            navigate(`/articleDetail/${article._id}/${selectedLanguage}`)
                                                        }
                                                    >
                                                        {/* show more */}
                                                        <FaEye color="black" size={18} />
                                                    </button>
                                                    
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger p-2"
                                                            style={{
                                                                border: "none",
                                                                backgroundColor: "transparent",
                                                            }}
                                                            onClick={() => handleDeleteClick(article)}
                                                        >
                                                            <FaTrashAlt color="red" size={18} />
                                                        </button>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan="6"
                                            style={{ textAlign: "center", fontWeight: "bold" }}
                                        >
                                            No Articles Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
                 <div className="d-flex justify-content-center align-items-center gap-3 mt-5">
          <button
            className="btn btn-outline-primary prevbtn"
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
             <GrFormPreviousLink size={20} />
          </button>
          <span className="fw-bold">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className="btn btn-outline-primary nextbtn"
          >
              <GrFormNextLink size={20} />
          </button>
        </div>
                {showDeleteModal && (
                    <DeleteArticleModal
                        selectedArticle={selectedArticle}
                        setShowDeleteModal={setShowDeleteModal}
                        handleConfirmDelete={handleConfirmDelete}
                    />
                )}
            </div>
        </CommonLayout>
    );
};

export default Dashboard;
