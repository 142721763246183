// import React, { useEffect, useState } from "react";
// import CommonLayout from "../../layout/commonLayout/CommonLayout";
// import { Link, useNavigate } from "react-router-dom";
// import { MdHome } from "react-icons/md";
// import {
//   deleteArticle,
//   fetchAllArticles,
// } from "../../services/Apis/ApiServices";
// import { Input, Image, Select } from "antd";
// import { FaSearch } from "react-icons/fa";
// import "./AllArticle.css";
// import DeleteArticleModal from "../../components/models/artcleModal/DeleteArticleModal";
// import { toast } from "react-toastify";
// import { languageOptions } from "../../utils/utils";
// // import { Option } from "antd/es/mentions";
// const { Option } = Select;
// const AllArticle = () => {
//   const [articles, setArticles] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [filteredLanguages, setFilteredLanguages] = useState('en');
//   const navigate = useNavigate();

//   const [showDeleteModal, setShowDeleteModal] = useState(false);
//   const [selectedArticle, setSelectedArticle] = useState(null);

//   const handleDeleteClick = (article) => {
//     setSelectedArticle(article);
//     setShowDeleteModal(true);
//   };

//   const handleConfirmDelete = async () => {
//     try {
//       const response = await deleteArticle(selectedArticle._id);
//       if (response.success) {
//         toast.success(response.message);
//         getAllarticle();
//       } else {
//         toast.warning(response.message);
//       }
//     } catch (error) {
//       toast.error(error.message);
//     }
//     setShowDeleteModal(false); // Close the modal after delete
//   };

//   const getAllarticle = async () => {
//     try {
//       setLoading(true);
//       const response = await fetchAllArticles(filteredLanguages);

//       if (response) {
//         setArticles(response.articles);
//       } else {
//         setArticles([]);
//       }
//     } catch (error) {
//       console.error("Error fetching articles:", error);
//       setArticles([]);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     getAllarticle();
//   }, []);

//   const filterArticle = Array.isArray(articles)
//     ? articles.filter((article) => {
//         const search = searchTerm ? searchTerm.toLowerCase() : "";
//         const titleEn = article?.title?.en?.toLowerCase() || "";
//         const titleSecondary = article?.title?.secondary?.toLowerCase() || "";
//         return titleEn.includes(search) || titleSecondary.includes(search);
//       })
//     : [];

//   const handleSearchChange = (e) => {
//     console.log(e.target.value);
//     setSearchTerm(e.target.value);
//   };

//   const handleFilterChange = async (value) => {
//     setFilteredLanguages(value);
//     try {
//         setLoading(true);
//         const response = await fetchAllArticles(value);
//         if(response){
//             setArticles(response.articles);
//         }
//         else{
//             setArticles([]);
//         }
//     } catch (error) {
//         console.error("Error fetching articles:", error);
//       setArticles([]);
//     }
//     finally{
//         setLoading(false);
//     }
//   };
//   return (
//     <CommonLayout>
//       <div className="d-flex align-items-center justify-content-between mb-3">
//         <Input
//           prefix={<FaSearch />}
//           placeholder="Search by title"
//           className="search-input"
//           value={searchTerm}
//           onChange={handleSearchChange}
//           style={{
//             maxWidth: "300px",
//             borderRadius: "50px",
//             boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//           }}
//         />

//         <Select
//           value={filteredLanguages}
//           onChange={handleFilterChange}
//           style={{
//             width: 200,
//             borderRadius: "50px",
//             boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//           }}
//           placeholder="Filter by Language"
//         >
//           <Select.Option value="en" >
//             All
//           </Select.Option>
//           {Object.entries(languageOptions).map(([code, name]) => (
//              <Select.Option key={code} value={code}>
//              {name}
//            </Select.Option>
//           ))}
//         </Select>

//         <nav aria-label="breadcrumb">
//           <ol class="breadcrumb justify-content-end">
//             <li class="breadcrumb-item">
//               <Link>
//                 <MdHome size={20} color="#4B49AC" />
//               </Link>
//             </li>
//             <li class="breadcrumb-item">
//               <Link
//                 style={{
//                   textDecoration: "none",
//                   color: "black",
//                   fontWeight: "bold",
//                   fontSize: "15px",
//                 }}
//               >
//                 All Article
//               </Link>
//             </li>
//           </ol>
//         </nav>
//       </div>
//       {loading ? (
//         <div className="loading-container">
//           <div className="spinner-border text-primary" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </div>
//         </div>
//       ) : (
//         <div className="row">
//           {filterArticle.length > 0 ? (
//             filterArticle.map((article) => (
//               <div className="col-md-3" key={article._id}>
//                 <div className="card mb-4 shadow-lg">
//                   {/* Banner Image */}
//                   {article.bannerData?.[0]?.bannerImage && (
//                     <Image
//                       crossOrigin="anonymous"
//                       src={article.bannerData[0].bannerImage}
//                       className="card-img-top img-fluid"
//                       alt={article.title.en}
//                       style={{ height: "150px", objectFit: "cover" }}
//                     />
//                   )}
//                   <div className="card-body cardbocdyclass">
//                     {/* Titles */}
//                     <h5 className="titleee">
//                       {article.title.en} / {article.title.secondary}
//                     </h5>
//                     {/* <p className="card-text text-muted">{article.title.secondary}</p> */}

//                     <div
//                       className="d-flex justify-content-end"
//                       style={{ gap: "10px" }}
//                     >
//                       <button
//                         type="button"
//                         className="btn btn-primary p-2 mt-3"
//                         style={{ fontSize: "11px", fontWeight: "bold" }}
//                         onClick={() =>
//                           navigate(`/articleDetail/${article._id}`)
//                         }
//                       >
//                         Show More
//                       </button>
//                       <button
//                         type="button"
//                         className="btn btn-danger p-2 mt-3"
//                         onClick={() => handleDeleteClick(article)}
//                         style={{
//                           fontSize: "11px",
//                           fontWeight: "bold",
//                           color: "white",
//                         }}
//                         // onClick={() => deleteArticle(article._id)}
//                       >
//                         Delete
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))
//           ) : (
//             <div className="col-12 text-center">
//               <p style={{ fontWeight: "bold", fontSize: "25px" }}>
//                 No Articles found
//               </p>
//             </div>
//           )}
//         </div>
//       )}

//       {showDeleteModal && (
//         <DeleteArticleModal
//           selectedArticle={selectedArticle}
//           setShowDeleteModal={setShowDeleteModal}
//           handleConfirmDelete={handleConfirmDelete}
//         />
//       )}
//     </CommonLayout>
//   );
// };

// export default AllArticle;

import React, { useEffect, useState, useCallback } from "react";
import CommonLayout from "../../layout/commonLayout/CommonLayout";
import { Link, useNavigate } from "react-router-dom";
import { MdHome } from "react-icons/md";
import {
  deleteArticle,
  fetchAllArticles,
} from "../../services/Apis/ApiServices";
import { Input, Image, Select } from "antd";
import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";
import DeleteArticleModal from "../../components/models/artcleModal/DeleteArticleModal";
import { languageOptions, languageOptionsWithFlag } from "../../utils/utils";
import "./AllArticle.css";
import { FaTrashAlt, FaEye } from "react-icons/fa";
const { Option } = Select;

const AllArticle = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const navigate = useNavigate();

  // Fetch articles from API
  const fetchArticles = useCallback(async (language = "en") => {
    try {
      setLoading(true);
      const response = await fetchAllArticles(language);
      setArticles(response?.articles || []);
    } catch (error) {
      console.error("Error fetching articles:", error);
      setArticles([]);
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch articles on mount and language change
  useEffect(() => {
    fetchArticles(selectedLanguage);
  }, [fetchArticles, selectedLanguage]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
  };

  // Filter articles based on search term
  const filteredArticles = articles.filter((article) => {
    const titleEn = article?.title?.en?.toLowerCase() || "";
    const titleSecondary = article?.title?.secondary?.toLowerCase() || "";
    return titleEn.includes(searchTerm) || titleSecondary.includes(searchTerm);
  });

  // Open delete modal
  const handleDeleteClick = (article) => {
    setSelectedArticle(article);
    setShowDeleteModal(true);
  };

  // Confirm delete
  const handleConfirmDelete = async () => {
    try {
      const response = await deleteArticle(selectedArticle._id);
      if (response.success) {
        toast.success(response.message);
        fetchArticles(selectedLanguage); // Refresh articles
      } else {
        toast.warning(response.message);
      }
    } catch (error) {
      toast.error("Error deleting article");
    } finally {
      setShowDeleteModal(false); // Close modal
    }
  };

  return (
    <CommonLayout>
      <div className="d-flex align-items-center justify-content-end">

        <nav aria-label="breadcrumb">
          <ol class="breadcrumb justify-content-end">
            <li class="breadcrumb-item">
              <Link>
                <MdHome size={20} color="#4B49AC" />
              </Link>
            </li>
            <li class="breadcrumb-item">
              <Link
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                All Article
              </Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="parentlanglag">
      {Object.entries(languageOptionsWithFlag).map(([code, { name, flag }]) => (
        <button
          key={code}
          onClick={() => handleLanguageChange(code)}
          style={{
            backgroundColor: selectedLanguage === code ? "#4B49AC" : "transparent",
          }}
          className={`langflag ${selectedLanguage === code ? "selected" : ""}`}
        >
          <div>
        <img
            src={flag}
            // alt={`${name} flag`}
            style={{ width: "24px", height: "16px", borderRadius: "2px" }}
          />
          </div>
          <div>
          <span style={{fontSize:"11px" , fontWeight:"bold"}}>{name}</span></div>
        </button>
      ))}
    </div>
      <div className="container card rounded shadow-lg p-4">
        <div className="d-flex justify-content-between mb-4">
          <h4 className=" fw-bold middleclass">All Article</h4>
          <Input
            prefix={<FaSearch />}
            placeholder="Search by title"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              maxWidth: "300px",
              borderRadius: "50px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          />

        </div>
        {loading ? (
          <div className="loading-container">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="table-responsive">
            <table className="table table-bordered table-striped table-hover parentclass">
              <thead className="bgtable">
                <tr>
                  <th className="paddingclass">#</th>
                  <th className="paddingclass">Banner</th>
                  <th className="paddingclass">Title (English)</th>
                  <th className="paddingclass">Category</th>
                  <th className="paddingclass">Language</th>
                  <th className="paddingclass">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredArticles.length > 0 ? (
                  filteredArticles.map((article, index) => (
                    <tr key={article._id}>
                      {/* Serial Number */}
                      <td>{index + 1}</td>

                      {/* Banner Image */}
                      <td>
                        {article.bannerData?.[0]?.bannerImage ? (
                          <Image
                            src={article.bannerData[0].bannerImage}
                            className="img-fluid avatar rounded-circle"
                            alt={article.title.en}
                            style={{
                              width: "40px",
                              height: "40px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          "No Image"
                        )}
                      </td>

                      {/* Titles */}
                      <td>{article.title.en}</td>
                      <td>{article.category.name.en}</td>
                      <td>{article.languages.secondary}</td>

                      {/* Actions */}
                      <td>
                        <div className="d-flex justify-content-start" style={{ gap: "10px" }}>
                          <button
                            type="button"
                            className="btn btn-primary p-2"
                            style={{ border: "none", backgroundColor: "transparent" }}
                            onClick={() => navigate(`/articleDetail/${article._id}`)}
                          >
                            <FaEye color="black" size={18} />
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger p-2"
                            style={{ border: "none", backgroundColor: "transparent" }}
                            onClick={() => handleDeleteClick(article)}
                          >
                            <FaTrashAlt color="red" size={18} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center", fontWeight: "bold" }}>
                      No Articles Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

        )}
      </div>

      {showDeleteModal && (
        <DeleteArticleModal
          selectedArticle={selectedArticle}
          setShowDeleteModal={setShowDeleteModal}
          handleConfirmDelete={handleConfirmDelete}
        />
      )}
    </CommonLayout>
  );
};

export default AllArticle;
