import React, { useEffect, useState } from "react";
import CommonLayout from "../../layout/commonLayout/CommonLayout";
import { Link } from "react-router-dom";
import { MdHome } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import { Input, Modal, Button, Image } from "antd";
import {
  deleteCategory,
  fetchCategories,
  updateCategory,
  uploadCategoryImage,
} from "../../services/Apis/ApiServices";
import DeleteModal from "../../Component/DeleteModal";
import { toast } from "react-toastify";
import { FaTrashAlt, FaEye } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { languageOptions } from "../../utils/utils";
import { GrFormPreviousLink ,GrFormNextLink  } from "react-icons/gr";

function AllCategories() {
  const [searchTerm, setSearchTerm] = useState("");
  const [ currentPage , setCurrentPage ] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [file,setFile] = useState(null);
  const [editImage, setEditImage] = useState(null);
  const {TextArea} = Input;

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const getAllCategories = async (page = 1) => {
    try {
      setLoading(true);
      const response = await fetchCategories(page);

      if (response) {
        setCategories(response.categories);
        setTotalPages(response.totalPages);
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      setCategories([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCategories(currentPage);
  }, [currentPage]);

  const filteredCategories = (categories || []).filter((category) => {
    const search = searchTerm.toLowerCase();
    return (
      category.name.en.toLowerCase().includes(search) ||
      category.name.secondary.toLowerCase().includes(search)
    );
  });

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleShowMore = (category) => {
    setSelectedCategory(category);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedCategory(null);
  };

  const handleDeleteClick = (id) => {
    setSelectedCategory(id);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteCategory(selectedCategory._id);

      if (response?.success) {
        toast.success(response.message);
        getAllCategories();
        setShowDeleteModal(false);
      } else {
        toast.error(response?.message || "Failed to delete category.");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the category.");
      console.error("Delete error:", error);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setSelectedCategory(null);
  };

  const handleEditClick = (category) => {
    setCategoryToEdit(category);
    setIsEditModalVisible(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalVisible(false);
    setCategoryToEdit(null);
  };

  const handleImageChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const previewUrl = URL.createObjectURL(selectedFile);
      setImagePreview(previewUrl);
      setFile(selectedFile); // Store the file in state
    }
  };
  

  const handleSaveEdit = async () => {
    try {
      let imagePath = categoryToEdit.categoryImage;

      if (file) {
        try {
          const response = await uploadCategoryImage(file);

          if (response) {
             imagePath = response;
            setCategoryToEdit((prev) => ({
              ...prev,
              categoryImage: imagePath,
            }));
          } else {
            console.error("Image upload failed: No path returned from API");
          }
        } catch (error) {
          console.error("Image upload failed:", error);
        }
      }
      const apiData = {
        _id: categoryToEdit._id,
        name:{
          en: categoryToEdit.name.en,
          secondary: categoryToEdit.name.secondary,
        },
        description: {
          en: categoryToEdit.description.en,
          secondary: categoryToEdit.description.secondary,
        },
        language: {
          secondary: categoryToEdit.language.secondary,
        },
        categoryImage: imagePath,
      };
        
      const response = await updateCategory(apiData);
      if (response?.success) {
        toast.success("Category updated successfully!");
        getAllCategories();
        setImagePreview(null);
        setFile(null);
        handleEditModalClose();
      } else {
        toast.error("Failed to update category. Please try again.");
      }
    } catch (error) {
      console.error("Error updating category:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

    const handleEditChange = (e) => {
      const { name, value } = e.target;

      setCategoryToEdit((prevTag) => {
        if (name === "language") {
          return {
            ...prevTag,
            language: {
              ...prevTag.language,
              secondary: value,
            },
          };
        } else if (name === "titleEn") {
          return {
            ...prevTag,
            name: {
              ...prevTag.name,
              en: value,
            },
          };
        } else if (name === "titleSecondary") {
          return {
            ...prevTag,
            name: {
              ...prevTag.name,
              secondary: value,
            },
          };
        } else if (name === "descriptionEn") {
          return {
            ...prevTag,
            description: {
              ...prevTag.description,
              en: value,
            },
          };
        } else if (name === "descriptionSecondary") {
          return {
            ...prevTag,
            description: {
              ...prevTag.description,
              secondary: value,
            },
          };
        }
        return prevTag;
      });
    };


  return (
    <CommonLayout>
      <div className="d-flex align-items-center justify-content-end mb-5">
        {/* <Input
          prefix={<FaSearch />}
          placeholder="Search by title"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            maxWidth: "300px",
            borderRadius: "50px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        /> */}
        <nav aria-label="breadcrumb">
          <ol
            className="breadcrumb justify-content-end"
            style={{
              background: "transparent",
              padding: "0",
              margin: "0",
              fontSize: "14px",
            }}
          >
            <li className="breadcrumb-item">
              <Link>
                <MdHome size={20} color="#4B49AC" />
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                style={{
                  textDecoration: "none",
                  color: "#333",
                  fontWeight: "600",
                }}
              >
                All Categories
              </Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="container card rounded shadow-lg p-4">
      <div className="d-flex justify-content-between mb-4">
            <h4 className=" fw-bold middleclass">All Categories</h4>
            <Input
              prefix={<FaSearch />}
              placeholder="Search by created by or title"
              className="search-input"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                maxWidth: "300px",
                borderRadius: "50px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            />
          </div>
      {loading ? (
 <div className="loading-container">
 <div className="spinner-border text-primary" role="status">
   <span className="visually-hidden">Loading...</span>
 </div>
</div>
      ) : (
        <div className="table-responsive">
        <table className="table table-bordered">
        <thead className="bgtable">
          <tr>
            <th>Serial No</th>
            <th>Image</th>
            <th>Category Name</th>
            <th>Language</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredCategories.length > 0 ? (
            filteredCategories.map((category, index) => (
              <tr key={category._id}>
                <td>{index + 1}</td>
                <td>
                  <Image
                    src={category.categoryImage}
                    alt='No image'
                    className="img-fluid avatar rounded-circle"
                    style={{
                      width: "40px",
                      height: "40px",
                      objectFit: "cover",
                    }}
                  />
                </td>
                <td>{category.name.en}</td>
                <td>
                  {category.language.primary} / {category.language.secondary}
                </td>

                <td>
                  <div className="d-flex" style={{ gap: "10px" }}>
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      style={{ border: "none", backgroundColor: "transparent" }}
                      onClick={() => handleShowMore(category)}
                    >
                       <FaEye color="black" size={18} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      style={{ border: "none", backgroundColor: "transparent" }}
                      onClick={() => handleDeleteClick(category)}
                    >
                        <FaTrashAlt color="red" size={18} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-warning btn-sm"
                      style={{ border: "none", backgroundColor: "transparent" }}
                      onClick={() => handleEditClick(category)}
                    >
                      <MdModeEdit color="4B49AC" size={18} />
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" style={{ textAlign: "center", fontWeight: "bold" }}>
                No Categories Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      </div>
      )}
       <div className="d-flex justify-content-center align-items-center gap-3 mt-5">
          <button
            className="btn btn-outline-primary prevbtn"
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
             <GrFormPreviousLink size={20} />
          </button>
          <span className="fw-bold">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className="btn btn-outline-primary nextbtn"
          >
              <GrFormNextLink size={20} />
          </button>
        </div>

      </div>
      <Modal
        // title="Edit Category"
        visible={isEditModalVisible}
        onCancel={handleEditModalClose}
        footer={null}
        style={{ borderRadius: "15px" }}
      >
        {categoryToEdit && (
          <div>
            <div className="row mb-3">
              <label
                htmlFor="categoryName"
                className="form-label fw-bold"
                style={{ textAlign: "left" }}
              >
                Name (English)
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control formclass"
                  id="categoryName"
                  name="titleEn"
                  value={categoryToEdit.name.en}
                  onChange={handleEditChange}
                />
              </div>

              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control formclass"
                  id="categoryName"
                  name="titleSecondary"
                  value={categoryToEdit.name.secondary}
                  onChange={handleEditChange}
                />
              </div>
            </div>

            <label
              htmlFor="categoryDescription"
              className="form-label fw-bold"
              style={{ textAlign: "left" }}
            >
              Description
            </label>
            <div className="mb-3">
              <TextArea
                className="form-control mb-3"
                id="categoryDescription"
                name="descriptionEn"
                value={categoryToEdit.description.en}
                onChange={handleEditChange}
              />
              <TextArea
                className="form-control"
                id="categoryDescription"
                name="descriptionSecondary"
                value={categoryToEdit.description.secondary}
                onChange={handleEditChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="categoryLanguage" className="form-label fw-bold">
                Language
              </label>
              <select
                className="form-select"
                id="categoryLanguage"
                name="language"
                value={categoryToEdit.language.secondary}
                onChange={handleEditChange}
              >
                {Object.entries(languageOptions).map(([code, name]) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label
                htmlFor="categoryImageUpload"
                className="form-label fw-bold"
              >
                Upload Category Image
              </label>
              <input
                type="file"
                className="form-control formclass"
                id="categoryImageUpload"
                onChange={handleImageChange}
              />
            </div>

            <div className="mb-3">
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Category Preview"
                  className="img-fluid"
                  style={{ maxWidth: "100%", height: "100px" }}
                />
              ) : (
                <p></p>
              )}
            </div>

            <div
              className="mb-3"
              style={{ border: "1px solid #ccc", padding: "10px" }}
            >
              {categoryToEdit.categoryImage ? (
                <img
                  src={categoryToEdit.categoryImage}
                  alt="Category"
                  className="img-fluid"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              ) : (
                <p>No image available</p>
              )}
            </div>

            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleEditModalClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary ms-2"
                onClick={handleSaveEdit}
              >
                Save Changes
              </button>
            </div>
          </div>
        )}
      </Modal>

      <div>
        <DeleteModal
          isVisible={showDeleteModal}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      </div>

      {selectedCategory && (
        <Modal
          title={
            <h4
              style={{
                fontWeight: "bold",
                margin: "0",
                textAlign: "center",
                marginBottom: "35px",
              }}
            >
              {selectedCategory.name.en} / {selectedCategory.name.secondary}
            </h4>
          }
          visible={isModalVisible}
          onCancel={handleModalClose}
          footer={[
            <Button key="close" onClick={handleModalClose}>
              Close
            </Button>,
          ]}
          style={{ borderRadius: "15px" }}
        >
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1 }}>
              <h5 style={{ fontWeight: "bold" }}> Description</h5>
              <p style={{ fontSize: "14px", color: "#555" }}>
                {selectedCategory.description.en || "No description available"}
              </p>
            </div>

            <div style={{ flex: 1 }}>
              <h5 style={{ fontWeight: "bold", marginBottom: "23px" }}> </h5>
              <p style={{ fontSize: "14px", color: "#555" }}>
                {selectedCategory.description.secondary ||
                  "No description available"}
              </p>
            </div>
          </div>
        </Modal>
      )}
    </CommonLayout>
  );
}

export default AllCategories;
