import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../components/Auth/AuthApi";
import { toast } from "react-toastify";
import "../sidebar/sidebar.css";
import { useAvatar } from "../../components/avatarContext/AvatarContext";
import { RiArrowDropDownFill } from "react-icons/ri";
function Navbar({ onToggleSidebar }) {
  const navigate = useNavigate();
  const { avatar } = useAvatar();

  const avatarUrl = avatar || "./assets/images/defualt-profile.png";
  const handleLogout = () => {
    logoutUser();
    toast.success("Logout Successfully");
    navigate("/");
  };
  return (
    <div>
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row classshadow">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
        <Link to={'/Dashboard'} className="navbar-brand brand-logo me-5" >
          <img
            src="../../../assets/images/takemy-logo.png"
            className="me-2"
            alt="logo"
          />
        </Link>
        <Link to={'/Dashboard'} className="navbar-brand brand-logo-mini">
          <img src="../../../assets/images/takemy-logo.png" alt="logo" />
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        {/* Sidebar Toggle Button */}
      

        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile dropdown droplist m-0">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              data-bs-toggle="dropdown"
              id="profileDropdown"
            >
              <img src={avatarUrl} alt="profile" />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="profileDropdown"
            >
              <Link className="dropdown-item" to="/Profile">
                <i className="fa-solid fa-user"></i> Profile{" "}
              </Link>
              <Link className="dropdown-item" to="/changePassword">
                <i className="fa-solid fa-unlock text-primary"></i> Change
                Password{" "}
              </Link>
              <Link
                className="dropdown-item"
                to="/"
                onClick={handleLogout}
              >
                <i className="ti-power-off text-primary"></i> Logout{" "}
              </Link>
            </div>
          </li>
          <RiArrowDropDownFill size={20} className="mt-3" />
        </ul>

        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          onClick={onToggleSidebar} // Toggle sidebar visibility
        >
          <span className="icon-menu"></span>
        </button>
      </div>
    </nav>
  </div>
  );
}

export default Navbar;
