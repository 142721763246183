import React, { useEffect, useState , useRef} from "react";
import CommonLayout from "../../layout/commonLayout/CommonLayout";
import { handleDeleteArticleForm, languageOptions } from "../../utils/utils";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import { FaSearch, FaPlus, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom"
import FroalaEditorSecondary from "../../components/Editor/FroalaEditorSecondary";
import { checkArticleAvalability, createArticle, fetchAllArticles, fetchAllArticlesforNewArticle, fetchArticleBySearch, fetchBannersByCategory, fetchCategories, fetchCategoryBySubCategory, fetchTagsBySearch, uploadNewArticleImage } from "../../services/Apis/ApiServices";
import { Input } from 'antd';
import JoditEditor from 'jodit-react';
import CopyClipBoardModal from "../../components/models/CopyClipBoardModal";
const SecondaryArticleForm = () => {
    const navigate = useNavigate();
    const { TextArea } = Input;
    const [formData, setFormData] = useState({
        languages: "",
        titleEn: "",
        descriptionEn: "",
        bioHtml: "",
        seoWordsEn: "",
        seoDescriptionEn: "",
        productLinks: [""],
        englishContent: "",
    });
    const [categories, setCategories] = useState([]);
    const [lang, setLang] = useState();
    const [islang, setIsLang] = useState(false);
    const [subCatgory, setSubCategory] = useState([]);
    const [banner, setbanner] = useState([]);
    const [selectedArticles, setSelectedArticles] = useState([]);
    const [article, setArticle] = useState([]);
    const [relatedArticles, setRelatedArticles] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [relatedArticleSearch, setRelatedArticleSearch] = useState("");
    const [englishContentSeach, setEnglishContentSearch] = useState("");
    const [tags, setTags] = useState([]);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);
    const [productLinks, setProductLinks] = useState([""]);
    const [additionalForms, setAdditionalForms] = useState([]);
    const [availabilityMessage, setAvailabilityMessage] = useState("");
    const [handleLang, setHandleLang] = useState()
    const [selectedEnglishArticle, setSelectedEnglishArticle] = useState(null);

    const [imagePath, setImagePath] = useState("");
    const [isCopied, setIsCopied] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageloading, setImageLoading] = useState(false);
    const editor = useRef(null)

    const rtlLanguages = ["ar", "ur"];

    const [errors, setErrors] = useState({
        languages: "",
        titleSecondary: "",
        descriptionSecondary: "",
        bioHtmlSecondary: '',
        category: "",
        banner: "",
        tag: "",
        seoWordsSecondary: "",
        seodescriptionSecondary: "",
        link: "",
    });

    const handleLanguageSelect = (language) => {
        if (formData.languages === language) return;
        setLang(language);
        setIsLang(true);
        setHandleLang(language);
        const isRtl = rtlLanguages.includes(language);
        setFormData({ ...formData, languages: language, });
        setErrors((prevErrors) => ({ ...prevErrors, languages: "" }));
        const existingFormIndex = additionalForms[0] ? true : false;

        if (existingFormIndex !== -1) {
            setAdditionalForms([]);
            setFormData({
                titleSecondary: "",
                descriptionSecondary: "",
                bioHtmlSecondary: "",
                seoWordsSecondary: "",
                seodescriptionSecondary: "",
                englishContent: '',
            });
            setAdditionalForms((prevForms) => [
                ...prevForms,
                { language, isRtl, formData: { ...formData, languages: language } },
            ]);

        } else {
            setAdditionalForms((prevForms) => [
                ...prevForms,
                { language, isRtl, formData: { titleSecondary: "", descriptionSecondary: "", bioHtmlSecondary: '', seoWordsSecondary: "", seodescriptionSecondary: "", } },
            ]);
        }
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                setImageLoading(true)
                const response = await uploadNewArticleImage(file);

                if (response) {
                    setImagePath(response);
                    setIsModalOpen(true);
                    setIsCopied(false);
                } else {
                    toast.error("Image upload failed. Please try again.");
                }
            } catch (error) {
                console.error("Error uploading image:", error);
                toast.error("An error occurred while uploading the image.");
            } finally {
                setImageLoading(false)
            }
        }
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(imagePath).then(() => {
            setIsCopied(true);
            setTimeout(() => {
                setIsModalOpen(false);
                setIsCopied(false);
            }, 300)
        });
    };
    const fetchArticle = async (code) => {
        if (relatedArticleSearch?.length > 0) {
            const result = await fetchArticleBySearch(relatedArticleSearch, code);
            setRelatedArticles(result);
        }

        else if (englishContentSeach.length > 0) {
            const result = await fetchArticleBySearch(englishContentSeach, code);
            setArticle(result);
        }

        else {
            setRelatedArticleSearch([]);
            setEnglishContentSearch([]);
        }
    };

    const fetchTags = async () => {
        if (searchQuery?.length > 0) {
            const result = await fetchTagsBySearch(searchQuery);
            setTags(result);
        } else {
            setTags([]);
        }
    };

    const handleSearchChange = (event) => {
        const { name, value } = event.target;

        if (name == "tag") {
            setSearchQuery(value);
            setDebounceTimeout(setTimeout(fetchTags, 300));
        }
        else if (name == "relatedArticle") {
            setRelatedArticleSearch(value);

            setDebounceTimeout(setTimeout(() => {
                fetchArticle(lang)
            }, 300));
        }
        else if (name == 'englishContent') {
            setEnglishContentSearch(value)
            setFormData((prevFormData) => ({ ...prevFormData, englishContent: value }));
            setDebounceTimeout(setTimeout(() => {
                fetchArticle('en')
            }, 300));
        }

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
    };

    const handleTagSelect = (tag) => {
        setSelectedTags((prev) => [...prev, tag]);
        setSearchQuery("");
        setTags([]);
    };

    const handleRelatedArctileSelect = (article) => {
        setSelectedArticles((prev) => [...prev, article]);
        setRelatedArticleSearch("");
        setRelatedArticles([]);
    }

    const handleEnglishContentSelect = (article) => {
        setSelectedEnglishArticle(article);
        setFormData((prevFormData) => ({
            ...prevFormData,
            englishContent: article._id,
        }));
        setEnglishContentSearch("");
    };

    const handleTagRemove = (tagId) => {
        setSelectedTags((prev) => prev.filter((tag) => tag._id !== tagId));
    };

    const handleArticleRemove = (articleId) => {
        setSelectedArticles((prev) => prev.filter((article) => article._id !== articleId));
    };

    const handleClearEnglishContent = () => {
        setArticle([]);
        setSelectedEnglishArticle(null);
        setEnglishContentSearch("");
    };
    const getCategories = async () => {
        try {
            const fetchedCategories = await fetchCategories();
            if (fetchedCategories?.length === 0) {
            } else {
                setCategories(fetchedCategories.categories);
            }
        } catch (err) {
            console.error("Error in getCategories:", err);
        }
    };


    const checkArticleAvailability = async (articleId, langCode) => {
        try {

            const response = await checkArticleAvalability({
                _id: articleId,
                langCode: langCode
            });
            if (response) {
                toast.error(response.message);

            }
        } catch (error) {
            toast.error('Error checking article availability.');


        }
    };

    useEffect(() => {
        getCategories();
    }, []);


    const handleCategoryChange = async (categoryId) => {
        setbanner([]);
        setFormData((prevFormData) => ({ ...prevFormData, category: categoryId }));
        if (categoryId) {
            try {
                const fetchedSubCategory = await fetchCategoryBySubCategory(categoryId);
                if (fetchedSubCategory.length > 0) {
                    setSubCategory(fetchedSubCategory);
                }
            } catch (err) {
                console.error("Error fetching data:", err);
            }
        }
        if(categoryId){
            try{
                const fetchedBanner = await fetchBannersByCategory(categoryId);
                if (fetchedBanner.length > 0) {
                    setbanner(fetchedBanner);
                }
            }catch(err){    
                console.error("Error fetching data:", err);
            }
        }
        else {
            setbanner([]);
            setSubCategory([]);
        }
    };

    const handleSubCategoryChange = async (subCategoryId) => {
        setFormData((prevFormData) => ({ ...prevFormData, subCatgory: subCategoryId }));
       
    };



    const handleFormChange = (e, language) => {
        const { name, value } = e.target;

        setAdditionalForms((prevForms) =>
            prevForms.map((form) =>
                form.language === language
                    ? {
                        ...form,
                        formData: {
                            ...form.formData,
                            [name]: value,
                        },
                    }
                    : form
            )
        );
        if (value.trim() !== "") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: "",
            }));
        }
    };

    const handleSecondEditorChange = (content, editor, language) => {
        setAdditionalForms((prevForms) =>
            prevForms.map((form) =>
                form.language === language
                    ? {
                        ...form,
                        formData: {
                            ...form.formData,
                            bioHtmlSecondary: content,
                        },
                    }
                    : form
            )
        );
        if (content.trim() !== "") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                bioHtmlSecondary: "",
            }));
        }
    };


    const handlelanguageArticleSelect = (e) => {
        const selectedArticleId = e.target.value;

        setFormData({ ...formData, englishContent: selectedArticleId });

        if (selectedArticleId) {
            checkArticleAvailability(selectedArticleId, handleLang);
        } else {
            setAvailabilityMessage("")
        }
    };
    const handleArticleSelect = (e) => {
        const selectedId = e.target.value;

        // Find the selected article
        const selectedArticle = relatedArticles.find((article) => article._id === selectedId);

        if (selectedArticle && !selectedArticles.some((a) => a._id === selectedId)) {
            setSelectedArticles([...selectedArticles, selectedArticle]);
        }
    };

    const handleRemoveArticle = (id) => {
        setSelectedArticles(selectedArticles.filter((article) => article._id !== id));
    };
    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({
            titleSecondary: "",
            descriptionSecondary: "",
            bioHtmlSecondary: "",
            languages: "",
            seoWordsSecondary: "",
            seodescriptionSecondary: "",
            link: "",
        });

        let isValid = true;
        const trimmedFormData = {
            ...formData,
            languages: formData.languages,
            titleSecondary: additionalForms[0]?.formData?.titleSecondary?.trim(),
            descriptionSecondary: additionalForms[0]?.formData?.descriptionSecondary?.trim(),
            bioHtmlSecondary: additionalForms[0]?.formData?.bioHtmlSecondary?.trim(),
            seoWordsSecondary: additionalForms[0]?.formData?.seoWordsSecondary?.trim(),
            seodescriptionSecondary: additionalForms[0]?.formData?.seodescriptionSecondary?.trim(),
            link: productLinks[0]?.trim(),
        };
        if (!trimmedFormData.languages) {
            setErrors((prevErrors) => ({ ...prevErrors, languages: "Language is required" }));
            isValid = false;
        }

        if (!trimmedFormData.titleSecondary) {
            setErrors((prevErrors) => ({ ...prevErrors, titleSecondary: "Title is required" }));
            isValid = false;
        }

        if (!trimmedFormData.descriptionSecondary) {
            setErrors((prevErrors) => ({ ...prevErrors, descriptionSecondary: "Description is required" }));
            isValid = false;
        }

        if (!trimmedFormData.bioHtmlSecondary) {
            setErrors((prevErrors) => ({ ...prevErrors, bioHtmlSecondary: "Content is required" }));
            isValid = false;
        }

        if (!trimmedFormData.seoWordsSecondary) {
            setErrors((prevErrors) => ({ ...prevErrors, seoWordsSecondary: "SEO Keywords are required" }));
            isValid = false;
        }

        if (!trimmedFormData.seodescriptionSecondary) {
            setErrors((prevErrors) => ({ ...prevErrors, seodescriptionSecondary: "SEO Description is required" }));
            isValid = false;
        }

        if (!trimmedFormData.link) {
            setErrors((prevErrors) => ({ ...prevErrors, link: "Product link is required" }));
            isValid = false;
        }
        if (!isValid) return;
        const apiData = {
            title: trimmedFormData.titleSecondary,
            shortDescription: trimmedFormData.descriptionSecondary,
            languages: trimmedFormData.languages,
            content: trimmedFormData.bioHtmlSecondary,

            category: formData.category,
            banner: formData.banner,
            subCategory: formData.subCatgory,
            relatedArticles: selectedArticles,
            tags: selectedTags,
            seo: {
                metaKeywords: trimmedFormData.seoWordsSecondary,
                metaDescription: trimmedFormData.seodescriptionSecondary,
            },
            amazonAffiliateLinks: productLinks,
            englishContent: formData.englishContent
            // englishContent
        };

        try {
            const response = await createArticle(apiData);
            if (response) {
                toast.success(response.message || "Article created successfully");
                navigate('/Dashboard');
                setFormData({
                    titleEn: "",
                    descriptionEn: "",
                    languages: "",
                    category: "",
                    seoWordsEn: "",
                    seoDescriptionEn: "",
                    bioHtml: "",
                    banner: "",
                    subCatgory: "",
                });
                setTags([]);
                setProductLinks([""]);
                setArticle([]);
            } else {
                toast.error(
                    response?.message || "An error occurred while creating the tag"
                );
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error("There was an error submitting the form.");
        }

    };

    const handleLinkChange = (index, e) => {
        const { value } = e.target;
        setProductLinks((prevLinks) =>
            prevLinks.map((link, i) => (i === index ? value : link))
        );
        if (value.trim() !== "") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                link: "",
            }));
        }
    };

    const handleAddLink = () => {
        setProductLinks((prevLinks) => [...prevLinks, ""]);
    };

    const handleDeleteLink = (index) => {
        setProductLinks((prevLinks) => prevLinks.filter((_, i) => i !== index));
    };


const closeModal = () => {
    setIsModalOpen(false);
}

    return (
        <CommonLayout>
            <div className="my-3">
                <h3 className="text-center mb-4 text-primary fw-bold">Secondary Language Article Form </h3>

                {/* Language Selection */}
                <div className="card p-4 mb-3">
                    <form>
                        <div className="mb-3">
                            <label className="form-label fw-bold">Language</label>
                            <select
                                className="form-select formclass"
                                name="languages"
                                value={formData.languages}
                                onChange={(e) => {
                                    handleLanguageSelect(e.target.value);
                                    setFormData({ ...formData, languages: e.target.value });
                                }}
                            >
                                <option value="" disabled>
                                    Select Language ---
                                </option>
                                {Object.entries(languageOptions).map(([code, name]) => (
                                    <option
                                        key={code}
                                        value={code}
                                        disabled={additionalForms.some(
                                            (form) => form.language === code
                                        )}
                                    >
                                        {name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {errors.languages && (
                            <div className="text-danger">{errors.languages}</div>
                        )}
                    </form>
                </div>

                <div className="card p-4 mb-3">
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <label className="form-label fw-bold"> Category</label>
                            <select
                                className="form-select"
                                value={formData.category}
                                onChange={(e) => {
                                    handleCategoryChange(e.target.value)
                                    setFormData({ ...formData, category: e.target.value })
                                }
                                }
                            >
                                <option value="">select category</option>
                                {categories?.length > 0 ? (
                                    categories.map((category) => (
                                        <option key={category._id} value={category._id}>
                                            {typeof category.name === "object"
                                                ? category.name.en
                                                : category.name}
                                        </option>
                                    ))
                                ) : (
                                    <option disabled>No categories available</option>
                                )}
                            </select>

                        </div>

                        <div className="col-md-4">
                            <label className="form-label fw-bold"> Sub category</label>
                            <select className="form-select" value={formData.subCatgory}
                                onChange={(e) => {
                                    handleSubCategoryChange(e.target.value)
                                    setFormData({ ...formData, subCatgory: e.target.value })
                                }
                                }>
                                <option value="">select sub category</option>
                                {subCatgory?.length > 0 ? (
                                    subCatgory?.map((subCatgory) => (
                                        <option key={subCatgory._id} value={subCatgory._id}>
                                            {typeof subCatgory.name === "object"
                                                ? subCatgory.name.en
                                                : subCatgory.name}
                                        </option>
                                    ))
                                ) : (
                                    <option disabled>No sub category available</option>
                                )}
                            </select>

                        </div>

                        <div className="col-md-4">
                            <label className="form-label fw-bold"> Banner</label>
                            <select className="form-select" value={formData.banner}
                                onChange={(e) =>
                                    setFormData({ ...formData, banner: e.target.value })
                                }>
                                <option value="">select banner</option>
                                {banner?.length > 0 ? (
                                    banner?.map((banner) => (
                                        <option key={banner._id} value={banner._id}>
                                            {typeof banner.title === "object"
                                                ? banner.title.en
                                                : banner.title}
                                        </option>
                                    ))
                                ) : (
                                    <option disabled>No banner available</option>
                                )}
                            </select>

                        </div>
                    </div>

                    <div className="row mb-3">
                        {/* <div className="col-md-4">
                            <label className="form-label fw-bold"> Choose english article</label>
                            <select
                                className="form-select"
                                name="englishContent"
                                value={formData.englishContent}
                                onChange={handlelanguageArticleSelect}>
                                <option value="">select article</option>

                                {article?.length > 0 ? (
                                    article?.map((article) => (
                                        <option key={article._id} value={article._id}>
                                            {typeof article.title === "object" ? article.title.en : article.title}
                                        </option>
                                    ))
                                ) : (
                                    <option disabled>No article available</option>
                                )}
                            </select>

                            {availabilityMessage && (
                                <div className="mt-2">
                                    <span>{availabilityMessage}</span>
                                </div>
                            )}

                        </div> */}
                        <div className="col-md-4">
                            <label className="form-label fw-bold"> Choose English Article</label>
                            <div className="input-group">
                                <span className="input-group-text">
                                    <FaSearch />
                                </span>
                                <input
                                    type="text"
                                    name="englishContent"
                                    className="form-control formclass"
                                    placeholder="Search english articles..."
                                    value={selectedEnglishArticle?.title || englishContentSeach}
                                    onChange={(e) => {
                                        handleSearchChange(e);
                                        setFormData({ ...formData, englishContent: e.target.value })

                                    }}
                                    disabled={!!selectedEnglishArticle}
                                />
                                {selectedEnglishArticle && (
                                    <span
                                        className="input-group-text"
                                        style={{ cursor: "pointer" }}
                                        onClick={handleClearEnglishContent}
                                    >
                                        ✕
                                    </span>
                                )}
                            </div>
                            {article?.length > 0 && !selectedEnglishArticle && (
                                <div className="search-results">
                                    {article?.map((art) => (
                                        <div
                                            key={art._id}
                                            className="search-result-item"
                                            onClick={() => handleEnglishContentSelect(art)}
                                        >
                                            <span>
                                                {art.title}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>


                        <div className="col-md-4">
                            <label className="form-label fw-bold"> Related Article</label>
                            <div className="input-group">
                                <span className="input-group-text">
                                    <FaSearch />
                                </span>
                                <input
                                    type="text"
                                    name="relatedArticle"
                                    className="form-control formclass"
                                    placeholder="Search related articles..."
                                    value={relatedArticleSearch}
                                    onChange={handleSearchChange}
                                />
                            </div>
                            {relatedArticles?.length > 0 && (
                                <div className="search-results">
                                    {relatedArticles?.map((article) => (
                                        <div
                                            key={article._id}
                                            className="search-result-item"
                                            onClick={() => handleRelatedArctileSelect(article)}
                                        >
                                            <span>
                                                {article.title}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {/* Display selected tags */}
                            {selectedArticles?.length > 0 && (
                                <div className="selected-tags">
                                    {selectedArticles?.map((article) => (
                                        <div key={article._id} className="selected-tag">
                                            <span>{article.title}/</span>
                                            <button
                                                type="button" className="tagclass"
                                                onClick={() => handleArticleRemove(article._id)}
                                            >
                                                X
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className="col-md-4">
                            <label className="form-label fw-bold"> Tags</label>
                            <div className="input-group">
                                <span className="input-group-text">
                                    <FaSearch />
                                </span>
                                <input
                                    type="text"
                                    name="tag"
                                    className="form-control formclass"
                                    placeholder="Search tags..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </div>
                            {tags?.length > 0 && (
                                <div className="search-results">
                                    {tags?.map((tag) => (
                                        <div
                                            key={tag._id}
                                            className="search-result-item"
                                            onClick={() => handleTagSelect(tag)}
                                        >
                                            <span>
                                                {tag.title.en} / {tag.title.secondary}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {/* Display selected tags */}
                            {selectedTags?.length > 0 && (
                                <div className="selected-tags">
                                    {selectedTags?.map((tag) => (
                                        <div key={tag._id} className="selected-tag">
                                            <span>{tag.title.en}/</span>
                                            <span>{tag.title.secondary}</span>
                                            <button
                                                type="button" className="tagclass"
                                                onClick={() => handleTagRemove(tag._id)}
                                            >
                                                X
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Additional Language Forms */}
                {additionalForms.map((form, index) => (
                    <div key={index} className="card p-4 mb-3" dir={form.isRtl ? "rtl" : "ltr"}>
                        <div className="d-flex justify-content-between">
                            <h4 className="text-primary fw-bold text-center">
                                Fill from in  {languageOptions[form.language]} language
                            </h4>
                            <button
                                type="button"
                                className="btn btn-danger btn-sm text-light"
                                onClick={() =>
                                    handleDeleteArticleForm(index, formData, setFormData, additionalForms, setAdditionalForms)
                                }
                            >
                                X
                            </button>
                        </div>
                        <form>
                            <div className="row">
                                <div className="mb-3 col-md-6">
                                    <label className="form-label fw-bold">Title</label>
                                    <input
                                        type="text"
                                        className="form-control formclass"
                                        name="titleSecondary"
                                        value={form.formData.titleSecondary}
                                        onChange={(e) => handleFormChange(e, form.language)}
                                        placeholder={`Enter title in ${languageOptions[form.language]}`}
                                        dir={form.isRtl ? "rtl" : "ltr"}
                                        required
                                    />
                                    {errors.titleSecondary && (
                                        <div className="text-danger">{errors.titleSecondary}</div>
                                    )}
                                </div>

                                <div className="mb-3 col-md-6">
                                    <label className="form-label fw-bold">Description</label>
                                    <TextArea
                                        className="form-control formclass"
                                        name="descriptionSecondary"
                                        value={form.formData.descriptionSecondary}
                                        onChange={(e) => handleFormChange(e, form.language)}
                                        placeholder={`Enter description in ${languageOptions[form.language]}`}
                                        dir={form.isRtl ? "rtl" : "ltr"}
                                        required
                                    />
                                    {errors.descriptionSecondary && (
                                        <div className="text-danger">{errors.descriptionSecondary}</div>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="mb-3 col-md-6">
                                    <label className="form-label fw-bold">SEO metakeywords</label>
                                    <input
                                        type="text"
                                        className="form-control formclass"
                                        name="seoWordsSecondary"
                                        value={form.formData.seoWordsSecondary}
                                        onChange={(e) => handleFormChange(e, form.language)}
                                        placeholder={`Enter seo Words in ${languageOptions[form.language]}`}
                                        dir={form.isRtl ? "rtl" : "ltr"}
                                        required
                                    />
                                    {errors.seoWordsSecondary && (
                                        <div className="text-danger">{errors.seoWordsSecondary}</div>
                                    )}
                                </div>


                                <div className="mb-3 col-md-6">
                                    <label className="form-label fw-bold">SEO Description</label>
                                    <TextArea
                                        className="form-control formclass"
                                        name="seodescriptionSecondary"
                                        value={form.formData.seodescriptionSecondary}
                                        onChange={(e) => handleFormChange(e, form.language)}
                                        placeholder={`Enter seo description  in  ${languageOptions[form.language]}`}
                                        dir={form.isRtl ? "rtl" : "ltr"}
                                        required
                                    />
                                    {errors.seodescriptionSecondary && (
                                        <div className="text-danger">{errors.seodescriptionSecondary}</div>
                                    )}
                                </div>
                            </div>

                            <div>
                                <label className="form-label fw-bold">Choose image for Article content </label>
                                <div className="mb-4" style={{ marginBottom: "20px" }}>

                                    <input
                                        type="file"
                                        className="form-control formclass"
                                        name="image"
                                        onChange={handleImageUpload}
                                    />
                                </div>
                                {imageloading && (
                                    <div
                                        style={{
                                            position: "fixed",
                                            top: 0,
                                            left: 0,
                                            width: "100vw",
                                            height: "100vh",
                                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                                            backdropFilter: "blur(5px)",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            zIndex: 1050,
                                        }}
                                    >
                                        <div
                                            className="spinner-border text-light"
                                            role="status"
                                            style={{ width: "3rem", height: "3rem" }}
                                        >
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                                {!imageloading && isModalOpen && (
                                    <CopyClipBoardModal
                                        imagePath={imagePath}
                                        isCopied={isCopied}
                                        handleCopy={handleCopy}
                                        closeModal={closeModal}
                                    />
                                )}
                            </div>

                            <Accordion>
                                <AccordionItem className="mb-3">
                                    <AccordionItemHeading>
                                        <AccordionItemButton style={{ fontWeight: "bold" }}>
                                            {`Enter content in ${languageOptions[form.language]}`}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <JoditEditor value={form.formData.bioHtmlSecondary} onChange={(content, editor) => handleSecondEditorChange(content, editor, form.language)} dir={form.isRtl ? "rtl" : "ltr"} />

                                    </AccordionItemPanel>
                                </AccordionItem>
                                {errors.bioHtmlSecondary && (
                                    <div className="text-danger">{errors.bioHtmlSecondary}</div>
                                )}
                            </Accordion>

                        </form>
                    </div>
                ))}

                <div className="card p-4 mb-3">
                    <div className="mb-3 col-md-12">
                        <label className="form-label fw-bold">Product Links</label>
                        {productLinks?.map((link, index) => (
                            <div key={index} className="d-flex align-items-center mb-2">
                                <input
                                    type="text"
                                    className="form-control formclass"
                                    name={`productLink${index}`}
                                    value={link} // Display the individual link value
                                    onChange={(e) => handleLinkChange(index, e)}
                                    placeholder="Enter product link"
                                    required
                                />

                                {/* Add button */}
                                {index === productLinks?.length - 1 && (
                                    <button
                                        type="button"
                                        onClick={handleAddLink}
                                        style={{ border: "none", backgroundColor: "transparent" }}
                                    >
                                        <FaPlus color="black" size={18} />
                                    </button>
                                )}

                                {/* Delete button */}
                                {productLinks?.length > 1 && (
                                    <button
                                        type="button"
                                        onClick={() => handleDeleteLink(index)}
                                        style={{ border: "none", backgroundColor: "transparent" }}
                                    >
                                        <FaTrashAlt color="red" size={18} />
                                    </button>
                                )}
                            </div>
                        ))}
                        {errors.link && (
                            <div className="text-danger">{errors.link}</div>
                        )}


                    </div>
                </div>
                <div className="text-end mt-4">
                    <button className="btn btn-primary" type="submit" onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
            </div>
        </CommonLayout>
    );
};

export default SecondaryArticleForm;
