import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { GrUserManager } from "react-icons/gr";
import { BiCategory } from "react-icons/bi";
import { MdOutlineArticle } from "react-icons/md";
import { FaHashtag } from "react-icons/fa";
import { PiFlagBannerFoldFill } from "react-icons/pi";
import { BiSolidDashboard } from "react-icons/bi";
import Cookies from 'js-cookie';
import './sidebar.css'

function Sidebar({ isSidebarOpen , onCloseSidebar}) {
  const permissions = JSON.parse(Cookies.get('permissions'));
  const location = useLocation();

  const isActive = (path) => location.pathname === path;
  const handleLinkClick = (path) => {
    if (location.pathname !== path) {
      onCloseSidebar();
    }
  };
  return (
    <div
      className={`sidebar-container newcsideclass ${
        isSidebarOpen ? "open" : "closed"
      } shadow-lg`}
    >
      <nav class="sidebar">
        <ul class="nav">
          <li className={`nav-item ${isActive('/Dashboard') ? 'active' : ''}`}>
            <Link class="nav-link" to='/Dashboard' onClick={handleLinkClick}>
              <BiSolidDashboard size={20} className='menu-icon me-2' />
              <span class="menu-title">Dashboard</span>
            </Link>
          </li>


          {permissions.manageUsers && (
            <li className={`nav-item ${isActive('/AllManager') || isActive('/ContentManager') ? 'active' : ''}`}>
              <a class="nav-link" data-bs-toggle="collapse" href="#charts" aria-expanded="false" aria-controls="charts">
                <GrUserManager size={20} className='menu-icon me-2' />
                <span class="menu-title">Content Manager</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="charts">
                <ul class="nav flex-column sub-menu">
                  <li className={`nav-item ${isActive('/AllManager') ? 'active' : ''}`}>
                    <Link class="nav-link" to='/AllManager'  onClick={handleLinkClick} >All Managers</Link>
                  </li>
                  <li className={`nav-item ${isActive('/ContentManager') ? 'active' : ''}`}>
                    <Link class="nav-link" to='/ContentManager'  onClick={handleLinkClick}>Add Manager</Link>
                  </li>
                </ul>
              </div>
            </li>
          )}
          {permissions.manageCategories && (
            <li className={`nav-item ${isActive('/AllSubCategory') || isActive('/CreateSubCategory') ? 'active' : ''}`}>
              <a class="nav-link" data-bs-toggle="collapse" href="#ui-basicSubb" aria-expanded="false" aria-controls="ui-basic">
              <BiCategory size={20} className='menu-icon me-2' />
                <span class="menu-title">Sub-Category</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="ui-basicSubb">
                <ul class="nav flex-column sub-menu">
                  <li className={`nav-item ${isActive('/AllSubCategory') ? 'active' : ''}`}> <Link class="nav-link" to='/AllSubCategory'  onClick={handleLinkClick}> All Sub Category</Link></li>
                  <li className={`nav-item ${isActive('/CreateSubCategory') ? 'active' : ''}`}><Link class="nav-link" to='/CreateSubCategory'  onClick={handleLinkClick}>Add Sub Category</Link></li>
                </ul>
              </div>
            </li>
          )}
          {permissions.manageArticles && (
            <li class={`nav-item ${isActive('/EnglishArticleForm') || isActive('/SecondaryArticleForm') ? 'active' : ''}`}>
              <a class="nav-link" data-bs-toggle="collapse" href="#form-elements" aria-expanded="false" aria-controls="form-elements">
                <MdOutlineArticle size={20} className='menu-icon me-2' />
                <span class="menu-title">Article </span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="form-elements">
                <ul class="nav flex-column sub-menu">

                  {/* <li class={`nav-item ${isActive('/AllArticle') ? 'active' : ''}`}>
                    <Link class="nav-link" to='/AllArticle'>All Article </Link>
                  </li> */}

                  <li class={`nav-item ${isActive('/EnglishArticleForm') ? 'active' : ''}`}>
                    <Link class="nav-link" to='/EnglishArticleForm'  onClick={handleLinkClick}>English Article Form </Link>
                  </li>

                  {/* <li class={`nav-item ${isActive('/ArticalForm') ? 'active' : ''}`}>
                    <Link class="nav-link" to='/ArticalForm'>Article Form</Link>
                  </li> */}

                  <li class={`nav-item ${isActive('/SecondaryArticleForm') ? 'active' : ''}`}>
                    <Link class="nav-link" to='/SecondaryArticleForm'  onClick={handleLinkClick}>Secondary Article Form</Link>
                  </li>
                </ul>
              </div>
            </li>
          )}
          {permissions.manageTags && (
            <li class={`nav-item ${isActive('/AllTag') || isActive('/CreateTag') ? 'active' : ''}`}>
              <a class="nav-link" data-bs-toggle="collapse" href="#tables" aria-expanded="false" aria-controls="tables">
                <FaHashtag size={20} className='menu-icon me-2' />
                <span class="menu-title">Tags</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="tables">
                <ul class="nav flex-column sub-menu">
                  <li class={`nav-item ${isActive('/AllTag') ? 'active' : ''}`}>
                    <Link class="nav-link" to='/AllTag'  onClick={handleLinkClick}>All Tag</Link>
                  </li>

                  <li class={`nav-item ${isActive('/CreateTag') ? 'active' : ''}`}>
                    <Link class="nav-link" to='/CreateTag'  onClick={handleLinkClick}>Create Tag</Link>
                  </li>
                </ul>
              </div>
            </li>
          )}
          {permissions.manageBanners && (
            <li class={`nav-item ${isActive('/AllBanner') || isActive('/CreateBanner') ? 'active' : ''}`}>
              <a class="nav-link" data-bs-toggle="collapse" href="#icons" aria-expanded="false" aria-controls="icons">
                <PiFlagBannerFoldFill size={20} className='menu-icon me-2' />
                <span class="menu-title">Banner</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="icons">
                <ul class="nav flex-column sub-menu">
                  <li class={`nav-item ${isActive('/AllBanner') ? 'active' : ''}`}>
                    <Link class="nav-link" to='/AllBanner'  onClick={handleLinkClick}>All Banner</Link>
                  </li>
                  <li class={`nav-item ${isActive('/CreateBanner') ? 'active' : ''}`}>
                    <Link class="nav-link" to='/CreateBanner'  onClick={handleLinkClick}>Add Banner</Link>
                  </li>
                </ul>
              </div>
            </li>
          )}
        </ul>
      </nav>
    </div>
  )
}

export default Sidebar