import React, { useState , useEffect } from "react";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";

function CommonLayout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    // Retrieve sidebar state from localStorage
    const savedState = localStorage.getItem("isSidebarOpen");
    return savedState === "true";
  });


  const handleToggleSidebar = () => {
    const newState = !isSidebarOpen;
    setIsSidebarOpen(newState);
    localStorage.setItem("isSidebarOpen", newState); // Save state in localStorage
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
    localStorage.setItem("isSidebarOpen", false); // Save state in localStorage
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsSidebarOpen(true);
        localStorage.setItem("isSidebarOpen", true);
      } else {
        setIsSidebarOpen(false);
        localStorage.setItem("isSidebarOpen", false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  
  return (
    <div>
      <Navbar onToggleSidebar={handleToggleSidebar} />
      <div className="layout-container" style={{ position: "relative" }}>
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          onCloseSidebar={handleCloseSidebar}
        />
         <div
          className={`main-content ${
            isSidebarOpen ? "content-overlay" : ""
          }`}
          style={{
            backgroundColor: "rgb(240, 242, 245)",
            padding: "24px",
          }}
        >
          <div>{children}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CommonLayout;
