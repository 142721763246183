import Cookies from 'js-cookie';
import { makeBearerJsonApiRequest, makeJsonApiRequest } from '../../services/ApiRequest';
import { removeToken } from './AuthServices';
import  {API_BASE_URL } from '../../BaseUrl/baseUrl'

export const loginUser = async (email, password) => {
    const endpoint = `${API_BASE_URL}login-user`;
    const jsonData = { email, password };

    try {
        const response = await makeJsonApiRequest('POST', endpoint, jsonData);
        if (response && response.data && response.data.token ) {
            Cookies.set('token', response.data.token, { expires: 7 });
            Cookies.set('permissions', JSON.stringify(response.data.permissions), { expires: 7 });
            if (response.data.avatar) {
                Cookies.set('avatar', response.data.avatar, { expires: 7 });
              }
        }
        return response;
    } catch (error) {
        throw error;
    }
};


export const forgetPassword = async (email) => {
    const endpoint = `${API_BASE_URL}forget-password`;
    const jsonData = { email };
    try {
        const response = await makeJsonApiRequest('POST', endpoint, jsonData);
        return response;
    } catch (error) {
        throw error.response ? error.response : error;
    }
};


export const logoutUser = () => {
    removeToken();
};


export const resetPassword = async (resetLink, password) => {
    const endpoint = `${API_BASE_URL}reset-password`;
    const jsonData = { resetLink, password };

    const response = await makeJsonApiRequest('POST', endpoint, jsonData);
    if (response && response.success) {
        return { success: true };
    } else {
        return {
            success: false,
            error: response.error || 'Password update failed',
        };
    }
};


export const updatePassword = async (currentPassword, newPassword) => {
    try {
      const endpoint = `${API_BASE_URL}changed-password`;
      const jsonData = { currentPassword, newPassword };
  
      const response = await makeBearerJsonApiRequest('POST', endpoint, jsonData);
      
      if (response && response.success) {
        return response;
      } else {
        throw new Error(response?.message || 'Password update failed.');
      }
    } catch (error) {
      console.error('Error updating password:', error.message || error);
      throw error; 
    }
  };
  

  