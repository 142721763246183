export  const languageOptions = {
    ar: "Arabic",
    de: "German",
    ru: "Russian",
    hi: "Hindi",
    es: "Spanish",
    fr: "French",
    ur: "Urdu",
    tl: "Tagalog",
    ja: "Japanese",
    ko: "Korean",
    tr: "Turkish",
  };

  export const languageOptionsWithFlag = {
    en: { name: "English", flag: "assets/flags/all7.png" },
    ar: { name: "Arabic", flag: "assets/flags/arabic.webp" },
    de: { name: "German", flag: "assets/flags/german.png" },
    ru: { name: "Russian", flag: "assets/flags/russia.jpg" },
    hi: { name: "Hindi", flag: "assets/flags/india.png" },
    es: { name: "Spanish", flag: "assets/flags/spanish.png" },
    fr: { name: "French", flag: "assets/flags/french.webp" },
    ur: { name: "Urdu", flag: "assets/flags/urdu.jpg" },
    tl: { name: "Tagalog", flag: "assets/flags/tapalog.png" },
    ja: { name: "Japanese", flag: "assets/flags/japan2.webp" },
    ko: { name: "Korean", flag: "assets/flags/jkorea.jpg" },
    tr: { name: "Turkish", flag: "assets/flags/turkish.png" },
   
  };

// handleDeleteArticleForm.js
export const handleDeleteArticleForm = (index, formData, setFormData, additionalForms, setAdditionalForms) => {
  const updatedForms = additionalForms.filter((_, i) => i !== index);

  const languageInUse = updatedForms.some((form) => form.language === formData.languages);

  if (!languageInUse) {
    // If no other form is using the same language, reset the state
    setFormData({ ...formData, languages: "" });
  }
  
  setAdditionalForms(updatedForms);
};



export function chunkArray(array, size) {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}
