import React, { useEffect, useState } from "react";
import CommonLayout from "../../layout/commonLayout/CommonLayout";
import { Link } from "react-router-dom";
import { MdHome } from "react-icons/md";
import { FaSearch } from "react-icons/fa";

import { Input, Modal, Button, Image } from "antd";
import {
  deleteBanner,
  fetchBanners,
  fetchCategories,
  fetchCategoryBySubCategory,
  updateBanner,
  uploadBannerImage,
} from "../../services/Apis/ApiServices";
import DeleteModal from "../../Component/DeleteModal";
import { toast } from "react-toastify";
import { languageOptions } from "../../utils/utils";
import { FaTrashAlt, FaEye } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { GrFormPreviousLink ,GrFormNextLink  } from "react-icons/gr";

const AllBanner = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [ currentPage , setCurrentPage ] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editableBanner, setEditableBanner] = useState(null);
  const [subCategory,setSubCategory] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState(null);
  const { TextArea } = Input;
  const [bannerToEdit, setBannerToEdit] = useState({
    title: { en: "", secondary: "" },
    description: { en: "", secondary: "" },
    bannerImage: "",
    linkedCategory: "", // Changed from object to string
    language: { secondary: "" },
    linkedSubCategory:""
  });
  const [category, setCategory] = useState([]);

  const filteredBanner = (banner || []).filter((banner) => {
    const search = searchTerm.toLowerCase();
    return (
      banner.title.en.toLowerCase().includes(search) ||
      banner.title.secondary.toLowerCase().includes(search)
    );
  });
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const getAllBanner = async (page = 1) => {
    try {
      setLoading(true);
      const response = await fetchBanners(page);

      if (response) {
        setBanner(response.banners);
        setTotalPages(response.totalPages);
      } else {
        setBanner([]);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      setBanner([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllBanner(currentPage);
  }, [currentPage]);

  const handleShowMore = (banner) => {
    setSelectedBanner(banner);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedBanner(null);
  };

  const handleDeleteClick = (id) => {
    setSelectedBanner(id);
    setShowDeleteModal(true);
  };
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const handleConfirmDelete = async () => {
    try {
      const response = await deleteBanner(selectedBanner._id);

      if (response?.success) {
        toast.success(response.message);

        getAllBanner();
        setShowDeleteModal(false);
      } else {
        toast.error(response?.message || "Failed to delete banner.");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the banner.");
      console.error("Delete error:", error);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setSelectedBanner(null);
  };
  const handleEditClick = (banner) => {
    if (!banner) {
      console.error("Edit operation failed: banner is null or undefined.");
      return;
    }
  
    setEditableBanner(banner);
    setBannerToEdit(banner);
    setIsEditModalVisible(true);
  };
  

  const getCategories = async () => {
    try {
      const fetchedCategories = await fetchCategories();
      if (fetchedCategories?.length === 0) {
      } else {
        setCategory(fetchedCategories.categories);
      }
    } catch (err) {
      console.error("Error in getCategories:", err);
    }
  };

  const getSubCategory = async(id) =>{
    try{
      const response = await fetchCategoryBySubCategory(id);
      if(response){
        setSubCategory(response);
      }
      else{
        setSubCategory([]);
      }
    }
    catch(error){
      toast.error("Error in getSubCategory:", error);
    }
  }


  useEffect(() => {
    getCategories();
    if(editableBanner && bannerToEdit.linkedCategory){
      getSubCategory(bannerToEdit.linkedCategory._id);
    }
  }, [editableBanner]);

  const handleSaveEdit = async () => {
    try {
      let imagePath = bannerToEdit.bannerImage;

      if (file) {
        try {
          const response = await uploadBannerImage(file);

          if (response) {
            imagePath = response;
          } else {
            console.error("Image upload failed: No path returned from API");
          }
        } catch (error) {
          console.error("Image upload failed:", error);
        }
      }

      // const apiData = {
      //   _id: bannerToEdit?._id,
      //   title: bannerToEdit?.title,
      //   description: bannerToEdit?.description,
      //   language: bannerToEdit?.language,
      //   linkedCategory: bannerToEdit?.linkedCategory?._id,
      //   linkedSubCategory: bannerToEdit?.linkedSubCategory?._id,
      //   bannerImage: imagePath,
      // };

      const apiData = {
        _id: bannerToEdit?._id,
        title: bannerToEdit?.title,
        description: bannerToEdit?.description,
        language: bannerToEdit?.language,
        linkedCategory: bannerToEdit?.linkedCategory?._id || bannerToEdit?.linkedCategory, // Handle both object and string cases
        linkedSubCategory: bannerToEdit?.linkedSubCategory?._id || bannerToEdit?.linkedSubCategory,
        bannerImage: imagePath,
      };


      const response = await updateBanner(apiData);
      if (response) {
        toast.success("Banner updated successfully!");
        getAllBanner();
        setImagePreview(null);
        setIsEditModalVisible(false);
      } else {
        toast.error("Failed to update banner. Please try again.");
      }
    } catch (error) {
      console.error("Error updating category:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const handleCategoryChange = (categoryId) => {
    setBannerToEdit((prev) => ({
      ...prev,
      linkedCategory: { _id: categoryId }, // Ensure linkedCategory is an object
    }));
  
    if (categoryId) {
      getSubCategory(categoryId);
    } else {
      setSubCategory([]);
    }
  };
  

  const handleEditChange = (e) => {
    const { name, value } = e.target;

    setBannerToEdit((prev) => {
      switch (name) {
        case "language":
          return { ...prev, language: { ...prev.language, secondary: value } };
        case "titleEn":
          return { ...prev, title: { ...prev.title, en: value } };
        case "titleSecondary":
          return { ...prev, title: { ...prev.title, secondary: value } };
        case "descriptionEn":
          return { ...prev, description: { ...prev.description, en: value } };
        case "descriptionSecondary":
          return {
            ...prev,
            description: { ...prev.description, secondary: value },
          };
        case "category":
          return { ...prev, linkedCategory: value };
        case "bannerImage":
          return { ...prev, bannerImage: value };
        case "subCategory":
          return { ...prev, linkedSubCategory: value };
        default:
          return prev;
      }
    });
  };

  const handleImageChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const previewUrl = URL.createObjectURL(selectedFile);
      setImagePreview(previewUrl);
      setFile(selectedFile); // Store the file in state
    }
  };


  return (
    <CommonLayout>
      <div className="d-flex align-items-center justify-content-end mb-5">
        {/* <Input
          prefix={<FaSearch />}
          placeholder="Search by title"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            maxWidth: "300px",
            borderRadius: "50px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        /> */}
        <nav aria-label="breadcrumb">
          <ol
            className="breadcrumb justify-content-end"
            style={{
              background: "transparent",
              padding: "0",
              margin: "0",
              fontSize: "14px",
            }}
          >
            <li className="breadcrumb-item">
              <Link>
                <MdHome size={20} color="#4B49AC" />
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link
                style={{
                  textDecoration: "none",
                  color: "#333",
                  fontWeight: "600",
                }}
              >
                All Banner
              </Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="container card rounded shadow-lg p-4">
        <div className="d-flex justify-content-between mb-4">
          <h4 className=" fw-bold middleclass">All banner</h4>
          <Input
            prefix={<FaSearch />}
            placeholder="Search by created by or title"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              maxWidth: "300px",
              borderRadius: "50px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          />
        </div>
        {loading ? (
          <div className="loading-container">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="bgtable">
              <tr>
                <th>Serial No</th>
                <th>Image</th>
                <th>Created By</th>
                <th>Title</th>
                <th>Language</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredBanner.length > 0 ? (
                filteredBanner.map((banner, index) => (
                  <tr key={banner._id}>
                    <td>{index + 1}</td>
                    <td>
                      <Image
                        src={banner.bannerImage}
                        className="img-fluid avatar rounded-circle"
                        style={{
                          width: "40px",
                          height: "40px",
                          objectFit: "cover",
                        }}
                      />
                    </td>
                    <td>{banner?.createdBy?.name || "N/A"}</td>
                    <td>
                      {banner?.title?.en} / {banner?.title?.secondary}
                    </td>
                    <td>
                      {banner?.language?.en} / {banner?.language?.secondary}
                    </td>
                    <td>
                      <div className="d-flex" style={{ gap: "10px" }}>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => handleShowMore(banner)}
                        >
                          <FaEye color="black" size={18} />
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => handleDeleteClick(banner)}
                        >
                          <FaTrashAlt color="red" size={18} />
                        </button>
                        <button
                          type="button"
                          className="btn btn-warning btn-sm"
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => handleEditClick(banner)}
                        >
                          <MdModeEdit color="4B49AC" size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="6"
                    style={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    No Banners Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          </div>

        )}
         <div className="d-flex justify-content-center align-items-center gap-3 mt-5">
          <button
            className="btn btn-outline-primary prevbtn"
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
             <GrFormPreviousLink size={20} />
          </button>
          <span className="fw-bold">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className="btn btn-outline-primary nextbtn"
          >
              <GrFormNextLink size={20} />
          </button>
        </div>

      </div>
      <div>
        <DeleteModal
          isVisible={showDeleteModal}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      </div>

      {selectedBanner && (
        <Modal
          title={
            <h4
              style={{
                fontWeight: "bold",
                margin: "0",
                textAlign: "center",
                marginBottom: "35px",
              }}
            >
              {selectedBanner.title.en} / {selectedBanner.title.secondary}
            </h4>
          }
          visible={isModalVisible}
          onCancel={handleModalClose}
          footer={[
            <Button key="close" onClick={handleModalClose}>
              Close
            </Button>,
          ]}
          style={{ borderRadius: "15px" }}
        >
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ flex: 1 }}>
              <h5 style={{ fontWeight: "bold" }}> Description</h5>
              <p style={{ fontSize: "14px", color: "#555" }}>
                {selectedBanner.description.en || "No description available"}
              </p>
            </div>

            <div style={{ flex: 1 }}>
              <h5 style={{ fontWeight: "bold", marginBottom: "23px" }}> </h5>
              <p style={{ fontSize: "14px", color: "#555" }}>
                {selectedBanner.description.secondary ||
                  "No description available"}
              </p>
            </div>
          </div>
        </Modal>
      )}

      {editableBanner && (
        <Modal
          title={
            <h4
              style={{
                fontWeight: "bold",
                margin: "0",
                textAlign: "center",
                marginBottom: "20px",
                color: "#4B49AC",
              }}
            >
              {editableBanner.title.en} / {editableBanner.title.secondary}
            </h4>
          }
          visible={isEditModalVisible}
          onCancel={() => setIsEditModalVisible(false)}
          footer={null}
          style={{ borderRadius: "15px" }}
        >
          <form>
            <p className="text-center m-0 p-0 mb-3 fw-bold"></p>
            <div className="mb-3">
              <label htmlFor="bannerLanguage" className="form-label fw-bold">
                Language
              </label>
              <select
                className="form-select"
                id="bannerLanguage"
                name="language"
                value={bannerToEdit.language.secondary}
                onChange={handleEditChange}
              >
                {Object.entries(languageOptions).map(([code, name]) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="category" className="form-label fw-bold">
                Category
              </label>
              <select
                id="category"
                className="form-select"
                name="category"
                value={bannerToEdit?.linkedCategory._id || ""}
                onChange={ (e)=>{
                  handleEditChange(e);
                  handleCategoryChange(e.target.value);
                }    
            }
              >
                <option value="" disabled>
                  Select category
                </option>
                {category.length > 0 ? (
                  category.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {typeof cat.name === "object" ? cat.name.en : cat.name}
                    </option>
                  ))
                ) : (
                  <option disabled>No category available</option>
                )}
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="category" className="form-label fw-bold">
                Sub Category
              </label>
              <select
                id="subCategory"
                className="form-select"
                name="subCategory"
                value={bannerToEdit.linkedSubCategory || ""}
                onChange={handleEditChange}
              >
                <option value="" disabled>
                  Select category
                </option>
                {subCategory.length > 0 ? (
                  subCategory.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {typeof cat.name === "object" ? cat.name.en : cat.name}
                    </option>
                  ))
                ) : (
                  <option disabled>No sub category available</option>
                )}
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label fw-bold">Title (English)</label>
              <input
                type="text"
                className="form-control formclass"
                name="titleEn"
                value={bannerToEdit.title.en}
                onChange={handleEditChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label fw-bold">Title (Secondary)</label>
              <input
                type="text"
                className="form-control formclass"
                name="titleSecondary"
                value={bannerToEdit.title.secondary}
                onChange={handleEditChange}
              />
            </div>

            <div className="mb-2">
              <label className="form-label fw-bold">
                Description (English)
              </label>
              <TextArea
                className="form-control formclass"
                name="descriptionEn"
                value={bannerToEdit.description.en}
                onChange={handleEditChange}
              />
            </div>

            <div className="mb-3">
              <label className="form-label fw-bold">
                Description (Secondary)
              </label>
              <TextArea
                className="form-control formclass"
                name="descriptionSecondary"
                value={bannerToEdit.description.secondary}
                onChange={handleEditChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="bannerImageUpload" className="form-label fw-bold">
                Upload Banner Image
              </label>
              <input
                type="file"
                className="form-control formclass"
                id="bannerImageUpload"
                onChange={handleImageChange}
              />
            </div>

            <div className="mb-3">
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Banner Preview"
                  className="img-fluid"
                  style={{ maxWidth: "100%", height: "100px" }}
                />
              ) : (
                <p></p>
              )}
            </div>

            <div
              className="mb-3"
              style={{ border: "1px solid #ccc", padding: "10px" }}
            >
              {editableBanner.bannerImage ? (
                <img
                  src={editableBanner.bannerImage}
                  alt="Banner"
                  className="img-fluid"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              ) : (
                <p>No image available</p>
              )}
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setIsEditModalVisible(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary ms-2"
                onClick={handleSaveEdit}
              >
                Save Changes
              </button>
            </div>
          </form>
        </Modal>
      )}
    </CommonLayout>
  );
};

export default AllBanner;
