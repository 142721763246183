import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const PublicRoute  = ({ children }) => {
  const { isAuthenticated } = useAuth(); 
  return isAuthenticated() ? <Navigate to="/Dashboard"  /> : children;
};

export default PublicRoute ;
