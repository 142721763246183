import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import 'react-accessible-accordion/dist/fancy-example.css';
import { AvatarProvider } from './components/avatarContext/AvatarContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 <>
    <BrowserRouter>
    <AvatarProvider>
      <App />
    </AvatarProvider>
    </BrowserRouter>
    <ToastContainer
      position="top-center"
      autoClose={1000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />

</>

);
reportWebVitals();
