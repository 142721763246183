import React from 'react'
import { FaCheck } from "react-icons/fa6";
const CopyClipBoardModal = ({ imagePath, isCopied, handleCopy, closeModal }) => {
  return (
    <div
              className="modal show d-block"
              tabIndex="-1"
              role="dialog"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">File Uploaded Successfully</h5>
                    <button
                      type="button"
                      className="btn-close"
                    onClick={closeModal}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body p-3">
                    <p className="mb-3">
                      <strong>Note:</strong> Copy the link and paste it into your{" "}
                      <strong>article</strong> where you need it.
                    </p>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control formclass"
                        value={imagePath}
                        readOnly
                      />
                      <button
                        type="button"
                        className={`btn ${isCopied ? "btn-success" : "btn-primary"}`}
                        onClick={handleCopy}
                      >
                        {isCopied ? (
                          <>
                            <FaCheck  size={20}/>
                          </>
                        ) : (
                          "Copy"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  )
}

export default CopyClipBoardModal
