import React, { useState } from 'react';
import CommonLayout from '../../layout/commonLayout/CommonLayout';
import { useLocation } from 'react-router-dom';

function CreateSecondCategory() {
  const location = useLocation();
  const { language: initialLanguage, imageUrl: initialImage } = location.state || {}; // Get initial language and image URL from location state

  const [formData, setFormData] = useState({
    name: '',
    language: initialLanguage || '', // Set the language from location state
    description: '',
    imageUrl: initialImage || '', // Set the image URL from location state
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: '' });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    // Validate required fields
    if (!formData.name) newErrors.name = 'Category name is required';
    if (!formData.language) newErrors.language = 'Language is required';
    if (!formData.description) newErrors.description = 'Description is required';

    // If there are errors, update the error state
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // No errors, proceed with form submission
      console.log('Form submitted:', formData);
      // Reset the form or redirect as needed
    }
  };

  return (
    <CommonLayout>
      <div className='container mt-2'>
        <h2 className="text-center mb-4">Create a Language-Based Category</h2>
        {/* Display Image Preview */}
        {formData.imageUrl && (
          <div className="text-center mb-4">
            <img
              src={formData.imageUrl}
              alt="Category Preview"
              style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '5px' }}
            />
          </div>
        )}
        
        <form onSubmit={handleSubmit}>
          {/* Language Field */}
          <div className="mb-3">
            <label htmlFor="language" className="form-label">Selected Language</label>
            <select
              id="language"
              className="form-select text-dark"
              value={formData.language}
              onChange={handleChange}
              disabled // Disable the select input to make it non-editable
            >
              <option value="">Select...</option>
              <option value="en">English</option>
              <option value="ar">Arabic</option>
              <option value="de">German</option>
              <option value="ru">Russian</option>
              <option value="hi">Hindi</option>
              <option value="es">Spanish</option>
              <option value="fr">French</option>
              <option value="ur">Urdu</option>
              <option value="tl">Tagalog</option>
              <option value="ja">Japanese</option>
              <option value="ko">Korean</option>
              <option value="tr">Turkish</option>
            </select>
            {errors.language && <div className="text-danger">{errors.language}</div>}
          </div>

          {/* Category Name Field */}
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Category Name</label>
            <input
              type="text"
              id="name"
              className="form-control"
              placeholder="Enter category name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <div className="text-danger">{errors.name}</div>}
          </div>

          {/* Description Field */}
          <div className="mb-3">
            <label htmlFor="description" className="form-label">Description</label>
            <textarea
              id="description"
              className="form-control"
              placeholder="Enter category description"
              rows="4"
              value={formData.description}
              onChange={handleChange}
            />
            {errors.description && <div className="text-danger">{errors.description}</div>}
          </div>

          {/* Submit Button */}
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">Finish</button>
          </div>
        </form>
      </div>
    </CommonLayout>
  );
}

export default CreateSecondCategory;
