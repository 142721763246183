import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { forgetPassword } from '../../components/Auth/AuthApi';
import { useNavigate } from 'react-router-dom';

function ForgetPassword() {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [loading , setLoading] = useState(false);
const navigate = useNavigate();

    const validateForm = () => {
        const newErrors = {};
        if (!email) newErrors.email = 'Email is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            if (!email) {
                setErrors({ email: 'Email is required.' });
                return;
              } 
              setErrors({});
              setLoading(true);
              try {
                const result = await forgetPassword(email);
                if (result.status === 200 && result.success) {
                  toast.success(result.message);
                  setEmail('');
                  navigate('/',);
                } else {
                  setErrors(result.message || 'Failed to send password reset link.');
                  toast.error(result.message || 'Failed to send password reset link.');
                }
              } catch (error) {
                setErrors('Error sending password reset link. Please try again.');
                console.error('Error:', error);
                toast.error('Error:', error);
              } finally {
                setLoading(false);
              }

        }
    };

    return (
        <div className="">
            <div className="container-fluid p-0">
                <div className="content-wrapper d-flex align-items-center auth px-0" style={{
                    backgroundImage: "url('https://img.freepik.com/free-photo/flowers-notebook-near-laptop_23-2147759307.jpg?semt=ais_hybrid')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100vh',
                }}>
                    <div className="row widthclass mx-0 mx-auto">
                    <div className="col-lg-4 col-md-6 col-sm-8 mx-auto card shadow-sm" style={{
                            borderRadius: '10px'
                        }}>
                            <div className="text-left rounded  px-sm-4">
                                <div className="d-flex align-items-center justify-content-center">
                                    <img src="https://img.freepik.com/premium-vector/blog-word-drawn-lettering-typographic-element_205544-12246.jpg?semt=ais_hybrid" alt="logo" style={{ height: "90px" }} />
                                </div>
                                <h5 className='text-center mb-3'>Forgot Your Password?</h5>
                               
                            
                                <form className="" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className={`form-control formclass ${errors.email ? 'is-invalid' : ''}`}
                                            placeholder="Enter email to reset the password."
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                    </div>
                                    <div className="mt-4 d-grid gap-2 mb-4">

                                        <button type="submit" className="btn btn-primary m-0" disabled={loading}>
                                            {loading ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ width: '1rem', height: '1rem' }}></span>) :'Send Reset Link'}
                                        </button>
                                        
                                    </div>
                                    <div className="text-center mt-4 font-weight-light mb-4">
                                        Remembered your password? <Link to='/' className="text-primary">Login</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgetPassword;
