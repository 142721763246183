import { makeBearerJsonApiRequest, makeJsonApiRequest } from "../ApiRequest";
import { API_BASE_URL } from "../../BaseUrl/baseUrl";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export const AddContentManager = async (formData) => {
  const endpoint = `${API_BASE_URL}create-content-manager`;
  // const jsonData = { formData };

  const response = await makeJsonApiRequest("POST", endpoint, formData);

  if (response.error && response.error.includes("invalid token")) {
    toast.error("Invalid token. Please log in again.");
  }

  return response;
};

export const getAllManagers = async (page) => {
  const endpoint = `${API_BASE_URL}show-all-managers?page=${page}`;

  const response = await makeBearerJsonApiRequest("GET", endpoint, null);

  if (response.error && response.error.includes("invalid token")) {
    toast.error("Invalid token. Please log in again.");
  }

  return response;
};

export const updateManagerPermissions = async (_id, permissions) => {
  const endpoint = `${API_BASE_URL}update-permisson`;
  const jsonData = {
    _id,
    permissions,
  };

  const response = await makeBearerJsonApiRequest("PATCH", endpoint, jsonData);

  if (response.error && response.error.includes("invalid token")) {
    toast.error("Invalid token. Please log in again.");
  }

  return response;
};

export const deleteManager = async (id) => {
  const endpoint = `${API_BASE_URL}delete-manager`;
  const jsonData = { _id: id };

  const response = await makeBearerJsonApiRequest("DELETE", endpoint, jsonData);


  if (response.error && response.error.includes("invalid token")) {
    toast.error("Invalid token. Please log in again.");
  }

  return response;
};

export const getProfile = async () => {
  const endpoint = `${API_BASE_URL}show-profile`;

  const response = await makeBearerJsonApiRequest("GET", endpoint, null);

  if (response.error && response.error.includes("invalid token")) {
    toast.error("Invalid token. Please log in again.");
  }

  return response;
};

// Function to upload profile image
export const uploadProfileImage = async (file) => {
  const formData = new FormData();
  formData.append("image", file);
  const token = Cookies.get("token");
  try {
    const { data } = await axios.post(
      `${API_BASE_URL}upload-profile-image`,
      formData,
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (data.success && data.status === 200) {
      return data.data.path;
    } else {
      throw new Error("Image upload failed.");
    }
  } catch (error) {
    toast.error("Error uploading image:", error);
    throw error;
  }
};

// Function to update profile data
export const updateProfile = async (profileData) => {
  try {
    const response = await makeJsonApiRequest(
      "PATCH",
      `${API_BASE_URL}update-profile`,
      profileData
    );

    if (response.success && response.status === 200) {
  // toast.success("Profile updated successfully!");
      return response.data;
    } else {
      throw new Error("Profile update failed.");
    }
  } catch (error) {
    toast.error("Error updating profile:", error);
    throw error;
  }
};

export const createTag = async (jsonData) => {
  const endpoint = `${API_BASE_URL}create-tag`;

  try {
    const response = await makeJsonApiRequest("POST", endpoint, jsonData);

    if (response.success && response.status === 201) {
      return response.data;
    } else {
      toast.error(
        "Error in response:",
        response.message || "Unexpected error"
      );
      return null;
    }
  } catch (error) {
    toast.error("An error occurred while creating the tag:", error.message);
    return null;
  }
};



export const fetchTag = async (page) => {
  const endpoint = `${API_BASE_URL}show-all-tags?page=${page}`;

  try {
    const response = await makeJsonApiRequest("GET", endpoint);

    if (response.success && response.status === 200) {
      return response.data;
    } else {
      toast.error(
   
        response.message || "Unexpected error"
      );
      return null;
    }
  } catch (error) {
    toast.error("An error occurred while creating the tag:", error.message);
    return null;
  }
};

export const fetchCategories = async (page) => {
  const endpoint = `${API_BASE_URL}show-all-categories?page=${page}`;
  try {
    const response = await makeJsonApiRequest("GET", endpoint, {});

    if (response.success) {
      return response.data;
    } else {
      throw new Error("Failed to fetch categories: " + response.data.message);
    }
  } catch (error) {
    toast.error("Error fetching categories:", error);
   
    return [];
  }
};

export const fetchTagsBySearch = async (searchTerm) => {
  const endpoint = `${API_BASE_URL}search-tag?query=${searchTerm}`;

  try {
    const response = await makeJsonApiRequest("GET", endpoint);
   
    if (response.success && response.status === 200) {
      return response.data;
    } else {
      toast.error("Error in response:", response.message || "Unexpected error");
      return [];
    }
  } catch (error) {
    toast.error("Error fetching tags:", error);
    return [];
  }
};

export const createArticle = async (jsonData) => {
  const endpoint = `${API_BASE_URL}create-article`;

  try {
    const response = await makeJsonApiRequest("POST", endpoint, jsonData);
    if (response.success && response.status === 201) {
      return response.data;
    } else {
      toast.warning(
        "Error in response:",
        response.message || "Unexpected error"
      );
      return null;
    }
  } catch (error) {
    toast.error("An error occurred while creating the Article:", error.message);
    return null;
  }
};

export const deleteTag = async (id) => {
  const endpoint = `${API_BASE_URL}delete-tag`;
  const jsonData = {"_id":id}
  try {
    const response = await makeJsonApiRequest("DELETE", endpoint, jsonData);
    if (response.success && response.status === 200) {
      return response;
    }
    else{
      toast.warning(response.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};


 export const uploadImageHandler = async (blobInfo, success, failure) => {
  const formData = new FormData();
  formData.append('image', blobInfo.blob(), blobInfo.filename());

  try {
    const response = await axios.post(`${API_BASE_URL}upload-article-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': ` ${Cookies.get('token')}`,
      },
    });

    if (response.data.success) {
      const imageUrl = response.data.data.path;
      success(imageUrl); 
      toast.success(response.message); 
    } else {
      toast.error('Image upload failed');
    }
  } catch (error) {
    toast.error('Image upload failed');
  }
};
export const updateTag = async (data) => {
  const endpoint = `${API_BASE_URL}update-tag`;
  try {
    const response = await makeJsonApiRequest("PATCH", endpoint, data);
    if (response.success && response.status === 200) {
      return response;
    }
    else{
      toast.warning(response.message);
    }
  }
  catch(error){
    toast.error(error.message);
  }

}



export const fetchBannersByCategory = async (categoryId) => {
  const endpoint = `${API_BASE_URL}get-banners-by-category?category=${categoryId}`;
  try {
    const response = await makeJsonApiRequest("GET", endpoint);
    if (response.success) {
      return response.data;
    } else {
      // toast.error(response.message);
    }
  } catch (error) {
    console.error("Error fetching banners:", error);
    return [];
  }
};


export const fetchAllArticles = async (code , page) => {
  const endpoint = `${API_BASE_URL}show-all-articles?page=${page}&langCode=${code}`;
  try {
    const response = await makeJsonApiRequest("GET", endpoint);
    if (response.success) {
      return response.data;
    }
    else if(response.status === 404){
      return [];
    }
    else {
      throw new Error("Failed to fetch articles: " + response.data.message);
    }
  } catch (error) {
    toast.error("No articles found for this language:", error);
   
    return [];
  }
};

export const uploadCategoryImage = async (file) => {
  const formData = new FormData();
  formData.append("image", file);
  const token = Cookies.get("token");
  try {
    const { data } = await axios.post(
      `${API_BASE_URL}upload-category-image`,
      formData,
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (data.success && data.status === 200) {
      return data.data.path;
    } else {
      throw new Error(" Category Image upload failed.");
    }
  } catch (error) {
    toast.error("Error uploading Category image:", error);
    throw error;
  }
};

export const createCategories = async (jsonData) => {
  const endpoint = `${API_BASE_URL}create-category`;

  try {
    const response = await makeJsonApiRequest("POST", endpoint, jsonData);

    if (response.success && response.status === 201) {
      return response.data;
    } else {
      toast.error(
        response.message || "Unexpected error"
      );
      return null;
    }
  } catch (error) {
    toast.error("An error occurred while creating the tag:", error.message);
    return null;
  }
};

export const deleteCategory = async (id) => {
  const endpoint = `${API_BASE_URL}delete-category`;
  const jsonData = {"_id":id}
  try {
    const response = await makeJsonApiRequest("DELETE", endpoint, jsonData);
    if (response.success && response.status === 200) {
      return response;
    }
    else{
      toast.warning(response.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateCategory = async (data) => {

  const endpoint = `${API_BASE_URL}update-category`;
  try {
    const response = await makeJsonApiRequest("PATCH", endpoint, data);
    if (response.success && response.status === 200) {
      return response;
    }
    else{
      toast.warning(response.message);
    }
  }
  catch(error){
    toast.error(error.message);
  }

}

export const fetchBanners = async (page) => {
  const endpoint = `${API_BASE_URL}show-all-banners?page=${page}`;
  try {
    const response = await makeJsonApiRequest("GET", endpoint, {});
    if (response.success) {
      return response.data;
    } else {
      throw new Error("Failed to fetch banners: " + response.data.message);
    }
  } catch (error) {
    toast.error("Error fetching banners:", error);
   
    return [];
  }
};

export const deleteBanner = async (id) => {
  const endpoint = `${API_BASE_URL}delete-banner`;
  const jsonData = {"_id":id}
  try {
    const response = await makeJsonApiRequest("DELETE", endpoint, jsonData);
    if (response.success && response.status === 200) {
      return response;
    }
    else{
      toast.warning(response.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
}

export const deleteArticle = async (id , langCode) =>{
  try {
    const endpoint = `${API_BASE_URL}delete-article`;
    const jsonData = {"_id":id , 'langCode':langCode}

    const response = await makeJsonApiRequest("DELETE", endpoint, jsonData);
    if (response.success && response.status === 200) {
      return response;
    }
    else{
      toast.warning(response.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
}


export const uploadBannerImage = async (file) => {
  const formData = new FormData();
  formData.append("image", file);
  const token = Cookies.get("token");
  try {
    const { data } = await axios.post(
      `${API_BASE_URL}upload-banner-image`,
      formData,
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
   
    if (data.success && data.status === 200) {
    //  toast.success(data.message);
      return data.data.path;
    } else {
      throw new Error(" banner Image upload failed.");
    }
  } catch (error) {
    toast.error("Error uploading banner image:", error);
    throw error;
  }
};

export const createBanner = async (jsonData) => {
  const endpoint = `${API_BASE_URL}create-banner`;

  try {
    const response = await makeJsonApiRequest("POST", endpoint, jsonData);

    if (response.success && response.status === 201) {
      return response.data;
    } else {
      toast.error(
        response.message || "Unexpected error"
      );
      return null;
    }
  } catch (error) {
    toast.error("An error occurred while creating the banner:", error.message);
    return null;
  }
};



export const updateBanner = async (jsonData) => {
  const endpoint = `${API_BASE_URL}update-banner`;

  try {
    const response = await makeJsonApiRequest("PATCH", endpoint, jsonData);
    if (response.success && response.status === 200) {
      return response.data;
    } else {
      toast.error(
        response.message || "Unexpected error"
      );
      return null;
    }
  } catch (error) {
    toast.error("An error occurred while creating the banner:", error.message);
    return null;
  }
};



export const getArticleById = async (id , code) => {
  const endpoint = `${API_BASE_URL}show-article-by-id?_id=${id}&langCode=${code}`;

  try {
    const response = await makeJsonApiRequest("GET", endpoint, );

    if (response.success) {
      return response.data;
    } else {
      toast.error(
       
        response.message || "Unexpected error"
      );
      return null;
    }
  } catch (error) {
    toast.error("An error occurred while creating the banner:", error.message);
    return null;
  }
};



export const updateArticle = async (jsonData) => {
  const endpoint = `${API_BASE_URL}update-article`;

  try {
    const response = await makeJsonApiRequest("PATCH", endpoint, jsonData);

    if (response.success && response.status === 200) {
      toast.success(response.message);
      return response.data;
    } else {
      toast.error(

        response.message || "Unexpected error"
      );
      return null;
    }
  } catch (error) {
    toast.error("An error occurred while creating the banner:", error.message);
    return null;
  }
};

export const uploadTagImage = async (file) => {
  const formData = new FormData();
  formData.append("image", file);
  const token = Cookies.get("token");
  try {
    const { data } = await axios.post(
      `${API_BASE_URL}upload-tag-picture`,
      formData,
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (data.success && data.status === 200) {
      return data.data.path;
    } else {
      throw new Error(" tag Image upload failed.");
    }
  } catch (error) {
    toast.error("Error uploading tag image:", error);
    throw error;
  }
};


export const createSubCategories = async (jsonData) => {
  const endpoint = `${API_BASE_URL}create-sub-category`;

  try {
    const response = await makeJsonApiRequest("POST", endpoint, jsonData);

    if (response.success && response.status === 201) {
      return response.data;
    } else {
      toast.error(
        response.message || "Unexpected error"
      );
      return null;
    }
  } catch (error) {
    toast.error("An error occurred while creating the sub category:", error.message);
    return null;
  }
};


export const fetchSubCategories = async (page) => {
  const endpoint = `${API_BASE_URL}show-all-sub-categories?page=${page}`;
  try {
    const response = await makeJsonApiRequest("GET", endpoint, {});

    if (response.success) {
      return response.data;
    } else {
      throw new Error("Failed to fetch sub categories: " + response.data.message);
    }
  } catch (error) {
    toast.error("Error fetching sub categories:", error);
   
    return [];
  }
};

export const deleteSubCategory = async (id) => {
  const endpoint = `${API_BASE_URL}delete-sub-category`;
  const jsonData = {"_id":id}
  try {
    const response = await makeJsonApiRequest("DELETE", endpoint, jsonData);
    if (response.success && response.status === 200) {
      return response;
    }
    else{
      toast.warning(response.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};


export const updateSubCategory = async (data) => {

  const endpoint = `${API_BASE_URL}update-sub-category`;
  try {
    const response = await makeJsonApiRequest("PATCH", endpoint, data);
    if (response.success && response.status === 200) {
      return response;
    }
    else{
      toast.warning(response.message);
    }
  }
  catch(error){
    toast.error(error.message);
  }

}


export const fetchCategoryBySubCategory = async (categoryId) => {
  const endpoint = `${API_BASE_URL}get-sub-categories-by-category?category=${categoryId}`;
  try {
    const response = await makeJsonApiRequest("GET", endpoint);
    if (response.success) {
      return response.data;
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    console.error("Error fetching sub categories:", error);
    return [];
  }
};


export const fetchAllArticlesforNewArticle = async () => {
  const endpoint = `${API_BASE_URL}show-all-articles?langCode=en`;
  try {
    const response = await makeJsonApiRequest("GET", endpoint);
  
    if (response.success) {
      return response.data;
    }
    else if(response.status === 404){
      return [];
    }
    else {
      throw new Error("Failed to fetch articles: " + response.data.message);
    }
  } catch (error) {
    toast.error("Error fetching articles:", error);
   
    return [];
  }
};


export const createEnglishArticle = async (jsonData) => {
  const endpoint = `${API_BASE_URL}create-english-article`;

  try {
    const response = await makeJsonApiRequest("POST", endpoint, jsonData);
    if (response.success && response.status === 201) {
      return response.data;
    } else {
      toast.warning(
        "Error in response:",
        response.message || "Unexpected error"
      );
      return null;
    }
  } catch (error) {
    toast.error("An error occurred while creating the Article:", error.message);
    return null;
  }
};


export const checkArticleAvalability = async (jsonData) => {
  const endpoint = `${API_BASE_URL}check-article-availability`;

  try {
    const response = await makeJsonApiRequest("POST", endpoint, jsonData);
    if (response.success && response.status
      === 200 ) {
      return response;
    }  else if(response.status === 404){
       return;
    }
  } catch (error) {
    toast.error("An error occurred while creating the Article:", error.message);
    return null;
  }
};



export const fetchArticleBySearch = async (searchTerm,code) => {
  const endpoint = `${API_BASE_URL}search-article?query=${searchTerm}&langCode=${code}`;

  try {
    const response = await makeJsonApiRequest("GET", endpoint);
   
    if (response.success && response.status === 200) {
      return response.data;
    } else {
      toast.error("Error in response:", response.message || "Unexpected error");
      return [];
    }
  } catch (error) {
    toast.error("Error fetching tags:", error);
    return [];
  }
};


export const uploadNewArticleImage = async (file) => {
  const formData = new FormData();
  formData.append("image", file);
  const token = Cookies.get("token");
  try {
    const { data } = await axios.post(
      `${API_BASE_URL}upload-article-image`,
      formData,
      {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (data.success && data.status === 200) {
      return data.data.path;
    } else {
      throw new Error(" Article Image upload failed.");
    }
  } catch (error) {
    toast.error("Error uploading Article image:", error);
    throw error;
  }
};