import React, { useState, useEffect, useRef, useMemo } from "react";
import CommonLayout from "../../layout/commonLayout/CommonLayout";
import {
  createEnglishArticle,
  fetchAllArticlesforNewArticle,
  fetchArticleBySearch,
  fetchBannersByCategory,
  fetchCategoryBySubCategory,
  fetchTagsBySearch,
  uploadNewArticleImage,
} from "../../services/Apis/ApiServices";
import { toast } from "react-toastify";
import { Button, Input } from "antd";
import { languageOptions } from "../../utils/utils";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { CiSaveDown2 } from "react-icons/ci";
import { fetchCategories } from "../../services/Apis/ApiServices";
import { FaSearch, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom"
import FroalaEditorEn from "../../components/Editor/FroalaEditorEn";
import JoditEditor from 'jodit-react';
import CopyClipBoardModal from "../../components/models/CopyClipBoardModal";
function EnArticleForm() {
  const { TextArea } = Input;
  const [categories, setCategories] = useState([]);
  const [article, setArticle] = useState([]);
  const [banner, setbanner] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [subCatgory, setSubCategory] = useState([]);
  const [tags, setTags] = useState([]);
  const [relatedArticleSearch, setRelatedArticleSearch] = useState("");
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [productLinks, setProductLinks] = useState([""]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [imagePath, setImagePath] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageloading, setImageLoading] = useState(false);
  const navigate = useNavigate()
  const editor = useRef(null)
  const [errors, setErrors] = useState({
    // language: "",
    titleEn: "",
    descriptionEn: "",
    contentEn: '',
    category: "",
    banner: "",
    tag: "",
    seowords: "",
    seodescription: "",
    productlinkss: "",
  });

  const [formData, setFormData] = useState({
    titleEn: "",

    descriptionEn: "",
    // languages: "",
    seowords: "",

    seodescription: "",

    bioHtml: "",
    banner: "",
  });

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setImageLoading(true)
        const response = await uploadNewArticleImage(file);

        if (response) {
          setImagePath(response);
          setIsModalOpen(true);
          setIsCopied(false);
        } else {
          toast.error("Image upload failed. Please try again.");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error("An error occurred while uploading the image.");
      } finally {
        setImageLoading(false)
      }
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(imagePath).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsModalOpen(false);
        setIsCopied(false);
      }, 300)
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditorChange = (content, editor) => {
    setFormData({
      ...formData,
      bioHtml: content,
    });
  };

  const fetchArticle = async (code) => {
    if (relatedArticleSearch?.length > 0) {
      const result = await fetchArticleBySearch(relatedArticleSearch, code);
      setRelatedArticles(result);
    }

    else {
      setRelatedArticleSearch([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors({
      // language: "",
      titleEn: "",
      titleSecondary: "",
    });

    let isValid = true;
    // if (!formData.languages) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     language: "Language is required",
    //   }));
    //   isValid = false;
    // }

    if (!formData.titleEn) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        titleEn: "Title (English) is required",
      }));
      isValid = false;
    }

    if (!formData.descriptionEn) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        descriptionEn: "Description (English) is required",
      }));
      isValid = false;
    }

    if (!formData.bioHtml) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        contentEn: "Content (English) is required",
      }));
      isValid = false;
    }


    if (!formData.category) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        category: "category is required",
      }));
      isValid = false;
    }

    if (!formData.banner) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        banner: "banner is required",
      }));
      isValid = false;
    }

    if (!tags) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        tag: "tag is required",
      }));
      isValid = false;
    }

    if (!formData.seowords) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        seowords: "seo words is required",
      }));
      isValid = false;
    }


    if (!formData.seodescription) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        seodescription: "seo Description is required",
      }));
      isValid = false;
    }


    if (!productLinks) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        productlinkss: "This field is required",
      }));
      isValid = false;
    }
    if (!isValid) return;

    const apiData = {
      title: formData.titleEn,

      shortDescription: formData.descriptionEn,
      content: formData.bioHtml,
      category: formData.category || "",
      subCategory: formData.subCatgory || "",
      relatedArticles: selectedArticles,
      tags: selectedTags,
      seo: {
        metaKeywords: formData.seowords,
        metaDescription: formData.seodescription,
      },
      amazonAffiliateLinks: productLinks,
      banner: formData.banner || "",
    };
    try {
      const response = await createEnglishArticle(apiData);
      if (response) {
        toast.success(response.message || "Article created successfully");
        navigate('/Dashboard');
        setFormData({
          titleEn: "",

          descriptionEn: "",

          category: "",
          seowords: "",

          seodescription: "",

          bioHtml: "",

          banner: "",
        });
        setTags([]);
        setProductLinks([""]);
        setArticle([]);
      } else {
        toast.error(
          response?.message || "An error occurred while creating the Article"
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("There was an error submitting the form.");
    }
  };

  const getCategories = async () => {
    try {
      const fetchedCategories = await fetchCategories();
      if (fetchedCategories?.length === 0) {
      } else {
        setCategories(fetchedCategories.categories);
      }
    } catch (err) {
      console.error("Error in getCategories:", err);
    }
  };

  const getArticle = async () => {
    try {
      const fetchedArticle = await fetchAllArticlesforNewArticle();
      if (fetchedArticle?.length === 0) {
      } else {
        setArticle(fetchedArticle.articles);
      }
    } catch (err) {
      console.error("Error in get article:", err);
    }
  };

  //   const handleCategoryChange = async (categoryId ) => {
  //     // console.log(categoryId, subCategoryId , 'hhhhhhhhhhhhhhhhhhhhhhh');
  //     setFormData({ ...formData, category: categoryId});
  //     if (categoryId ) {
  //         try {
  //             const fetchedSubCategory = await fetchCategoryBySubCategory(categoryId);
  //             console.log(fetchedSubCategory[0]._id, 'hhhhhhhhhhhhhhhhhhhhhhh');
  //             setSubCategory(fetchedSubCategory);

  //         } catch (err) {
  //             console.error("Error fetching data:", err);
  //         }
  //     }
  //     if(categoryId && formData.subCatgory){
  //         try {
  //             const fetchedBanner = await fetchBannersByCategory(categoryId,formData.subCatgory);
  //             setbanner(fetchedBanner);
  //         } catch (err) {
  //             console.error("Error fetching data:", err);
  //         }
  //     } 
  //     else {
  //         // Clear subcategories and banners if no category is selected
  //         setSubCategory([]);
  //         setbanner([]);
  //     }
  // };

  // console.log(subCatgory,"aaaaaaaaaaaaaaaaaaa");

  const handleCategoryChange = async (categoryId) => {
    setFormData((prevFormData) => ({ ...prevFormData, category: categoryId }));
    setbanner([]);
    if (categoryId) {
      try {
        const fetchedSubCategory = await fetchCategoryBySubCategory(categoryId);
        if (fetchedSubCategory.length > 0) {
          setSubCategory(fetchedSubCategory);
        } 

        if(categoryId){
          const fetchedBanner = await fetchBannersByCategory(categoryId);
          if (fetchedBanner.length > 0) {
            setbanner(fetchedBanner);
          }
        }
      }
       catch (err) {
        console.error("Error fetching data:", err);
      }
    }
    else {
      setSubCategory([]);
      setbanner([]);
    }
  };

  const handleSubCategoryChange = async (subCategoryId) => {
    setFormData((prevFormData) => ({ ...prevFormData, subCatgory: subCategoryId }));
  };

  const handleArticleSelect = (e) => {
    const selectedId = e.target.value;

    // Find the selected article
    const selectedArticle = article.find((article) => article._id === selectedId);

    if (selectedArticle && !selectedArticles.some((a) => a._id === selectedId)) {
      setSelectedArticles([...selectedArticles, selectedArticle]);
    }
  };

  const handleRemoveArticle = (id) => {
    setSelectedArticles(selectedArticles.filter((article) => article._id !== id));
  };


  const fetchTags = async () => {
    if (searchQuery?.length > 0) {
      const result = await fetchTagsBySearch(searchQuery);
      setTags(result);
    } else {
      setTags([]);
    }
  };

  const handleSearchChange = (event) => {
    const { name, value } = event.target;

    if (name == "tag") {
      setSearchQuery(value);
      setDebounceTimeout(setTimeout(fetchTags, 300));
    }
    else if (name == "relatedArticle") {
      setRelatedArticleSearch(value);

      setDebounceTimeout(setTimeout(() => {
        fetchArticle('en')
      }, 300));
    }

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
  };

  const handleTagSelect = (tag) => {
    setSelectedTags((prev) => [...prev, tag]);
    setSearchQuery("");
    setTags([]);
  };

  const handleRelatedArctileSelect = (article) => {
    setSelectedArticles((prev) => [...prev, article]);
    setRelatedArticleSearch("");
    setRelatedArticles([]);
  }


  const handleTagRemove = (tagId) => {
    setSelectedTags((prev) => prev.filter((tag) => tag._id !== tagId));
  };

  const handleArticleRemove = (articleId) => {
    setSelectedArticles((prev) => prev.filter((article) => article._id !== articleId));
  };

  useEffect(() => {
    getCategories();
    getArticle();
  }, []);

  const handleLinkChange = (index, e) => {
    const { value } = e.target;
    setProductLinks((prevLinks) =>
      prevLinks.map((link, i) => (i === index ? value : link))
    );
  };

  const handleAddLink = () => {
    setProductLinks((prevLinks) => [...prevLinks, ""]);
  };

  const handleDeleteLink = (index) => {
    setProductLinks((prevLinks) => prevLinks.filter((_, i) => i !== index));
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <CommonLayout>
      <div className="container my-3">
        <h3 className="text-center mb-4 text-primary fw-bold">
          English Article Form
        </h3>
        <div className="card p-4 mb-3">
          <form>
            <div className="row mb-3">
              <div className="col-md-4">
                <label className="form-label fw-bold"> Category</label>
                <select
                  className="form-select"
                  value={formData.category}
                  onChange={(e) => handleCategoryChange(e.target.value)}
                >
                  <option value="">select category</option>
                  {categories?.length > 0 ? (
                    categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {typeof category.name === "object"
                          ? category.name.en
                          : category.name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No categories available</option>
                  )}
                </select>

              </div>

              <div className="col-md-4">
                <label className="form-label fw-bold"> Sub category</label>
                <select className="form-select" value={formData.subCatgory}
                  onChange={(e) =>
                    handleSubCategoryChange(e.target.value)
                  }>
                  <option value="">select sub category</option>
                  {subCatgory?.length > 0 ? (
                    subCatgory?.map((subCatgory) => (
                      <option key={subCatgory._id} value={subCatgory._id}>
                        {typeof subCatgory.name === "object"
                          ? subCatgory.name.en
                          : subCatgory.name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No sub category available</option>
                  )}
                </select>

              </div>

              <div className="col-md-4">
                <label className="form-label fw-bold"> Banner</label>
                <select className="form-select" value={formData.banner}
                  onChange={(e) =>
                    setFormData({ ...formData, banner: e.target.value })
                  }>
                  <option value="">select banner</option>
                  {banner?.length > 0 ? (
                    banner?.map((banner) => (
                      <option key={banner._id} value={banner._id}>
                        {typeof banner.title === "object"
                          ? banner.title.en
                          : banner.title}
                      </option>
                    ))
                  ) : (
                    <option disabled>No banner available</option>
                  )}
                </select>

              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label className="form-label fw-bold"> Related Article</label>
                <div className="input-group">
                  <span className="input-group-text">
                    <FaSearch />
                  </span>
                  <input
                    type="text"
                    name="relatedArticle"
                    className="form-control formclass"
                    placeholder="Search related articles..."
                    value={relatedArticleSearch}
                    onChange={handleSearchChange}
                  />
                </div>
                {relatedArticles?.length > 0 && (
                  <div className="search-results">
                    {relatedArticles?.map((article) => (
                      <div
                        key={article._id}
                        className="search-result-item"
                        onClick={() => handleRelatedArctileSelect(article)}
                      >
                        <span>
                          {article.title}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                {/* Display selected tags */}
                {selectedArticles?.length > 0 && (
                  <div className="selected-tags">
                    {selectedArticles?.map((article) => (
                      <div key={article._id} className="selected-tag">
                        <span>{article.title}/</span>
                        <button
                          type="button" className="tagclass"
                          onClick={() => handleArticleRemove(article._id)}
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="col-md-6">
                <label className="form-label fw-bold"> Tags</label>
                <div className="input-group">
                  <span className="input-group-text">
                    <FaSearch />
                  </span>
                  <input
                    type="text"
                    name="tag"
                    className="form-control formclass"
                    placeholder="Search tags..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                {tags?.length > 0 && (
                  <div className="search-results">
                    {tags?.map((tag) => (
                      <div
                        key={tag._id}
                        className="search-result-item"
                        onClick={() => handleTagSelect(tag)}
                      >
                        <span>
                          {tag.title.en} / {tag.title.secondary}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
                {/* Display selected tags */}
                {selectedTags?.length > 0 && (
                  <div className="selected-tags">
                    {selectedTags?.map((tag) => (
                      <div key={tag._id} className="selected-tag">
                        <span>{tag.title.en}/</span>
                        <span>{tag.title.secondary}</span>
                        <button
                          type="button" className="tagclass"
                          onClick={() => handleTagRemove(tag._id)}
                        >
                          X
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="card p-4">
          <form>
            <div className="mb-3 row">
              <div className="col-md-6">
                <label className="form-label fw-bold">Title</label>
                <input
                  type="text"
                  className="form-control formclass"
                  name="titleEn"
                  value={formData.titleEn}
                  onChange={handleChange}
                  placeholder="Enter title in English"

                />
                {errors.titleEn && (
                  <div className="text-danger">{errors.titleEn}</div>
                )}
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label fw-bold">
                  Description
                </label>
                <TextArea
                  className="form-control formclass"
                  name="descriptionEn"
                  value={formData.descriptionEn}
                  onChange={handleChange}
                  placeholder="Enter description in English"
                />
                {errors.descriptionEn && (
                  <div className="text-danger">{errors.descriptionEn}</div>
                )}
              </div>

            </div>

            <div>
              <label className="form-label fw-bold">Choose image for Article content </label>
              <div className="mb-4" style={{ marginBottom: "20px" }}>

                <input
                  type="file"
                  className="form-control formclass"
                  name="image"
                  onChange={handleImageUpload}
                />
              </div>
              {imageloading && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    backdropFilter: "blur(5px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1050,
                  }}
                >
                  <div
                    className="spinner-border text-light"
                    role="status"
                    style={{ width: "3rem", height: "3rem" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
              {!imageloading && isModalOpen && (
                <CopyClipBoardModal
                  imagePath={imagePath}
                  isCopied={isCopied}
                  handleCopy={handleCopy}
                  closeModal={closeModal}
                />
              )}

            </div>


            <div className="mb-3">
              <Accordion>
                <AccordionItem className="mb-3">
                  <AccordionItemHeading>
                    <AccordionItemButton style={{ fontWeight: "bold" }}>
                      Content
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {/* <FroalaEditorEn value={formData.bioHtml} onChange={handleEditorChange} /> */}
                    <JoditEditor
                      ref={editor}
                      value={formData.bioHtml} onChange={handleEditorChange}
                    />
                  </AccordionItemPanel>
                </AccordionItem>
                {errors.contentEn && (
                  <div className="text-danger">{errors.contentEn}</div>
                )}
              </Accordion>
            </div>

            <div className=" row">

              <div className="mb-3 col-md-6">
                <label className="form-label fw-bold">
                  SEO keywords
                </label>
                <input
                  type="text"
                  className="form-control formclass"
                  name="seowords"
                  value={formData.seowords}
                  onChange={handleChange}
                  placeholder="Enter description in English"

                />
                {errors.seowords && (
                  <div className="text-danger">{errors.seowords}</div>
                )}
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label fw-bold">
                  SEO description
                </label>
                <TextArea
                  className="form-control formclass"
                  name="seodescription"
                  value={formData.seodescription}
                  onChange={handleChange}
                  placeholder="Enter description in English"

                />
                {errors.seodescription && (
                  <div className="text-danger">{errors.seodescription}</div>
                )}
              </div>

            </div>

            <div className="mb-3 col-md-12">
              <label className="form-label fw-bold">Product Links</label>
              {productLinks?.map((link, index) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <input
                    type="text"
                    className="form-control formclass"
                    name={`productLink${index}`}
                    value={link} // Display the individual link value
                    onChange={(e) => handleLinkChange(index, e)}
                    placeholder="Enter product link"

                  />

                  {/* Add button */}
                  {index === productLinks?.length - 1 && (
                    <button
                      type="button"
                      onClick={handleAddLink}
                      style={{ border: "none", backgroundColor: "transparent" }}
                    >
                      <FaPlus color="black" size={18} />
                    </button>
                  )}

                  {/* Delete button */}
                  {productLinks?.length > 1 && (
                    <button
                      type="button"
                      onClick={() => handleDeleteLink(index)}
                      style={{ border: "none", backgroundColor: "transparent" }}
                    >
                      <FaTrashAlt color="red" size={18} />
                    </button>
                  )}
                </div>
              ))}
              {errors.productlinkss && (
                <div className="text-danger">{errors.productlinkss}</div>
              )}
            </div>

            <div className="text-end mt-4">
              <Button
                icon={<CiSaveDown2 />}
                className="mybtn"
                onClick={handleSubmit}
                type="button"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </CommonLayout>
  );
}

export default EnArticleForm;
