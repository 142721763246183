
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Auth/Login';

import ResetPassword from './pages/Auth/ResetPassword';
import ChangePassword from './pages/profile/changePassword/ChangePassword';
import SignUp from './pages/Auth/SignUp';
import ContentManager from './pages/contentManager.js/ContentManager';
import ForgetPassword from './pages/Auth/ForgetPassword';
import CreateCategories from './pages/Categories/CreateCategories';
import CreateSecondCategory from './pages/Categories/CreateSecondCategory';
import AllCategories from './pages/Categories/AllCategories';
import AllManagers from './pages/contentManager.js/AllManagers';
import Profile from './pages/profile/Profile/Profile';
import CreateTag from './pages/tag/CreateTag';
import AllTag from './pages/allTag/AllTag';
import AllArticle from './pages/ArticalForm/AllArticle';
import MoreArticleDetail from './pages/ArticalForm/MoreArticleDetail';
import AllBanner from './pages/Banner/AllBanner';
import CreateBanner from './pages/Banner/CreateBanner';

import AllSubCategory from './pages/SubCategory/AllSubCategory';
import CreateSubCtegory from './pages/SubCategory/CreateSubCtegory';
import EditArticleNew from './pages/ArticalForm/EditArticleNew';
import Dashboard from './pages/Home/Dashboard';
import EnArticleForm from './pages/ArticalForm/EnArticleForm';
import SecondaryArticleForm from './pages/ArticalForm/SecondaryArticleForm';
import EnEditArticleForm from './pages/ArticalForm/EnEditArticleForm';
import ProtectedRoute from './services/Apis/ProtectedRoute';
import NotFound404 from './pages/errorPage/NotFound404';
import { AuthProvider } from './services/AuthContext';
import PublicRoute from './services/Apis/PublicRoute';
function App() {
  return (
    <div className="App">

      <AuthProvider>
        <Routes>
          {/* Auth */}
          <Route path='/' element={
            <PublicRoute>
            <Login />
            </PublicRoute>} />


          <Route path='/Signup' element={
            <PublicRoute>
            <SignUp />
            </PublicRoute>} />


          <Route path='/ForgetPassword' element={
            <PublicRoute>
            <ForgetPassword />
            </PublicRoute>} />

            
          <Route path='/ResetPassword/:token' element={<ResetPassword />} />

          {/* home */}
          <Route path='/Dashboard' element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>} />


          <Route path='/changePassword' element={
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>} />
          <Route path='/Profile' element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>} />

          <Route path='/ContentManager' element={
            <ProtectedRoute>
              <ContentManager />
            </ProtectedRoute>} />

          <Route path='/AllManager' element={
            <ProtectedRoute>
              <AllManagers />
            </ProtectedRoute>} />

          <Route path='/SecondaryArticleForm' element={
            <ProtectedRoute>
              <SecondaryArticleForm />
            </ProtectedRoute>} />

          <Route path='/EnglishArticleForm' element={
            <ProtectedRoute>
              <EnArticleForm />
            </ProtectedRoute>} />


          <Route path='/articleDetail/:id/:langCode' element={
            <ProtectedRoute>
              <MoreArticleDetail />
            </ProtectedRoute>} />


          <Route path='/edit-article/:id/:langCode' element={
            <ProtectedRoute>
              <EditArticleNew />
            </ProtectedRoute>} />
          <Route path='/English-edit-article/:id/:langCode' element={
            <ProtectedRoute>
              <EnEditArticleForm />
            </ProtectedRoute>} />

          <Route path='/CreateCategories' element={
            <ProtectedRoute>
              <CreateCategories />
            </ProtectedRoute>} />


          <Route path='/CreateSecondCategory' element={
            <ProtectedRoute>
              <CreateSecondCategory />
            </ProtectedRoute>} />


          <Route path='/AllCategories' element={
            <ProtectedRoute>
              <AllCategories />
            </ProtectedRoute>} />

          <Route path='/CreateTag' element={
            <ProtectedRoute>
              <CreateTag />
            </ProtectedRoute>} />
          <Route path="/AllTag" element={
            <ProtectedRoute>
              <AllTag />
            </ProtectedRoute>} />


          <Route path="/AllBanner" element={
            <ProtectedRoute>
              <AllBanner />
            </ProtectedRoute>} />


          <Route path="/CreateBanner" element={
            <ProtectedRoute>
              <CreateBanner />
            </ProtectedRoute>} />

          <Route path="/AllSubCategory" element={
            <ProtectedRoute>
              <AllSubCategory />
            </ProtectedRoute>} />


          <Route path="/CreateSubCategory" element={
            <ProtectedRoute>
              <CreateSubCtegory />
            </ProtectedRoute>} />

          <Route path="*" element={<NotFound404 />}/>

        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
