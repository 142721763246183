import React from "react";
import { Modal, Button } from "antd";

const DeleteModal = ({ isVisible, onConfirm, onCancel }) => {
  return (
    <Modal
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
      centered
      style={{ borderRadius: "15px" , padding:"20px"}}
    >
      <div className="text-center">
        <h4 className="mb-5">Are you sure you want to delete?</h4>
        <div className="d-flex justify-content-center mt-2" style={{ gap: "15px" }}>
          <Button type="primary" danger onClick={onConfirm}>
            Delete
          </Button>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
