import React, { useState, useEffect, useCallback } from "react";
import CommonLayout from "../../layout/commonLayout/CommonLayout";
import { useParams, useNavigate, Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdHome } from "react-icons/md";
import "./AllArticle.css";
import { getArticleById } from "../../services/Apis/ApiServices";
import { languageOptions } from "../../utils/utils";

const MoreArticleDetail = () => {
  const { id , langCode } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState([]);
  const [languages, setLanguages] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState(langCode);

  const fetchArticleById = useCallback(async () => {
    try {
      const response = await getArticleById(id , langCode);
      if (response) {
        setArticle(response);
        setLanguages(response); 
        setSelectedLanguage(langCode);
      }
    } catch (error) {
      console.error("Error fetching article details:", error);
    }
  }, [id , langCode]); 

  useEffect(() => {
    fetchArticleById();
  }, [fetchArticleById]); 

  if (!article) {
    return (
      <div className="container mt-5 text-center">
        <h2>Loading Article...</h2>
      </div>
    );
  }

  const secondaryLangName = languageOptions[article?.languages] || article?.languages
  return (
    <CommonLayout>
      {/* Breadcrumb */}
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb justify-content-end">
          <li className="breadcrumb-item">
            <Link>
              <MdHome size={20} color="#4B49AC" />
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              Article
            </Link>
          </li>
        </ol>
      </nav>

      {/* Article Title */}
      <div className="text-center mb-4">
        <h2 className="fw-bold text-primary">{article?.title}</h2>
        <p className="text-muted fw-bold">{article?.shortDescription}</p>
      </div>

      {/* English Content Card */}
      <div className="card mb-5 p-4 shadow-sm">
        <p className="m-0 borderlinclss" />
        <h4 className=" fw-bold text-dark text-center m-0 p-2">
        {secondaryLangName} Lang Article
        </h4>
        <p className="mb-4 borderlinclssTwo" />
        <div dangerouslySetInnerHTML={{ __html: article?.content }} />
        <hr className="my-4" />
        <div className="row">
          <div className="col-md-6">
            {/* <h5 className="fw-bold">SEO Meta</h5> */}
            <p>
              <strong>Meta Keywords:</strong> {article?.seo?.metaKeywords}
            </p>
            <p>
              <strong>Meta Description:</strong>{" "}
              {article?.seo?.metaDescription}
            </p>
          </div>
          <div className="col-md-6">
            {/* <h5 className="fw-bold">Tags</h5> */}
            <div className="d-flex flex-wrap justify-content-end">
              {article?.tags?.map((tags, index) => (
                <span
                  key={index}
                  className="badge bg-primary text-white me-2 mb-2"
                >
                  #{tags?.title?.en}
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="mt-4">
              <h5 className="fw-bold text-secondary">Category:</h5>
              <p className="text-dark fw-bold fs-6">
                {article?.category?.name?.en}
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mt-4">
              <h5 className="fw-bold text-secondary">sub-category:</h5>
              <p className="text-dark fw-bold fs-6">
                {article?.subCategory?.name?.en}
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mt-4">
              <h5 className="fw-bold text-secondary">Banner:</h5>
              <p className="text-dark fw-bold fs-6">
                {article?.banner?.title?.en || "No data available"}
              </p>
            </div>
          </div>
        </div>
      </div>

 

   <div className="card my-2 mb-4 p-3 shadow-sm">
   <h5 className="fw-bold">Related Articles</h5>
      {article?.relatedArticles?.length > 0 && (
        <div className="my-4">
          <ul className="list-unstyled">
            {article?.relatedArticles?.map((related, index) => (
              <li key={index}>
              
                  {related?.title || "Untitled Article"}
               
              </li>
            ))}
          </ul>
        </div>
      )}
</div>
      {/* Amazon Affiliate Links */}
      <div className="card mb-4  ">
        <div className="row p-4">
          <div className="col-md-6">
            {article?.amazonAffiliateLinks?.length > 0 && (
              <div className="">
                <h5 className="fw-bold">Amazon Affiliate Links</h5>
                <ul className="list-unstyled">
                  {article?.amazonAffiliateLinks?.map((link, index) => (
                    <li key={index}>
                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary"
                      >
                        {link}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* Google Ads */}
          {/* <div className="col-md-6">
            <h5 className="fw-bold">Google Ads</h5>
            <p>
              {typeof article?.googleAds === "string" ? (
                <a
                  href={article.googleAds}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {article.googleAds}
                </a>
              ) : (
                "No Google Ads available"
              )}
            </p>
          </div> */}
        </div>
      </div>

      {/* Edit Button */}
      <div className="d-flex justify-content-end align-items-center mb-4">
        <button
          className="btn btn-primary d-flex align-items-center"
          style={{
            padding: "10px 15px",
            borderRadius: "5px",
            fontSize: "14px",
          }}
          onClick={() => {
            const path = langCode === 'en' 
              ? `/English-edit-article/${id}/${langCode}` 
              : `/edit-article/${id}/${langCode}`;
            navigate(path);
          }}
          
        >
          <FaEdit size={15} className="me-1" /> Edit
        </button>

        
      </div>
    </CommonLayout>
  );
};

export default MoreArticleDetail;
