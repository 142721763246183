// src/context/AvatarContext.js
import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';

const AvatarContext = createContext();

export const AvatarProvider = ({ children }) => {
    const [avatar, setAvatar] = useState(Cookies.get('avatar') || './assets/images/default-profile.png');

    const updateAvatar = (newAvatar) => {
        setAvatar(newAvatar);
        Cookies.set('avatar', newAvatar); 
      };

    return (
        <AvatarContext.Provider value={{ avatar, updateAvatar }}>
            {children}
        </AvatarContext.Provider>
    );
};

export const useAvatar = () => useContext(AvatarContext);
