import React, { useState } from "react";
import CommonLayout from "../../layout/commonLayout/CommonLayout";
import { createTag, uploadTagImage } from "../../services/Apis/ApiServices";
import { toast } from "react-toastify";
import { Button } from "antd";
import { Input } from 'antd';
import { CiSaveDown2 } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { languageOptions } from "../../utils/utils";

function CreateTag() {
  const navigate = useNavigate();
  const { TextArea } = Input;
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    titleEn: "",
    titleSecondary: "",
    descriptionEn: "",
    descriptionSecondary: "",
    language: "", 
    image:"",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [previewImage, setPreviewImage] = useState(null);
  const handleImageChange = async (e) => {
    const selectedFile = e.target.files[0]; 
    if (!selectedFile) return;
    setFile(selectedFile);
  
    try {
      const previewURL = URL.createObjectURL(selectedFile);
      setPreviewImage(previewURL);
      const imagePath = await uploadTagImage(selectedFile);
      if(imagePath){
        setFormData((prevData) => ({
          ...prevData,
          image: imagePath,
        }));
        setFile(null);
      }
    } catch (error) {
      toast.error("Failed to upload image.");
      console.error("Error:", error);
    } finally {
      setFile(null); 
    }
  };
  const validateFields = () => {
    const { language, titleEn, titleSecondary, descriptionEn, descriptionSecondary } = formData;
    const newErrors = {};

    if (!language) newErrors.language = "Language is required";
    if (!titleEn) newErrors.titleEn = "Title (English) is required";
    if (!titleSecondary) newErrors.titleSecondary = `Title (${languageOptions[language]}) is required`;
    if (!descriptionEn) newErrors.descriptionEn = "Description (English) is required";
    if (!descriptionSecondary) newErrors.descriptionSecondary = `Description (${languageOptions[language]}) is required`;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const resetForm = () => {
    setFormData({
      titleEn: "",
      titleSecondary: "",
      descriptionEn: "",
      descriptionSecondary: "",
      language: "",
    });
    setErrors({});
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) return;


    const { titleEn, titleSecondary, descriptionEn, descriptionSecondary, language , tagImage } = formData;
    const apiData = {
      title: { en: titleEn, secondary: titleSecondary },
      description: { en: descriptionEn, secondary: descriptionSecondary },
      language: { secondary: language },
      tagImage: formData.image
    };

    const response = await createTag(apiData);

    if (response) {
      toast.success(response.message || "Tag created successfully");
      navigate("/AllTag");
      resetForm();
    }
  };
  const renderError = (field) =>
    errors[field] && <div className="text-danger">{errors[field]}</div>;

  return (
    <CommonLayout>
      <div className="container my-3">
        <div className="card shadow-lg p-4">
          <h3 className="text-center mb-4 text-primary fw-bold">Create Tag</h3>
          <form>
            <div className="mb-3">
              <label className="form-label fw-bold">Language</label>
              <select
                className="form-select "
                name="language"
                value={formData.language}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Language ---
                </option>
                {Object.entries(languageOptions).map(([code, name]) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                ))}
              </select>
              {renderError("language")}
            </div>

            <div className="mb-3 row">
              <div className="col-md-6">
                <label className="form-label fw-bold">Title (English)</label>
                <input
                  type="text"
                  className="form-control formclass"
                  name="titleEn"
                  value={formData.titleEn}
                  onChange={handleChange}
                  placeholder="Enter title in English"
                  required
                />

                {renderError("titleEn")}
              </div>

              {/* <div className="mb-3"> */}
              <div className="col-md-6">
                <label className="form-label fw-bold">
                  Title ({languageOptions[formData.language]})
                </label>
                <input
                  type="text"
                  className="form-control formclass"
                  name="titleSecondary"
                  value={formData.titleSecondary}
                  onChange={handleChange}
                  placeholder={`Enter title in ${
                    languageOptions[formData.language]
                  }`}
                  required
                />

                {renderError("titleSecondary")}
              </div>
            </div>


              <div className="mb-3 col-md-12">
                <label className="form-label fw-bold">
                  Description (English)
                </label>
                <TextArea
                  className="form-control formclass"
                  name="descriptionEn"
                  value={formData.descriptionEn}
                  onChange={handleChange}
                  placeholder="Enter description in English"
                  required
                />

                {renderError("descriptionEn")}
              </div>

              <div className="mb-4 col-md-12">
                <label className="form-label fw-bold">
                  Description ({languageOptions[formData.language]})
                </label>
                <TextArea
                  className="form-control formclass"
                  name="descriptionSecondary"
                  value={formData.descriptionSecondary}
                  onChange={handleChange}
                  placeholder={`Enter description in ${
                    languageOptions[formData.language]
                  }`}
                  required
                />

                {renderError("descriptionSecondary")}
              </div>


              <div className="">
              <label className="form-label fw-bold">Upload Image</label>
              <input
                type="file"
                className="form-control formclass"
                onChange={handleImageChange}
                accept="image/*"
                required
              />
              {previewImage && (
                <div className="mt-3">
                  <img src={previewImage} alt="Preview" style={{ width: "100px", height: "100px", objectFit: "cover" }} />
                </div>
              )}
            </div>


            <div className="text-end mt-4">
              <Button
                icon={<CiSaveDown2 />}
                className="mybtn"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </CommonLayout>
  );
}

export default CreateTag;
