
import React, { useEffect, useState } from 'react';
import { getProfile, uploadProfileImage, updateProfile } from '../../../services/Apis/ApiServices';
import CommonLayout from '../../../layout/commonLayout/CommonLayout';
import { Button, Form, Input, Upload, Image } from 'antd';
import { UserOutlined, UploadOutlined } from '@ant-design/icons';
import { MdHome } from "react-icons/md";
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { CiSaveDown2 } from "react-icons/ci";
import '../../../App.css';
import { useAvatar } from '../../../components/avatarContext/AvatarContext';

function Profile() {

    const [newAdminData, setNewAdminData] = useState({});
    const [image, setImage] = useState(null);
    const [permissions, setPermissions] = useState("");
    const [loading, setLoading] = useState(false);

    const {  updateAvatar } = useAvatar();  

    const profileData = async () => {
        try {
            const response = await getProfile();
            if (!response) {
                console.log("An error fetching data");
            } else {
                setNewAdminData(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getPermissionsFromCookies = () => {
        const permissionsCookie = Cookies.get("permissions");
        if (permissionsCookie) {
            try {

                const parsedPermissions = JSON.parse(permissionsCookie);
                setPermissions(parsedPermissions);
            } catch (error) {
                console.log("Error parsing permissions:", error);
            }
        }
    };


    useEffect(() => {
        profileData();
        getPermissionsFromCookies();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewAdminData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleImageUpload = async ({ file }) => {
        setLoading(true);
        try {
            const imagePath = await uploadProfileImage(file);
            setImage(imagePath);

           
            setNewAdminData((prevData) => ({
                ...prevData,
                avatar: imagePath
            }));
            toast.success("Image uploaded successfully!");
        } catch (error) {
            toast.error("An error occurred during the upload.");
        } finally {
            setLoading(false);
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        try {
         const response =    await updateProfile(newAdminData);
                if(response){
                    updateAvatar(response.avatar);
                    toast.success("Profile updated successfully!");
                }
        } catch (error) {
            toast.error("An error occurred while saving the profile.");
        } finally {
            setLoading(false);
        }
    };


    const permissionsList = Object.keys(permissions)
        .filter((key) => permissions[key] === true)
        .map((key) => (
            <li key={key}>{key}</li>
        ));


    return (
        <CommonLayout>
            <div className="container mt-1">

                <div className='row'>
                    {/* <div className='text-center fw-bold'>
                     <h3 className="mt-3">{admindata.name || 'Admin Profile'}</h3>
                            <p>{admindata.email || 'admin@example.com'}</p>
                            </div> */}
                    <nav aria-label="breadcrumb" >
                        <ol class="breadcrumb justify-content-end">
                            <li class="breadcrumb-item"><Link><MdHome size={20} color='#4B49AC' /></Link></li>
                            <li class="breadcrumb-item"><Link style={{ textDecoration: 'none', color: 'black', fontWeight: 'bold', fontSize: '15px' }}>Profile</Link></li>
                        </ol>
                    </nav>
                    <div className='col-md-3 col-sm-12 text-center card card-body mb-3'>
                        <div className='mb-3'>
                            <Image
                                size={100}
                                src={image || newAdminData.avatar || './assets/images/default-profile.png'}
                                icon={<UserOutlined />}
                                alt="Profile Avatar"
                            />
                        </div>

                        {/* <Upload className='mt-5'>
                        <Button icon={<UploadOutlined />} loading={loading}>Change Avatar</Button>
                        </Upload> */}
                        <Upload
                            showUploadList={false}
                            customRequest={handleImageUpload}
                            className='mt-5'
                        >
                            <Button icon={<UploadOutlined />} loading={loading}>Change Avatar</Button>
                        </Upload>

                    </div>

                    {/* Profile Info Edit Form */}
                    <div className='col-md-9 col-sm-12 text-center paddingclass'>
                        <div className='card card-body'>
                            <Form layout="vertical" onSubmit={handleSubmit}>
                                <Form.Item label="Name" required style={{ fontWeight: 'bold' }}>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={newAdminData.name || ''}
                                        onChange={handleChange}
                                        placeholder="Enter name"
                                    />
                                </Form.Item>
                                <Form.Item label="Email" required style={{ fontWeight: 'bold' }}>
                                    <Input
                                        type="email"
                                        name="email"
                                        value={newAdminData.email || ''}
                                        onChange={handleChange}
                                        placeholder="Enter email"
                                    />
                                </Form.Item>

                                <Form.Item label="Permissions" required style={{ fontWeight: 'bold' }}>
                                    <ul class='liststyle'>
                                        {permissionsList.length > 0 ? permissionsList : <li>No active permissions</li>}
                                    </ul>
                                </Form.Item>


                            </Form>
                        </div>
                    </div>
                </div>
                <div className='text-end mt-4'>
                    <Button
                        icon={<CiSaveDown2 />}
                        className='mybtn'
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        Save Changes
                    </Button>
                </div>
            </div>
        </CommonLayout>
    );
}

export default Profile;
