import React, { useState, useEffect , useRef } from "react";
import CommonLayout from "../../layout/commonLayout/CommonLayout";
import {
    fetchAllArticles,
    fetchArticleBySearch,
    fetchBannersByCategory,
    fetchCategoryBySubCategory,
    fetchTagsBySearch,
    getArticleById,
    updateArticle,
    uploadNewArticleImage,
} from "../../services/Apis/ApiServices";
import { toast } from "react-toastify";
import { Button, Input } from "antd";
import { languageOptions } from "../../utils/utils";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import { CiSaveDown2 } from "react-icons/ci";
import { fetchCategories } from "../../services/Apis/ApiServices";
import { FaSearch, FaPlus, FaTrashAlt } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import FroalaEditorEn from "../../components/Editor/FroalaEditorEn";
import JoditEditor from 'jodit-react';
import CopyClipBoardModal from "../../components/models/CopyClipBoardModal";
function EditArticleNew() {
    const { id, langCode } = useParams();
    const { TextArea } = Input;
    const [categories, setCategories] = useState([]);
    const [article, setArticle] = useState([]);
    const [relatedArticles, setRelatedArticles] = useState([]);
    const [banner, setbanner] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [relatedArticleSearch, setRelatedArticleSearch] = useState("");
    const [englishContentSeach, setEnglishContentSearch] = useState("");
    const [subCategory, setSubCategory] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedArticles, setSelectedArticles] = useState([]);
    const [productLinks, setProductLinks] = useState([""]);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const [lang, setLang] = useState();
    const [islang, setIsLang] = useState(false);
    const [handleLang, setHandleLang] = useState()
    const [selectedEnglishArticle, setSelectedEnglishArticle] = useState(null);

    const [imagePath, setImagePath] = useState("");
    const [isCopied, setIsCopied] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageloading, setImageLoading] = useState(false);

    const navigate = useNavigate();
    const editor = useRef(null)
    const [formData, setFormData] = useState({
        title: "",
        shortDescription: "",
        languages: "",
        seowords: "",
        seo: {
            metaKeywords: "",
            metaDescription: "",
        },
        category: "",
        subCategory: "",
        content: "",
        relatedArticles: [],
        englishContent: "",
        tags: [],
        banner: "",
    });

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                setImageLoading(true)
                const response = await uploadNewArticleImage(file);

                if (response) {
                    setImagePath(response);
                    setIsModalOpen(true);
                    setIsCopied(false);
                } else {
                    toast.error("Image upload failed. Please try again.");
                }
            } catch (error) {
                console.error("Error uploading image:", error);
                toast.error("An error occurred while uploading the image.");
            } finally {
                setImageLoading(false)
            }
        }
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(imagePath).then(() => {
            setIsCopied(true);
            setTimeout(() => {
                setIsModalOpen(false);
                setIsCopied(false);
            }, 300)
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Handle specific nested fields
        if (name === "seo.metaKeywords") {
            setFormData((prevData) => ({
                ...prevData,
                seo: {
                    ...prevData.seo,
                    metaKeywords: value,
                },
            }));
        } else if (name === "seo.metaDescription") {
            setFormData((prevData) => ({
                ...prevData,
                seo: {
                    ...prevData.seo,
                    metaDescription: value,
                },
            }));
        } else {
            // Handle top-level updates
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleEditorChange = (content, editor) => {
        setFormData({
            ...formData,
            content: content,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const apiData = {
            _id: id,
            languages: formData.languages,
            title: formData.title,
            shortDescription: formData.shortDescription,
            content: formData.content,
            category: formData.category || "",
            subCategory: formData.subCategory._id || "",
            relatedArticles: selectedArticles,
            tags: selectedTags,
            seo: {
                metaKeywords: formData.seo.metaKeywords,
                metaDescription: formData.seo.metaDescription,
            },
            amazonAffiliateLinks: productLinks,
            banner: formData.banner || "",
            englishContent: formData.englishContent,
        };
        try {
            const response = await updateArticle(apiData);
            if (response) {
                // toast.success(response.message || "Article updated successfully");
                navigate("/Dashboard");
                setFormData({
                    title: "",
                    shortDescription: "",
                    category: "",
                    subCategory: "",
                    languages: "",
                    banner: "",
                    seo: {
                        metaKeywords: "",
                        metaDescription: "",
                    },
                    content: "",
                    banner: "",
                });
                setTags([]);
                setProductLinks([""]);
                setArticle([]);
            } else {
                toast.error(
                    response?.message || "An error occurred while updating the article"
                );
            }
        } catch (error) {
            console.error("Error updating article:", error);
            toast.error("There was an error updating the article.");
        }
    };

    const getCategories = async () => {
        try {
            const fetchedCategories = await fetchCategories();
            if (fetchedCategories?.length === 0) {
            } else {
                setCategories(fetchedCategories.categories);
            }
        } catch (err) {
            console.error("Error in getCategories:", err);
        }
    };

    // const getArticle = async () => {
    //     try {
    //         const fetchedArticle = await fetchAllArticlesforNewArticle();
    //         if (fetchedArticle?.length === 0) {
    //         } else {
    //             setArticle(fetchedArticle.articles);
    //         }
    //     } catch (err) {
    //         console.error("Error in get article:", err);
    //     }
    // };

    const handleCategoryChange = async (categoryId) => {
        setFormData((prevFormData) => ({ ...prevFormData, category: categoryId }));
        setbanner([]);
        if (categoryId) {
            try {
                const fetchedSubCategory = await fetchCategoryBySubCategory(categoryId);
                if (fetchedSubCategory?.length > 0) {
                    setSubCategory(fetchedSubCategory);
                    setbanner([]);
                }
            } catch (err) {
                console.error("Error fetching data:", err);
            }
        } 
        if(categoryId) {
            const fetchedBanner = await fetchBannersByCategory(categoryId);
            if (fetchedBanner?.length > 0) {
                setbanner(fetchedBanner);
            }
        }
        else {
            setSubCategory([]);
            setbanner([]);
        }
    };

    const fetchSubCategoriesByCategory = async () => {
        try {
            const fetchedSubCategory = await fetchCategoryBySubCategory(formData.category);
            if (fetchedSubCategory.length > 0) {
                setSubCategory(fetchedSubCategory);
            }
            // if (formData.subCategory !== "") {
            //     const fetchedBanner = await fetchBannersByCategory(
            //         formData.category,
            //         formData.subCategory._id
            //     );

            //     if (fetchedBanner.length > 0) {
            //         setbanner(fetchedBanner);
            //     }
            // }
        } catch (err) {
            console.error("Error fetching data:", err);
        }
    }

    useEffect(() => {
        if (formData.category !== "") {
            fetchSubCategoriesByCategory();
        }

    }, [formData.category])

    const handleSubCategoryChange = async (subCategoryId) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            subCategory: {
                _id: subCategoryId,
            },
        }));
        if (subCategoryId) {
            try {
                const fetchedBanner = await fetchBannersByCategory(
                    formData.category,
                    subCategoryId
                );
                if (fetchedBanner.length > 0) {
                    setbanner(fetchedBanner);
                }
            } catch (err) {
                console.error("Error fetching data:", err);
            }
        } else {
            setbanner([]);
        }
    };



    const handleArticleSelect = (e) => {
        const selectedId = e.target.value;

        // Find the selected article
        const selectedArticle = relatedArticles.find(
            (article) => article._id === selectedId
        );

        if (
            selectedArticle &&
            !selectedArticles.some((a) => a._id === selectedId)
        ) {
            setSelectedArticles([...selectedArticles, selectedArticle]);
        }
    };

    const handleRemoveArticle = (id) => {
        setSelectedArticles(
            selectedArticles.filter((article) => article._id !== id)
        );
    };
    const fetchArticle = async (code) => {
        if (relatedArticleSearch?.length > 0) {
            const result = await fetchArticleBySearch(relatedArticleSearch, code);
            setRelatedArticles(result);
        }

        else if (englishContentSeach.length > 0) {
            const result = await fetchArticleBySearch(englishContentSeach, code);
            setArticle(result);
        }

        else {
            setRelatedArticleSearch([]);
            setEnglishContentSearch([]);
        }
    };

    const fetchTags = async () => {
        if (searchQuery?.length > 0) {
            const result = await fetchTagsBySearch(searchQuery);
            setTags(result);
        } else {
            setTags([]);
        }
    };

    const handleSearchChange = (event) => {
        const { name, value } = event.target;

        if (name == "tag") {
            setSearchQuery(value);
            setDebounceTimeout(setTimeout(fetchTags, 300));
        }
        else if (name == "relatedArticle") {
            setRelatedArticleSearch(value);

            setDebounceTimeout(setTimeout(() => {
                fetchArticle(lang)
            }, 300));
        }
        else if (name == 'englishContent') {
            setEnglishContentSearch(value)
            setFormData((prevFormData) => ({ ...prevFormData, englishContent: value }));
            setDebounceTimeout(setTimeout(() => {
                fetchArticle('en')
            }, 300));
        }

        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
    };

    const handleTagSelect = (tag) => {
        setSelectedTags((prev) => [...prev, tag]);
        setSearchQuery("");
        setTags([]);
    };

    const handleRelatedArctileSelect = (article) => {
        setSelectedArticles((prev) => [...prev, article]);
        setRelatedArticleSearch("");
        setRelatedArticles([]);
    }

    const handleEnglishContentSelect = (article) => {
        setSelectedEnglishArticle(article);
        setFormData((prevFormData) => ({
            ...prevFormData,
            englishContent: article._id,
        }));
        setEnglishContentSearch("");
    };

    const handleTagRemove = (tagId) => {
        setSelectedTags((prev) => prev.filter((tag) => tag._id !== tagId));
    };


    const handleArticleRemove = (articleId) => {
        setSelectedArticles((prev) => prev.filter((article) => article._id !== articleId));
    };

    const handleClearEnglishContent = () => {
        setArticle([]);
        setSelectedEnglishArticle(null);
        setEnglishContentSearch("");
    };
    useEffect(() => {
        getCategories();
        // getArticle();
    }, []);

    const handleLinkChange = (index, e) => {
        const { value } = e.target;
        setProductLinks((prevLinks) =>
            prevLinks.map((link, i) => (i === index ? value : link))
        );
    };

    const handleAddLink = () => {
        setProductLinks((prevLinks) => [...prevLinks, ""]);
    };

    const handleDeleteLink = (index) => {
        setProductLinks((prevLinks) => prevLinks.filter((_, i) => i !== index));
    };

    const handlelangChange = (e) => {

        const { name, value } = e.target;

        setFormData((prevData) => {
            switch (name) {
                case "languages":
                    return {
                        ...prevData,
                        languages: value,
                    };

                default:
                    return prevData;
            }
        });
        setLang(value || formData.languages);
        setIsLang(true);
    };

    // const getArticleforedit = async () => {
    //     console.log("im run");
    //     try {
    //         const fetchedArticle = await fetchAllArticles(lang ? lang : formData.languages);
    //         console.log(fetchedArticle, "fetchedArticle");
    //         if (fetchedArticle?.length === 0) {
    //             setSelectedArticles([]);
    //             setRelatedArticles([]);
    //             setIsLang(false);
    //             setLang("");
    //         } else {
    //             setRelatedArticles(fetchedArticle.articles);
    //             setIsLang(false);
    //             setLang("");

    //         }
    //     } catch (err) {
    //         console.error("Error in get article:", err);
    //     }
    // };

    // useEffect(() => {
    //     if (islang) {
    //         console.log("im run in useEffect");
    //         getArticleforedit();
    //     }
    // }, [formData.languages]);

    const fetchArticleDetailsforEdit = async (id, langCode) => {
        try {
            const response = await getArticleById(id, langCode);
            if (response) {
                const articleData = response;

                setFormData({
                    title: articleData.title || "",
                    shortDescription: articleData.shortDescription || "",
                    languages: articleData.languages || "",
                    seo: {
                        metaKeywords: articleData?.seo?.metaKeywords || "",
                        metaDescription: articleData?.seo?.metaDescription || "",
                    },
                    category: articleData.category?._id || "",
                    subCategory: articleData.subCategory,
                    content: articleData.content || "",
                    relatedArticles: articleData.relatedArticles || [],
                    tags: articleData.tags || [],
                    banner: articleData.banner?._id || "",
                    englishContent: articleData?.englishContent?._id || "",
                });
                if (articleData?.englishContent) {
                    setSelectedEnglishArticle(articleData.englishContent);
                }
                // Update other related states if required
                setSelectedTags(articleData.tags || []);
                setProductLinks(articleData.amazonAffiliateLinks || [""]);
                setSelectedArticles(articleData.relatedArticles || []);
                setLang(articleData.languages);
                setIsLang(true);
            } else {
                console.error("Failed to fetch article details:", response.message);
            }
        } catch (error) {
            console.error("Error fetching article details:", error);
        }
    };

    useEffect(() => {
        fetchArticleDetailsforEdit(id, langCode);
    }, [id, langCode]);

const closeModal = () => {
    setIsModalOpen(false);
}
    return (
        <CommonLayout>
            <div className="container my-3">
                <h3 className="text-center mb-4 text-primary fw-bold">Edit Article</h3>

                <div className="card p-4 mb-3">
                    <form>
                        <div className="mb-3">
                            <label className="form-label fw-bold">Language</label>
                            <select
                                className="form-select formclass"
                                name="languages"
                                value={formData.languages}
                                onChange={(e) => handlelangChange(e)}
                            >
                                <option value="" disabled>
                                    Select Language ---
                                </option>
                                {Object?.entries(languageOptions)?.map(([code, name]) => (
                                    <option key={code} value={code}>
                                        {name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </form>
                </div>

                <div className="card p-4 mb-3">
                    <form>
                        <div className="row mb-3">
                            <div className="col-md-4">
                                <label className="form-label fw-bold"> Category</label>
                                <select
                                    className="form-select"
                                    value={formData.category}
                                    onChange={(e) => handleCategoryChange(e.target.value)}
                                >
                                    <option value="">select category</option>
                                    {categories?.length > 0 ? (
                                        categories.map((category) => (
                                            <option key={category._id} value={category._id}>
                                                {typeof category.name === "object"
                                                    ? category.name.en
                                                    : category.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option disabled>No categories available</option>
                                    )}
                                </select>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold"> Sub category</label>
                                <select
                                    className="form-select"
                                    value={formData?.subCategory?._id}

                                    onChange={(e) => handleSubCategoryChange(e.target.value)}
                                >

                                    <option value="">select sub category</option>
                                    {Array.isArray(subCategory) && subCategory.length > 0 ? (
                                        subCategory.map((sCat) => (
                                            <option key={sCat._id} value={sCat._id}>
                                                {typeof sCat.name === "object"
                                                    ? sCat.name.en
                                                    : sCat.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option disabled>No banners available</option>
                                    )}
                                </select>
                            </div>

                            <div className="col-md-4">
                                <label className="form-label fw-bold"> Banner</label>
                                <select
                                    className="form-select"
                                    value={formData.banner}
                                    onChange={(e) =>
                                        setFormData({ ...formData, banner: e.target.value })
                                    }
                                >
                                    <option value="">select banner</option>
                                    {Array.isArray(banner) && banner.length > 0 ? (
                                        banner.map((bannerItem) => (
                                            <option key={bannerItem._id} value={bannerItem._id}>
                                                {typeof bannerItem.title === "object"
                                                    ? bannerItem.title.en
                                                    : bannerItem.title}
                                            </option>
                                        ))
                                    ) : (
                                        <option disabled>No banners available</option>
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="row mb-3">


                            {/* <div className="col-md-4">
                                        <label className="form-label fw-bold">
                                            Choose english article
                                        </label>
                                        <select
                                            className="form-select"
                                            value={formData?.englishContent}
                                            onChange={(e) =>
                                                setFormData({ ...formData, englishContent: e.target.value })
                                            }
                                        >
                                            <option value="">select article</option>
                                            {article?.length > 0 ? (
                                                article?.map((article) => (
                                                    <option key={article._id} value={article._id}>
                                                        {typeof article.title === "object"
                                                            ? article.title.en
                                                            : article.title}
                                                    </option>
                                                ))
                                            ) : (
                                                <option disabled>No article available</option>
                                            )}
                                        </select>
                                    </div> */}

                            <div className="col-md-4">
                                <label className="form-label fw-bold"> Choose English Article</label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <FaSearch />
                                    </span>
                                    <input
                                        type="text"
                                        name="englishContent"
                                        className="form-control formclass"
                                        placeholder="Search english articles..."
                                        value={selectedEnglishArticle?.title || englishContentSeach}
                                        onChange={(e) => {
                                            handleSearchChange(e);
                                            setFormData({ ...formData, englishContent: e.target.value })

                                        }}
                                        disabled={!!selectedEnglishArticle}
                                    />
                                    {selectedEnglishArticle && (
                                        <span
                                            className="input-group-text"
                                            style={{ cursor: "pointer" }}
                                            onClick={handleClearEnglishContent}
                                        >
                                            ✕
                                        </span>
                                    )}
                                </div>
                                {article?.length > 0 && !selectedEnglishArticle && (
                                    <div className="search-results">
                                        {article?.map((art) => (
                                            <div
                                                key={art._id}
                                                className="search-result-item"
                                                onClick={() => handleEnglishContentSelect(art)}
                                            >
                                                <span>
                                                    {art.title}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>


                            {/* <div className="col-md-4">
                                <label className="form-label fw-bold">Related Articles</label>
                                <select
                                    className="form-select"
                                    onChange={handleArticleSelect}
                                    value=""
                                >
                                    <option value="">Select Related article</option>
                                    {relatedArticles?.length > 0 ? (
                                        relatedArticles?.filter(
                                            (article) =>
                                                !selectedArticles.some((a) => a._id === article._id) &&
                                                article._id !== id 
                                        ).map((article) => (
                                            <option key={article._id} value={article._id}>
                                                {typeof article.title === "object"
                                                    ? article.title.en
                                                    : article.title}
                                            </option>
                                        ))
                                    ) : (
                                        <option disabled>No article available</option>
                                    )}
                                </select>

                             
                                {selectedArticles?.length > 0 && (
                                    <div className="selected-articles mt-3">
                                        {selectedArticles?.map((article) => (
                                            <div key={article._id} className="selected-article  mb-2">
                                                <span className="me-2">
                                                    {typeof article.title === "object"
                                                        ? article.title.en
                                                        : article.title}
                                                </span>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm tagclass"
                                                    onClick={() => handleRemoveArticle(article._id)}
                                                >
                                                    X
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div> */}
                            <div className="col-md-4">
                                <label className="form-label fw-bold"> Related Article</label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <FaSearch />
                                    </span>
                                    <input
                                        type="text"
                                        name="relatedArticle"
                                        className="form-control formclass"
                                        placeholder="Search related articles..."
                                        value={relatedArticleSearch}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                                {relatedArticles?.length > 0 && (
                                    <div className="search-results">
                                        {relatedArticles?.map((article) => (
                                            <div
                                                key={article._id}
                                                className="search-result-item"
                                                onClick={() => handleRelatedArctileSelect(article)}
                                            >
                                                <span>
                                                    {article.title}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {/* Display selected tags */}
                                {selectedArticles?.length > 0 && (
                                    <div className="selected-tags">
                                        {selectedArticles?.map((article) => (
                                            <div key={article._id} className="selected-tag">
                                                <span>{article.title}/</span>
                                                <button
                                                    type="button" className="tagclass"
                                                    onClick={() => handleArticleRemove(article._id)}
                                                >
                                                    X
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-4">
                                <label className="form-label fw-bold"> Tags</label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        <FaSearch />
                                    </span>
                                    <input
                                        type="text"
                                        name='tag'
                                        className="form-control formclass"
                                        placeholder="Search tags..."
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                                {tags?.length > 0 && (
                                    <div className="search-results">
                                        {tags?.map((tag) => (
                                            <div
                                                key={tag._id}
                                                className="search-result-item"
                                                onClick={() => handleTagSelect(tag)}
                                            >
                                                <span>
                                                    {tag.title.en} / {tag.title.secondary}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {/* Display selected tags */}
                                {selectedTags?.length > 0 && (
                                    <div className="selected-tags">
                                        {selectedTags?.map((tag) => (
                                            <div key={tag._id} className="selected-tag">
                                                <span>{tag.title.en}/</span>
                                                <span>{tag.title.secondary}</span>
                                                <button
                                                    type="button"
                                                    className="tagclass"
                                                    onClick={() => handleTagRemove(tag._id)}
                                                >
                                                    X
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
                <div className="card p-4">
                    <form>
                        <div className="mb-3 row">
                            <div className="col-md-6">
                                <label className="form-label fw-bold">Title</label>
                                <input
                                    type="text"
                                    className="form-control formclass"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    placeholder="Enter title in English"
                                    required
                                />
                            </div>

                            <div className="mb-3 col-md-6">
                                <label className="form-label fw-bold">Description</label>
                                <TextArea
                                    className="form-control formclass"
                                    name="shortDescription"
                                    value={formData.shortDescription}
                                    onChange={handleChange}
                                    placeholder="Enter description in English"
                                    required
                                />
                            </div>
                        </div>

                        <div>
                            <label className="form-label fw-bold">Choose image for Article content </label>
                            <div className="mb-4" style={{ marginBottom: "20px" }}>

                                <input
                                    type="file"
                                    className="form-control formclass"
                                    name="image"
                                    onChange={handleImageUpload}
                                />
                            </div>
                            {imageloading && (
                                <div
                                    style={{
                                        position: "fixed",
                                        top: 0,
                                        left: 0,
                                        width: "100vw",
                                        height: "100vh",
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                                        backdropFilter: "blur(5px)",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        zIndex: 1050,
                                    }}
                                >
                                    <div
                                        className="spinner-border text-light"
                                        role="status"
                                        style={{ width: "3rem", height: "3rem" }}
                                    >
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {!imageloading && isModalOpen && (
                                <CopyClipBoardModal
                                    imagePath={imagePath}
                                    isCopied={isCopied}
                                    handleCopy={handleCopy}
                                    closeModal={closeModal}
                                />
                            )}
                        </div>

                        <div className="mb-3">
                            <Accordion>
                                <AccordionItem className="mb-3">
                                    <AccordionItemHeading>
                                        <AccordionItemButton style={{ fontWeight: "bold" }}>
                                            Content
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>

                                        <JoditEditor
                                            ref={editor}
                                            value={formData.content} onChange={handleEditorChange}
                                        />
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>

                        <div className=" row">
                            <div className="mb-3 col-md-6">
                                <label className="form-label fw-bold">SEO keywords</label>
                                <input
                                    type="text"
                                    className="form-control formclass"
                                    name="seo.metaKeywords"
                                    value={formData?.seo?.metaKeywords}
                                    onChange={handleChange}
                                    placeholder="Enter description in English"
                                    required
                                />
                            </div>

                            <div className="mb-3 col-md-6">
                                <label className="form-label fw-bold">SEO description</label>
                                <TextArea
                                    className="form-control formclass"
                                    name="seo.metaDescription"
                                    value={formData?.seo?.metaDescription}
                                    onChange={handleChange}
                                    placeholder="Enter description in English"
                                    required
                                />
                            </div>
                        </div>

                        <div className="mb-3 col-md-12">
                            <label className="form-label fw-bold">Product Links</label>
                            {productLinks?.map((link, index) => (
                                <div key={index} className="d-flex align-items-center mb-2">
                                    <input
                                        type="text"
                                        className="form-control formclass"
                                        name={`productLink${index}`}
                                        value={link} // Display the individual link value
                                        onChange={(e) => handleLinkChange(index, e)}
                                        placeholder="Enter product link"
                                        required
                                    />

                                    {/* Add button */}
                                    {index === productLinks?.length - 1 && (
                                        <button
                                            type="button"
                                            onClick={handleAddLink}
                                            style={{ border: "none", backgroundColor: "transparent" }}
                                        >
                                            <FaPlus color="black" size={18} />
                                        </button>
                                    )}

                                    {/* Delete button */}
                                    {productLinks?.length > 1 && (
                                        <button
                                            type="button"
                                            onClick={() => handleDeleteLink(index)}
                                            style={{ border: "none", backgroundColor: "transparent" }}
                                        >
                                            <FaTrashAlt color="red" size={18} />
                                        </button>
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className="text-end mt-4">
                            <Button
                                icon={<CiSaveDown2 />}
                                className="mybtn"
                                onClick={handleSubmit}
                                type="button"
                            >
                                update
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </CommonLayout>
    );
}

export default EditArticleNew;
