import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './authStyle/Login.css'
import { loginUser } from '../../components/Auth/AuthApi';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [loading , setLoading] = useState(false);
    const navigate = useNavigate();

    const validateForm = () => {
        const newErrors = {};
        if (!email) newErrors.email = 'Email is required';
        if (!password) newErrors.password = 'Password is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            try {
                const response = await loginUser(email, password);
    
                if (response && response.data && response.data.token) {
                    toast.success(response.data.role === 'superAdmin' ? 'Admin Login Successfully' : 'Content Manager Login successfully');
                    navigate('/Dashboard');
                } else {
                    toast.error(response.message);
                }
            } catch (error) {
                if (error.response) {
                    toast.error(
                        error.response.data.message || 'Invalid email or password'
                    );
                } else if (error.request) {
                    toast.error('No response received, please try again');
                } else {
                    console.error('An error occurred', error);
                }
            } finally{
                setLoading(false);
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div className="">
            <div className="container-fluid p-0">
                <div className="content-wrapper d-flex align-items-center justify-content-center auth px-0" style={{
                    backgroundImage: "url('https://img.freepik.com/free-photo/flowers-notebook-near-laptop_23-2147759307.jpg?semt=ais_hybrid')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100vh',
                }}>
                    <div className="row widthclass mx-0 h-100 ">
                        <div className="col-lg-4 col-md-6 col-sm-8 mx-auto card shadow-sm" style={{
                            borderRadius: '10px'
                        }}>
                            <div className=" text-left rounded px-sm-4 ">
                                <div className="brand-logo d-flex align-items-center justify-content-center ">
                                    <img src="https://img.freepik.com/premium-vector/blog-word-drawn-lettering-typographic-element_205544-12246.jpg?semt=ais_hybrid" alt="logo" style={{ height: "90px" }} />
                                </div>
                                <h4 className="text-center">Hello! Let's get started</h4>
                                <h6 className="font-weight-light text-center mb-4">Sign in to continue.</h6>
                                <form className="smallclass" onSubmit={handleSubmit}>
                                    <div className="form-group mb-3">
                                        <input
                                            type="email"
                                            className={`form-control formclass  ${errors.email ? 'is-invalid' : ''}`}
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                    </div>
                                    <div className="form-group position-relative">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className={`form-control formclass  ${errors.password ? 'is-invalid' : ''}`}
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                        <span
                                            onClick={togglePasswordVisibility}
                                            className="position-absolute top-50 end-0 translate-middle-y pe-3 cursor-pointer"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </span>
                                    </div>
                                    <div className="mt-4 d-grid gap-2 mb-4">

                                    <div className="mt-4 d-grid gap-2 mb-4">
                                        <button type="submit" className="btn btn-primary m-0" disabled={loading}>
                                            {loading ? (
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ width: '1rem', height: '1rem' }}></span>
                                            ) : 'Sign In'}
                                        </button>
                                    </div>
                                    </div>
                                    <div className="my-2 d-flex justify-content-between align-items-center">
                                        <div className="form-check">
                                            <label className="form-check-label text-muted">
                                                <input type="checkbox" className="form-check-input" /> Keep me signed in
                                            </label>
                                        </div>
                                        <Link to='/ForgetPassword' className="auth-link text-black">Forgot password?</Link>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;