import React, { useEffect, useState } from "react";
import CommonLayout from "../../layout/commonLayout/CommonLayout";
import {
  getAllManagers,
  deleteManager,
  updateManagerPermissions,
} from "../../services/Apis/ApiServices";
import { toast } from "react-toastify";
import "./AllManagers.css";
import { Link } from "react-router-dom";
import { MdHome } from "react-icons/md";
import { FaSearch, FaTrashAlt } from "react-icons/fa";
import { Input } from "antd";
import { MdModeEdit } from "react-icons/md";
import { GrFormPreviousLink ,GrFormNextLink  } from "react-icons/gr";
import {Image} from 'antd';

function AllManagers() {
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedManager, setSelectedManager] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const fetchManagers = async (page = 1) => {
    try {
      const response = await getAllManagers(page);
      if (response.success) {
        setManagers(response.data.users);
        setTotalPages(response.data.totalPages);
      } else {
        setError(response.message || "Failed to fetch data");
      }
    } catch (err) {
      setError("An error occurred while fetching managers");
      toast.error("An error occurred while fetching managers");
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (manager) => {
    setSelectedManager(manager);
    setShowEditModal(true);
  };

  const handleDeleteClick = (manager) => {
    setSelectedManager(manager);
    setShowDeleteModal(true);
  };

  const handleUpdatePermissions = async (updatedPermissions) => {
    try {
      const response = await updateManagerPermissions(
        selectedManager._id,
        updatedPermissions
      );
      if (response.success) {
        setManagers((prev) =>
          prev.map((m) =>
            m._id === selectedManager._id
              ? { ...m, permissions: updatedPermissions }
              : m
          )
        );
        toast.success("Permissions updated successfully");
      } else {
        toast.error(response.message || "Failed to update permissions");
      }
    } catch {
      toast.error("An error occurred while updating permissions");
    } finally {
      setShowEditModal(false);
      setSelectedManager(null);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteManager(selectedManager._id);
      if (response.success) {
        
        setManagers((prev) =>
          prev.filter((manager) => manager._id !== selectedManager._id)
        );
        toast.success("Manager deleted successfully");
      
        fetchManagers(currentPage);
      } else {
        toast.error(response.message || "Failed to delete manager");
      }
    } catch {
      toast.error("An error occurred while deleting manager");
    } finally {
      setShowDeleteModal(false);
      setSelectedManager(null);
    }
  };

  const filteredManagers = managers.filter((manager) => {
    const search = searchTerm.toLowerCase();
    return (
      manager.name.toLowerCase().includes(search) ||
      manager.email.toLowerCase().includes(search)
    );
  });

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    fetchManagers(currentPage);
  }, [currentPage]);
  // Handler for going to the previous page
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Handler for going to the next page
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  if (error) return <p className="text-danger">{error}</p>;

 

  return (
    <CommonLayout>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-end">
          <li class="breadcrumb-item">
            <Link to='/Home'>
              <MdHome size={20} color="#4B49AC" />
            </Link>
          </li>
          <li class="breadcrumb-item">
            <Link
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              Content Manager
            </Link>
          </li>
        </ol>
      </nav>
      {loading ? (
 <div className="loading-container">
 <div className="spinner-border text-primary" role="status">
   <span className="visually-hidden">Loading...</span>
 </div>
</div>
      ) : (
        <div className="container card rounded shadow-lg p-4">
        {/* Search Bar */}
        <div className="managerclass mb-4">
          <h4 className=" fw-bold middleclass">All Managers</h4>
          <Input
            prefix={<FaSearch />}
            placeholder="Search by name or email"
            className="search-input"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{
              maxWidth: "300px",
              borderRadius: "50px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          />
        </div>

        {/* Table */}
        <div className="table-responsive">
          <table className="table table-bordered table-striped table-hover parentclass">
            <thead className="bgtable">
              <tr>
                <th className="paddingclass">#</th>
                <th className="paddingclass">Profile</th>
                <th className="paddingclass">Name</th>
                <th className="paddingclass">Email</th>
                <th className="paddingclass">Role</th>
                <th className="paddingclass">Permissions</th>
                <th className="paddingclass">Created At</th>
                <th className="paddingclass">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredManagers.length > 0 ? (
                filteredManagers.map((manager, index) => (
                  <tr key={manager._id}>
                    <td>{index + 1}</td>
                    <td>
                      <Image
                        src={manager.avatar}
                        alt="Avatar"
                        className="avatar rounded-circle"
                        style={{
                          width: "40px",
                          height: "40px",
                          objectFit: "cover",
                        }}
                      />
                    </td>
                    <td>{manager.name}</td>
                    <td>{manager.email}</td>
                    <td>{manager.role}</td>
                    <td>
                      <ul style={{ paddingLeft: "20px" }}>
                        {Object.entries(manager.permissions)
                          .filter(([_, value]) => value).map(([key]) => (
                            <li key={key} className="permission-item">
                              {key.replace(/([A-Z])/g, " $1").toLowerCase()}
                            </li>
                          ))}
                      </ul>
                    </td>
                    <td>{new Date(manager.createdAt).toLocaleDateString()}</td>
                    <td>
                      <button
                        onClick={() => handleEditClick(manager)}
                        aria-label="Edit Manager"
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <MdModeEdit color="4B49AC" size={18} />
                      </button>

                      <button
                        onClick={() => handleDeleteClick(manager)}
                        aria-label="Delete Manager"
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        <FaTrashAlt color="red" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="8"
                    style={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    No managers found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-center align-items-center gap-3 mt-5">
          <button
            className="btn btn-outline-primary prevbtn"
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
             <GrFormPreviousLink size={20} />
          </button>
          <span className="fw-bold">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className="btn btn-outline-primary nextbtn"
          >
              <GrFormNextLink size={20} />
          </button>
        </div>

        {showEditModal && (
          <div className="popup-overlay">
            <div className="popup-contenttwo">
              <h4 className="modal-title mb-4">
                Edit Permissions for {selectedManager.name}
              </h4>
              <form>
                {Object.keys(selectedManager.permissions)
                  .map((key) => (
                    <div key={key} className="permission-checkbox">
                      <label className="permission-label">
                        <input
                          type="checkbox"
                          checked={selectedManager.permissions[key]}
                          onChange={(e) =>
                            setSelectedManager((prev) => ({
                              ...prev,
                              permissions: {
                                ...prev.permissions,
                                [key]: e.target.checked,
                              },
                            }))
                          }
                        />
                        <span className="permission-text">
                          {key.replace(/([A-Z])/g, " $1").toLowerCase()}
                        </span>
                      </label>
                    </div>
                  ))}
                <div className="popup-buttons d-flex justify-content-center gap-4 mt-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      handleUpdatePermissions(selectedManager.permissions)
                    }
                  >
                    Save Changes
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={() => setShowEditModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Delete Modal */}

        {showDeleteModal && (
          <div className="popup-overlay">
            <div className="popup-contentthree pt-4">
              <h4 className="mt-4 text-center mb-4">
                Are you sure you want to delete {selectedManager.name}?
              </h4>

              <div className="popup-buttons d-flex justify-content-center gap-3 p-2">
                <button
                  className="btn btn-primary"
                  onClick={handleConfirmDelete}
                >
                  Confirm
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      )}
    
    </CommonLayout>
  );
}

export default AllManagers;
