import React, { useEffect, useState } from "react";
import CommonLayout from "../../layout/commonLayout/CommonLayout";
import {
  deleteTag,
  fetchTag,
  updateTag,
  uploadTagImage,
} from "../../services/Apis/ApiServices";
import { FaTrashAlt, FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdHome } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import { Input, Modal, Image } from "antd";
import { toast } from "react-toastify";
import { GrFormPreviousLink, GrFormNextLink } from "react-icons/gr";
import { MdModeEdit } from "react-icons/md";
import { languageOptions } from "../../utils/utils";
const AllTag = () => {
  const [tags, setTags] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editabletag, setEditabletag] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState(null);
  const { TextArea } = Input;
  const getAllTag = async () => {
    try {
      setLoading(true);
      const response = await fetchTag(currentPage);
      if (response) {
        setTags(response.tags);
        setTotalPages(response.totalPages);
      } else {
        console.error("Fetched data is not an array:", response);
        setTags([]);
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTag();
  }, [currentPage]);

  const handleViewDescription = (tag) => {
    setSelectedTag(tag);
    setShowModal(true);
  };

  const handleDeleteModel = (tag) => {
    setSelectedTag(tag);
    setShowModalDelete(true);
  };

  const handleEditModal = (tag) => {
    setSelectedTag(tag);
    setEditabletag(tag);
    setShowModalEdit(true);
  };

  const filteredtags = Array.isArray(tags)
    ? tags.filter((tag) => {
        const search = searchTerm.toLowerCase();
        return (
          tag?.createdBy?.name?.toLowerCase()?.includes(search) ||
          false ||
          tag?.title?.en?.toLowerCase()?.includes(search) ||
          false ||
          tag?.title?.secondary?.toLowerCase()?.includes(search) ||
          false
        );
      })
    : [];

  const handleConfirmDelete = async (id) => {
    try {
      const response = await deleteTag(id);

      if (response?.success) {
        getAllTag();
        toast.success(response.message);
        setShowModalDelete(false);
      } else {
        const errorMessage =
          response?.message || "Failed to delete tag. Please try again.";
        toast.error(errorMessage);
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "An unexpected error occurred";
      toast.error(errorMessage);
      console.error("Error deleting tag:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    let imagePath = selectedTag.tagImage;
    if (file) {
      const response = await uploadTagImage(file);
      if (response) {
        imagePath = response;
        setImagePreview(null);
        setFile(null);
      }
    }
    const updatedTagData = {
      _id: selectedTag._id,
      title: {
        en: selectedTag.title.en,
        secondary: selectedTag.title.secondary,
      },
      description: {
        en: selectedTag.description.en,
        secondary: selectedTag.description.secondary,
      },
      language: {
        secondary: selectedTag.language.secondary,
      },
      tagImage: imagePath,
    };

    try {
      const response = await updateTag(updatedTagData);
      if (response?.success) {
        toast.success(response.message );
        getAllTag();
        setShowModalEdit(false);
        setFile(null); // Reset file state
        setImagePreview(null); // Reset preview state
      } else {
        toast.error(
          response?.message || "Failed to update tag. Please try again."
        );
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;

    setSelectedTag((prevTag) => {
      if (name === "language") {
        return {
          ...prevTag,
          language: {
            ...prevTag.language,
            secondary: value,
          },
        };
      } else if (name === "titleEn") {
        return {
          ...prevTag,
          title: {
            ...prevTag.title,
            en: value,
          },
        };
      } else if (name === "titleSecondary") {
        return {
          ...prevTag,
          title: {
            ...prevTag.title,
            secondary: value,
          },
        };
      } else if (name === "descriptionEn") {
        return {
          ...prevTag,
          description: {
            ...prevTag.description,
            en: value,
          },
        };
      } else if (name === "descriptionSecondary") {
        return {
          ...prevTag,
          description: {
            ...prevTag.description,
            secondary: value,
          },
        };
      } else if (name === "tagImage") {
        return {
          ...prevTag,
          tagImage: {
            ...prevTag.tagImage,
            secondary: value,
          },
        };
      }
      return prevTag;
    });
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Handler for going to the next page
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleImageChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const previewUrl = URL.createObjectURL(selectedFile);
      setImagePreview(previewUrl);
      setFile(selectedFile);
    }
  };
  return (
    <CommonLayout>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb justify-content-end">
          <li class="breadcrumb-item">
            <Link>
              <MdHome size={20} color="#4B49AC" />
            </Link>
          </li>
          <li class="breadcrumb-item">
            <Link
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              All tag
            </Link>
          </li>
        </ol>
      </nav>

      {loading ? (
        <div className="loading-container">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="container card rounded shadow-lg p-4">
          <div className="d-flex justify-content-between mb-4">
            <h4 className=" fw-bold middleclass">All tag</h4>
            <Input
              prefix={<FaSearch />}
              placeholder="Search by created by or title"
              className="search-input"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                maxWidth: "300px",
                borderRadius: "50px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            />
          </div>

          {loading ? (
            <div className="text-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="bgtable">
                  <tr>
                    <th>Serial No</th>
                    <th>Image</th>
                    <th>Created By</th>
                    <th>Title</th>
                    <th>Language</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredtags.length > 0 ? (
                    filteredtags.map((tag, index) => (
                      <tr key={tag._id}>
                        <td>{index + 1}</td>
                        <Image
                          src={tag.tagImage}
                          className="img-fluid avatar rounded-circle"
                          style={{
                            width: "40px",
                            height: "40px",
                            objectFit: "cover",
                          }}
                        />
                        <td>{tag?.createdBy?.name}</td>
                        <td>
                          {tag?.title?.en} / {tag?.title?.secondary}
                        </td>
                        <td>{tag?.language?.secondary}</td>
                        <td>
                          <button
                            onClick={() => handleViewDescription(tag)}
                            aria-label="see description"
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                          >
                            <FaEye color="black" size={18} />
                          </button>

                          <button
                            aria-label="edit tag"
                            onClick={() => handleEditModal(tag)}
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                          >
                            <MdModeEdit color="4B49AC" size={18} />
                          </button>

                          <button
                            aria-label="delete tag"
                            onClick={() => handleDeleteModel(tag)}
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                          >
                            <FaTrashAlt color="red" size={18} />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="8"
                        style={{ textAlign: "center", fontWeight: "bold" }}
                      >
                        No Tags found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center gap-3 mt-5">
            <button
              onClick={handlePrevious}
              className="btn btn-outline-primary prevbtn"
            >
              <GrFormPreviousLink size={20} />
            </button>
            <span className="fw-bold">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              className="btn btn-outline-primary nextbtn"
            >
              <GrFormNextLink size={20} />
            </button>
          </div>

          {/* Modal for viewing the description */}
          {showModal && selectedTag && (
            <div className="popup-overlay">
              <div className="popup-contenttwo pt-4 rounded-3 shadow-lg">
                <h4 className="text-center mb-4">
                  Description of
                  <span className="fw-bold"> {selectedTag.title.en}</span>
                </h4>
                <div className="mb-3">
                  <strong>English:</strong>
                  <p>{selectedTag.description.en}</p>
                </div>
                <div>
                  <strong>Secondary Language:</strong>
                  <p>{selectedTag.description.secondary}</p>
                </div>
                <div className="text-end mt-4">
                  <button
                    className="btn btn-secondary fw-bold fs-6"
                    onClick={() => setShowModal(false)}
                  >
                    cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Modal for deleting tag */}

          {showModalDelete && (
            <div className="popup-overlay">
              <div className="popup-contentthree pt-4">
                <h4 className="mt-4 text-center mb-4">
                  Are you sure you want to delete {selectedTag.title.en}?
                </h4>

                <div className="popup-buttons d-flex justify-content-center gap-3 p-2">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      handleConfirmDelete(selectedTag._id);
                    }}
                  >
                    Confirm
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => setShowModalDelete(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          {showModalEdit && (
            <Modal
              title={
                <h4
                  style={{
                    fontWeight: "bold",
                    margin: "0",
                    textAlign: "center",
                    marginBottom: "20px",
                    color: "#4B49AC",
                  }}
                >
                  Edit Tag
                </h4>
              }
              visible={showModalEdit}
              onCancel={() => setShowModalEdit(false)}
              footer={null}
              style={{ borderRadius: "15px" }}
            >
              <div className="container my-3">
                <div className="">
                  <form onSubmit={handleEditSubmit}>
                    <div className="mb-3">
                      <label className="form-label fw-bold">Language</label>
                      <select
                        className="form-select"
                        name="language"
                        value={selectedTag.language.secondary}
                        onChange={handleEditChange}
                      >
                        {Object.entries(languageOptions).map(([code, name]) => (
                          <option key={code} value={code}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-3 row">
                      <div className="col-md-6">
                        <label className="form-label fw-bold">
                          Title (English)
                        </label>
                        <input
                          type="text"
                          className="form-control formclass"
                          name="titleEn"
                          value={selectedTag.title.en}
                          onChange={handleEditChange}
                          placeholder="Enter title in English"
                          required
                        />
                      </div>

                      <div className="col-md-6">
                        <label className="form-label fw-bold">
                          Title (
                          {languageOptions[selectedTag.language.secondary]})
                        </label>
                        <input
                          type="text"
                          className="form-control formclass"
                          name="titleSecondary"
                          value={selectedTag.title.secondary}
                          onChange={handleEditChange}
                          placeholder={`Enter title in ${
                            languageOptions[selectedTag.language.secondary]
                          }`}
                          required
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label className="form-label fw-bold">
                          Description (English)
                        </label>
                        <TextArea
                          className="form-control formclass"
                          name="descriptionEn"
                          value={selectedTag.description.en}
                          onChange={handleEditChange}
                          placeholder="Enter description in English"
                          required
                        />
                      </div>

                      <div className="col-md-6">
                        <label className="form-label fw-bold">
                          Description (
                          {languageOptions[selectedTag.language.secondary]})
                        </label>
                        <TextArea
                          className="form-control formclass"
                          name="descriptionSecondary"
                          value={selectedTag.description.secondary}
                          onChange={handleEditChange}
                          placeholder={`Enter description in ${
                            languageOptions[selectedTag.language.secondary]
                          }`}
                          required
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label
                        htmlFor="bannerImageUpload"
                        className="form-label fw-bold"
                      >
                        Upload Tag Image
                      </label>
                      <input
                        type="file"
                        className="form-control formclass"
                        id="tagImageUpload"
                        onChange={handleImageChange}
                      />
                    </div>

                    <div className="mb-3">
                      {imagePreview ? (
                        <img
                          src={imagePreview}
                          alt="tag Preview"
                          className="img-fluid"
                          style={{ maxWidth: "100%", height: "100px" }}
                        />
                      ) : (
                        <p></p>
                      )}
                    </div>

                    <div
                      className="mb-3"
                      style={{ border: "1px solid #ccc", padding: "10px" }}
                    >
                      {editabletag?.tagImage ? (
                        <img
                          src={editabletag.tagImage}
                          alt="tagImage"
                          className="img-fluid"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      ) : (
                        <p>No image available</p>
                      )}
                    </div>

                    <div className="popup-buttons d-flex justify-content-center gap-3 p-2">
                      <button type="submit" className="btn btn-primary">
                        Save Changes
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger fw-bold text-light"
                        onClick={() => setShowModalEdit(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Modal>
          )}
        </div>
      )}
    </CommonLayout>
  );
};

export default AllTag;
