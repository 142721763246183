import React from 'react';
import './notfound404.css';
import { useNavigate } from 'react-router-dom';

const NotFound404 = () => {
    const navigate = useNavigate();
    const handleReturnHome = () => {
        navigate('/');
    }
  return (
    <div className="not-found-container">
      {/* <img src="../../../assets/error/404-error.webp" alt="404 Not Found" className="not-found-image" /> */}
      <h1 className="not-found-title">404</h1>
      <p className="not-found-message">Oops! The page you're looking for doesn't exist.</p>
      <button type="submit" className="btn btn-primary m-0"onClick={handleReturnHome} >Return to Home
        </button>
    </div>
  );
};

export default NotFound404;
