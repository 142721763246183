import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate , useParams } from 'react-router-dom';
import './authStyle/ForgotPassword.css'
import { toast } from 'react-toastify';
import { resetPassword } from '../../components/Auth/AuthApi';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
function ResetPassword() {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading , setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const validateForm = () => {
        const newErrors = {};
        if (!newPassword) newErrors.newPassword = 'New password is required';
        if (newPassword !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            try {
                const resetLink = token;
                const response = await resetPassword(resetLink, newPassword);
                if (response && response.success) {
                  toast.success(response.message || 'Password changed successfully.');
                  navigate('/');
                  setNewPassword('');
                  setConfirmPassword('');
                  setErrors({});
                } else {
                  toast.error(response.error || 'Failed to reset password.');
                }
              } catch (error) {
                toast.error(error.message || 'An unexpected error occurred.');
              } finally {
                setLoading(false);
              }
            
            
        }
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    return (
        <div className="">
            <div className="container-fluid p-0">
                <div className="content-wrapper d-flex align-items-center auth px-0" style={{
                    backgroundImage: "url('https://img.freepik.com/free-photo/flowers-notebook-near-laptop_23-2147759307.jpg?semt=ais_hybrid')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100vh',
                }}>
                    <div className="row widthclass mx-0 mx-auto">
                    <div className="col-lg-4 col-md-6 col-sm-8 mx-auto card shadow-sm" style={{
                            borderRadius: '10px'
                        }}>
                            <div className=" text-left rounded  px-sm-5">
                                <div className="brand-logo d-flex align-items-center justify-content-center">
                                <img src="https://img.freepik.com/premium-vector/blog-word-drawn-lettering-typographic-element_205544-12246.jpg?semt=ais_hybrid" alt="logo" style={{ height: "90px" }} />
                                </div>
                                <h4 className='text-center mb-3'>Reset Password</h4>
                                <form className="" onSubmit={handleSubmit}>
                                    
                                    <div className="form-group mb-3  position-relative">
                                        <input
                                             type={showNewPassword ? 'text' : 'password'}
                                            className={`form-control  ${errors.newPassword ? 'is-invalid' : ''}`}
                                            placeholder="New Password"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            required
                                        />
                                        {errors.newPassword && <div className="invalid-feedback">{errors.newPassword}</div>}
                                        <span
                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                            className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                                        </span>
                                    </div>
                                    <div className="form-group  position-relative">
                                        <input
                                              type={showConfirmPassword ? 'text' : 'password'}
                                            className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                            placeholder="Confirm Password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            required
                                        />
                                        {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
                                        <span
                                            onClick={toggleConfirmPasswordVisibility}
                                            className="position-absolute top-50  end-0 translate-middle-y me-3   cursor-pointer"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                        </span>
                                    </div>
                                    <div className="mt-4 d-grid gap-2 mb-4">
                              
                                        <button type="submit" className=" btn btncolor m-0" disable={loading}>
                                            {loading ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ width: '1rem', height: '1rem' }}></span>) : 'Reset password'}
                                        </button>
                                        
                                    </div>
                                    <div className="text-center mt-4 font-weight-light mb-4">
                                        Remember your password? <Link to="/" className="text-primary">Login</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
