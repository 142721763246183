import React from "react";

const DeleteArticleModal = ({
  selectedArticle,
  setShowDeleteModal,
  handleConfirmDelete,
}) => {
  return (
    <div className="popup-overlay">
      <div className="popup-contentthree pt-4 text-center">
     
        <h5 className="mt-4 text-danger">
          {selectedArticle?.languages === "en"
            ? "If you delete this article all the associated articles in other languages will be deleted too. Are you sure you want to delete ?"
            : "Are you sure you want to delete this article"}
        </h5>
        <h6 className="mb-4 ">{selectedArticle?.title}</h6>
        <div className="popup-buttons d-flex justify-content-center gap-3 p-2">
          <button className="btn btn-primary" onClick={handleConfirmDelete}>
            Confirm
          </button>
          <button
            className="btn btn-danger"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteArticleModal;
